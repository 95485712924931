<template>
  <v-card width="100%">
    <management-nav />
    <v-card-title>
      Anatomia w praktyce - pliki do pobrania
      <v-spacer></v-spacer>
      <v-text-field v-model="search" label="Szukaj" single-line hide-details
        ><font-awesome-icon
          icon="search"
          slot="prepend-inner"
          class="mr-1 mt-1"
      /></v-text-field>
    </v-card-title>
    <v-data-table
      :loading="!items"
      loading-text="Trwa pobieranie danych..."
      :headers="headers"
      :items="items"
      :search="search"
    >
      <template v-slot:no-data>
        <v-alert :value="items && items.length === 0">
          Brak plików do pobrania
        </v-alert>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="green"
              @click="editAttachment(item)"
              v-bind="attrs"
              v-on="on"
              v-on:click="key++, (isModalVisible = true)"
            >
              <font-awesome-icon icon="edit" />
            </v-btn>
          </template>
          <span>Edytuj</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="red"
              @click="(isDeleteModalVisible = true), (packageData = item)"
              v-bind="attrs"
              v-on="on"
            >
              <font-awesome-icon icon="times" />
            </v-btn>
          </template>
          <span>Usuń</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <template>
          <p>
            {{ item.type === "THEORY" ? "Teoria" : "Praktyka" }}
          </p>
        </template>
      </template>
      <template v-slot:[`item.attachment`]="{ item }">
        <template>
          <p class="attachment-name" @click="openAttachment(item)">
            {{ item.filenameWithExtension }}
          </p>
        </template>
      </template>
    </v-data-table>
    <add-theory-practice-workshop-lesson-attachment-modal
      :isVisible="isModalVisible"
      :attachmentItemData="attachmentData"
      @closeModal="closeModal()"
      :keyHandle="key"
    />
    <before-delete-modal
      :isVisible="isDeleteModalVisible"
      @closeModal="closeDeleteModal()"
      @confirmDeletion="confirmDelete(packageData.id)"
    />
    <v-btn
      fab
      dark
      large
      color="green"
      fixed
      left
      bottom
      v-on:click="key++, (isModalVisible = true)"
    >
      <font-awesome-icon icon="plus" />
    </v-btn>
  </v-card>
</template>

<script>
import ManagementNav from "../components/ManagementNav.vue";
import BeforeDeleteModal from "../components/modals/BeforeDeleteModal.vue";
import AddTheoryPracticeWorkshopLessonAttachmentModal from "../components/modals/AddTheoryPracticeWorkshopLessonAttachmentModal.vue";
import { SERVER_URL } from "../axios";
export default {
  name: "TheoryPracticeWorkshopsLessonsAttachmentsManagement",
  components: {
    ManagementNav,
    BeforeDeleteModal,
    AddTheoryPracticeWorkshopLessonAttachmentModal,
  },
  async created() {
    await this.$store.dispatch(
      "theoryPracticeWorkshopsLessonsAttachments/fetchTheoryPracticeWorkshopsLessonsAttachments"
    );
    await this.$store.dispatch(
      "theoryPracticeWorkshopsLessons/fetchTheoryPracticeWorkshopsLessons",
      { withNotActive: true }
    );
  },
  data: () => ({
    headers: [
      { text: "Nazwa załącznika", value: "name" },
      { text: "Plik", value: "attachment" },
      { text: "Lekcja", value: "theoryPracticeWorkshopsLesson.name" },
      { text: "Teoria/Praktyka", value: "type" },
      { text: "Akcje", value: "actions", sortable: false },
    ],
    search: "",
    isModalVisible: false,
    attachmentData: null,
    isDeleteModalVisible: false,
    key: 1,
  }),
  computed: {
    items() {
      return this.$store.getters[
        "theoryPracticeWorkshopsLessonsAttachments/getTheoryPracticeWorkshopsLessonsAttachments"
      ];
    },
  },
  methods: {
    openAttachment(attachment) {
      const url = `${SERVER_URL}lessonsAttachments/${attachment.theoryPracticeWorkshopsLessonId}_${attachment.filenameWithExtension}`;
      window.open(url, "_blank");
    },
    confirmDelete(id) {
      this.deletePackage(id);
    },
    closeDeleteModal() {
      this.isDeleteModalVisible = false;
      this.packageData = null;
    },

    editAttachment(data) {
      this.attachmentData = data;
    },
    async deletePackage(id) {
      await this.$store.dispatch(
        "theoryPracticeWorkshopsLessonsAttachments/deleteTheoryPracticeWorkshopLessonAttachment",
        { id }
      );
    },
    closeModal() {
      this.isModalVisible = false;
      this.attachmentData = null;
    },
  },
};
</script>

<style scoped>
.attachment-name {
  text-decoration: underline;
  cursor: pointer;
}
</style>
