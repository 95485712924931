<template>
  <div class="container">
    <div class="form">
      <ValidationObserver ref="obs" v-slot="{ handleSubmit }" mode="eager">
        <v-form @submit.prevent="handleSubmit(onSubmit)">
          <h3 class="mb-2">Dane kontaktowe</h3>
          <v-checkbox
            dense
            outlined
            v-model="invoiceForCompany"
            :label="`Faktura na firmę`"
            class="mb-4"
            :disabled="loading || transaction.show"
          ></v-checkbox>
          <VTextFieldWithValidation
            rules="email|required"
            v-model.trim="email"
            label="E-mail"
            color="grey"
            outlined
            dense
            :disabled="loading || transaction.show"
          />
          <VTextFieldWithValidation
            rules="email|required"
            v-model.trim="email_confirmation"
            label="Potwierdź e-mail"
            color="grey"
            outlined
            dense
            :disabled="loading || transaction.show"
          />
          <VTextFieldWithValidation
            :rules="!invoiceForCompany ? 'required' : ''"
            v-if="!invoiceForCompany"
            v-model="name"
            label="Imię i nazwisko"
            color="grey"
            outlined
            dense
            :disabled="loading || transaction.show"
          />
          <VTextFieldWithValidation
            :rules="invoiceForCompany ? 'required' : ''"
            v-if="invoiceForCompany"
            label="Nazwa firmy"
            color="grey"
            outlined
            dense
            :disabled="loading || transaction.show"
            v-model="companyName"
          />
          <VTextFieldWithValidation
            v-if="invoiceForCompany"
            :rules="invoiceForCompany ? 'required|numeric|NIP' : ''"
            label="Numer NIP"
            color="grey"
            outlined
            dense
            :disabled="loading || transaction.show"
            v-model="companyNIP"
          />
          <VTextFieldWithValidation
            :rules="invoiceForCompany ? 'required' : ''"
            :label="'Ad\u200Bres'"
            color="grey"
            outlined
            dense
            :disabled="loading || transaction.show"
            v-model="companyAddress"
            v-if="invoiceForCompany"
          />

          <v-checkbox
            dense
            outlined
            v-model="newsletter"
            :label="`Chcę się zapisać do newslettera`"
            class="mb-4"
            :disabled="loading || transaction.show"
          ></v-checkbox>

          <div v-if="!productsWithoutDelivery">
            <h3 class="mb-2 mt-4">Dane do wysyłki</h3>
            <div class="inline-fields">
              <div>
                <VTextFieldWithValidation
                  rules="required"
                  v-model.trim="sendAddressFirstName"
                  label="Imię"
                  color="grey"
                  name="firstname"
                  outlined
                  dense
                  :disabled="loading || transaction.show"
                  @newValueAfterChange="handleNewAddress"
                />
              </div>
              <div>
                <VTextFieldWithValidation
                  rules="required"
                  v-model.trim="sendAddressLastName"
                  label="Nazwisko"
                  color="grey"
                  outlined
                  dense
                  :disabled="loading || transaction.show"
                  @newValueAfterChange="handleNewAddress"
                />
              </div>
            </div>
            <VTextFieldWithValidation
              :rules="'required|phone'"
              :label="'Telefon'"
              color="grey"
              outlined
              dense
              :disabled="loading || transaction.show"
              v-model="sendAddressPhone"
              @newValueAfterChange="handleNewAddress"
            />

            <h3 class="mb-2 mt-4">Wybierz sposób dostawy</h3>
            <VSelectWithValidation
              :rules="'required'"
              :label="'Sposób dostawy'"
              color="grey"
              outlined
              dense
              :disabled="loading || transaction.show"
              v-model="selectedDeliveryType"
              :height="40"
              :items="deliveryTypes"
            />

            <div
              v-if="
                selectedDeliveryType === 2 &&
                availableServices &&
                availableServices.length > 0
              "
            >
              <h3 class="mb-2">Wybierz kuriera</h3>
              <VSelectWithValidation
                :rules="'required'"
                :label="'Kurier'"
                color="grey"
                outlined
                dense
                :disabled="loading || transaction.show"
                v-model="selectedFurgonetkaServiceId"
                :height="40"
                :items="availableServicesFiltered"
              />
            </div>

            <div
              v-if="
                selectedDeliveryType === 2 ||
                (selectedFurgonetkaService &&
                  selectedFurgonetkaService.service === 'poczta')
              "
            >
              <h3 class="mb-2">Adres dostawy</h3>
              <div class="inline-fields">
                <div>
                  <VTextFieldWithValidation
                    rules="required|postalCode"
                    v-model.trim="sendAddressPostcode"
                    label="Kod pocztowy"
                    color="grey"
                    outlined
                    dense
                    :disabled="loading || transaction.show"
                    name="postcode"
                    @newValueAfterChange="handleNewAddress"
                  />
                </div>
                <div>
                  <VTextFieldWithValidation
                    rules="required"
                    v-model.trim="sendAddressCity"
                    label="Miasto"
                    color="grey"
                    name="city"
                    outlined
                    dense
                    :disabled="loading || transaction.show"
                    @newValueAfterChange="handleNewAddress"
                  />
                </div>
              </div>
              <VTextFieldWithValidation
                :rules="'required'"
                :label="'Adres'"
                color="grey"
                name="street"
                outlined
                dense
                :disabled="loading || transaction.show"
                v-model="sendAddressStreet"
                @newValueAfterChange="handleNewAddress"
              />
            </div>

            <furgonetka-map
              v-show="selectedDeliveryType === 1"
              @point-selected="handleNewFurgonetkaPoint"
            />
            <p>Wysyłka w ciągu 3 dni roboczych</p>
          </div>

          <div class="discounts">
            <VTextFieldWithValidation
              v-model.trim="discountCode"
              label="Kod rabatowy"
              color="grey"
              name="discountCode"
              outlined
              dense
              :disabled="loading || transaction.show"
            />
            <button
              class="submit-button mt-1"
              color="grey darken-2"
              @click="handleDiscountCodeChange"
            >
              Użyj kodu
            </button>
          </div>

          <button
            type="submit"
            class="mt-5 mb-3 submit-button"
            color="grey darken-2"
          >
            Dalej
          </button>

          <br />

          <form :action="PAYMENT_URL" method="post" id="form">
            <input
              type="hidden"
              name="p24_session_id"
              :value="transaction.data.p24_session_id"
            />
            <input
              type="hidden"
              name="p24_merchant_id"
              :value="transaction.data.p24_merchant_id"
            />
            <input
              type="hidden"
              name="p24_pos_id"
              :value="transaction.data.p24_pos_id"
            />
            <input
              type="hidden"
              name="p24_amount"
              :value="transaction.data.p24_amount"
            />
            <input
              type="hidden"
              name="p24_currency"
              :value="transaction.data.p24_currency"
            />
            <input
              type="hidden"
              name="p24_description"
              :value="transaction.data.p24_description"
            />
            <input
              type="hidden"
              name="p24_country"
              :value="transaction.data.p24_country"
            />
            <input
              type="hidden"
              name="p24_email"
              :value="transaction.data.p24_email"
            />
            <input
              type="hidden"
              name="p24_url_return"
              :value="transaction.data.p24_url_return"
            />
            <input
              type="hidden"
              name="p24_url_status"
              :value="transaction.data.p24_url_status"
            />
            <input
              type="hidden"
              name="p24_api_version"
              :value="transaction.data.p24_api_version"
            />
            <input
              type="hidden"
              name="p24_sign"
              :value="transaction.data.p24_sign"
            />
            <v-btn type="submit" class="mt-5 submit-button" id="submit-button">
              Dokonaj płatności
            </v-btn>
          </form>

          <br />
        </v-form>
      </ValidationObserver>
    </div>

    <div class="summary">
      <h3>Podsumowanie</h3>
      <break-line />

      <div
        v-for="product in bucket.products"
        :key="product.id"
        class="summary-row"
      >
        <div>
          <b>{{ product.name }}</b>
          <span style="font-size: 14px; margin-left: 0.25rem">
            ({{ product.amount }})</span
          >
        </div>
        <product-price
          style="font-size: 14px"
          :margin="'0'"
          :price="(product.price * product.amount).toFixed(2)"
        />
      </div>

      <break-line />

      <div
        class="summary-row"
        v-if="
          (!productsWithoutDelivery &&
            selectedFurgonetkaService &&
            selectedFurgonetkaService.priceGross) ||
          selectedFurgonetkaServiceId
        "
      >
        <div
          style="font-size: 12px; margin-left: 1rem"
          v-if="!productsWithoutDelivery"
        >
          Wysyłka
        </div>
        <product-price
          v-if="selectedFurgonetkaService && !productsWithoutDelivery"
          style="font-size: 14px"
          :margin="'0'"
          :price="
            selectedDeliveryType === 2
              ? `${selectedFurgonetkaService.priceGross}`
              : selectedFurgonetkaPoint && selectedFurgonetkaPoint.name
              ? `${selectedFurgonetkaService.priceGross}`
              : '24.48'
          "
        />
        <p v-else-if="selectedFurgonetkaServiceId && !productsWithoutDelivery">
          Paczkomat
        </p>
      </div>

      <break-line v-if="!productsWithoutDelivery" />

      <div class="summary-row">
        <div style="font-size: 14px; margin-left: 1rem">
          <b>Suma</b>
        </div>

        <product-price
          style="font-size: 24px; margin-right: 0.6rem"
          :margin="'0'"
          :price="`${calculateSumPrice}`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VTextFieldWithValidation from "../../components/inputs/VTextFieldWithValidation";
import { ValidationObserver } from "vee-validate";
import { PAYMENT_URL } from "../../axios";
import VSelectWithValidation from "../../components/inputs/VSelectWithValidation.vue";
import BreakLine from "../../components/BreakLine.vue";
import ProductPrice from "../../components/shop/ProductPrice.vue";
import FurgonetkaMap from "../../components/FurgonetkaMap.vue";
//import RoundedColorButton from "../../components/buttons/RoundedColorButton.vue";

export default {
  metaInfo: {
    title: "Dagajoga płatność - portal jogi online",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Dagajoga to szkoła internetowa o wszechstronnym systemie budowania praktyki własnej. Praktyka jogi połączona z nowoczesnymi metodami ćwiczeń funkcjonalnych, ćwiczeń wzmacniających.",
      },
    ],
  },
  name: "ShopProductsPayment",
  components: {
    VTextFieldWithValidation,
    ValidationObserver,
    VSelectWithValidation,
    BreakLine,
    ProductPrice,
    FurgonetkaMap,
    //RoundedColorButton,
  },
  data: () => ({
    email: "",
    email_confirmation: "",
    discountCode: "",
    loading: false,
    PAYMENT_URL,
    newsletter: false,
    companyName: "",
    companyNIP: "",
    companyAddress: "",
    name: "",
    invoiceForCompany: false,
    address: "",
    availableServices: [],
    selectedFurgonetkaServiceId: "",
    selectedFurgonetkaService: null,
    sendAddressPostcode: "",
    sendAddressStreet: "",
    sendAddressCity: "",
    sendAddressPhone: "",
    sendAddressLastName: "",
    sendAddressFirstName: "",
    productsWithoutDelivery: true,
    availableServicesInterval: null,
    selectedFurgonetkaPoint: null,
    deliveryTypes: [
      { value: 1, name: "Paczkomat" },
      { value: 2, name: "Przesyłka kurierska" },
    ],
    selectedDeliveryType: 1,
    notCouriers: ["poczta", "inpost", "orlen"],
    usedDiscountCode: null,
  }),
  watch: {
    selectedDeliveryType() {
      if (
        this.selectedDeliveryType &&
        this.selectedDeliveryType === 1 &&
        this.selectedFurgonetkaPoint
      ) {
        this.handleNewFurgonetkaPoint(this.selectedFurgonetkaPoint);
      }
    },

    invoiceForCompany() {
      if (!this.companyNIP) {
        this.companyNIP = " ";
      }
    },
    selectedFurgonetkaServiceId(newServiceId) {
      this.selectedFurgonetkaService = this.furgonetkaServices.find(
        (service) => String(service.id) === String(newServiceId)
      );
    },
  },
  computed: {
    calculateSumPrice() {
      if (this.bucket && this.bucket.products) {
        let sumPrice = 0;
        const products = this.bucket.products;
        let productsPrice = 0;
        products.forEach((product) => {
          const productPrice = product.amount * product.price;
          productsPrice = productsPrice + productPrice;
        });
        sumPrice = sumPrice + productsPrice;
        if (
          !this.productsWithoutDelivery &&
          this.selectedFurgonetkaService &&
          this.selectedFurgonetkaService.priceGross
        ) {
          sumPrice = sumPrice + this.selectedFurgonetkaService.priceGross;
        }
        if (this.usedDiscountCode) {
          sumPrice = sumPrice * (1 - this.usedDiscountCode.value);
        }
        return sumPrice;
      } else {
        return "0.00";
      }
    },
    availableServicesFiltered() {
      return this.availableServices
        ? this.availableServices.filter(
            (s) => s.service && !this.notCouriers.includes(s.service)
          )
        : [];
    },

    transaction: function () {
      return this.$store.getters["payment/getTransInfo"];
    },
    bucket() {
      return this.$store.getters["shopProducts/getBucket"];
    },
    user() {
      return this.$store.getters["auth/getProfile"];
    },
    furgonetkaServices() {
      const allServices =
        this.$store.getters["shopProducts/getFurgonetkaServices"];
      return allServices;
    },
  },
  async created() {
    this.resetData();
  },
  beforeDestroy() {
    clearInterval(this.availableServicesInterval);
  },
  async mounted() {
    if (this.furgonetkaServices && Array.isArray(this.furgonetkaServices)) {
      this.furgonetkaServices.forEach((service, index) => {
        if (index === 0) {
          this.selectedFurgonetkaServiceId = service.id;
          this.selectedFurgonetkaService = service;
        }
        this.availableServices.push({
          value: service.id,
          name: `${service.service.toUpperCase()} - ${service.priceGross} zł`,
          service: service.service,
        });
      });
    }

    this.fetchAndRefreshFurgonetkaServices();

    await this.$store.dispatch("auth/fetchUserProfile");

    if (this.bucket && this.bucket.products) {
      const products = this.bucket.products;
      products.forEach((product) => {
        const productType = product.type;
        if (productType === "STANDARD") {
          this.productsWithoutDelivery = false;
        }
      });
    }

    this.furgonetkaServices.forEach((service, index) => {
      if (index === 0) {
        this.selectedFurgonetkaServiceId = service.id;
        this.selectedFurgonetkaService = service;
      }
      this.availableServices.push({
        value: service.id,
        name: `${service.service.toUpperCase()} - ${service.priceGross}zł`,
        service: service.service,
      });
    });

    this.availableServicesInterval = setInterval(async () => {
      if (!this.availableServices || this.availableServices.length === 0) {
        await this.handleNewAddress();
      }
    }, 2000);
    if (this.user && this.user.id) {
      this.email = this.user.email;
      this.email_confirmation = this.user.email;
      if (this.user.name) {
        this.name = this.user.name;
      }
      if (this.user.company) {
        this.companyName = this.user.company;
      }
      if (this.user.nip) {
        this.companyNIP = this.user.nip;
      }
      if (this.user.address) {
        this.address = this.user.address;
      }

      const currentDeliveryAddress = window.localStorage.getItem(
        "dagajoga_delivery_address"
      );
      if (currentDeliveryAddress) {
        const jsonDeliveryAddress = JSON.parse(currentDeliveryAddress);
        this.sendAddressPostcode = jsonDeliveryAddress.sendAddressPostcode;
        this.sendAddressCity = jsonDeliveryAddress.sendAddressCity;
        this.sendAddressStreet = jsonDeliveryAddress.sendAddressStreet;
        this.sendAddressFirstName = jsonDeliveryAddress.sendAddressFirstName;
        this.sendAddressLastName = jsonDeliveryAddress.sendAddressLastName;
        this.sendAddressPhone = jsonDeliveryAddress.sendAddressPhone;
      }
    } else {
      this.$store.dispatch(
        "snackbar/setSnackbar",
        {
          color: "red",
          icon: "exclamation-triangle",
          message:
            "Zakup produktów dostępny jest wyłącznie dla zalogowanych użytkowników",
        },
        { root: true }
      );
      this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
      this.$router.push({
        name: "Auth",
        params: { previousPage: "ShopProductsPayment" },
      });
    }

    if (
      !this.bucket ||
      !this.bucket.products ||
      this.bucket.products.length < 1
    ) {
      this.$store.dispatch(
        "snackbar/setSnackbar",
        {
          color: "red",
          icon: "exclamation-triangle",
          message:
            "Twój koszyk jest pusty. Dodaj produkty do koszyka, które chcesz zakupić",
        },
        { root: true }
      );
      this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
      this.$router.push({
        name: "ShopProducts",
      });
    }
  },
  destroyed() {
    this.reset();
  },
  methods: {
    async handleDiscountCodeChange(e) {
      e.preventDefault();
      if (this.discountCode) {
        const usedDiscountCode = await this.$store.dispatch(
          "discountCode/getDiscountCodeByCodeAndType",
          {
            code: this.discountCode,
            type: "SHOP-PRODUCTS",
          }
        );
        if (usedDiscountCode) {
          this.$store.dispatch("snackbar/showSnackbar", {
            color: "success",
            message: "Kod rabatowy został zastosowany",
          });
        } else {
          this.$store.dispatch("snackbar/showSnackbar", {
            color: "red",
            message: "Kod rabatowy jest nieprawidłowy",
          });
        }
        this.usedDiscountCode = usedDiscountCode;
      }
    },

    async handleNewFurgonetkaPoint(point) {
      const { type } = point;

      await this.fetchAndRefreshFurgonetkaServices(point ? point.code : null);
      this.selectedFurgonetkaService = this.furgonetkaServices.find(
        (s) => s.service === type
      );
      this.selectedFurgonetkaServiceId = this.selectedFurgonetkaService.id;
      this.selectedFurgonetkaPoint = point;
    },
    async fetchAndRefreshFurgonetkaServices(point) {
      await this.$store.dispatch(
        "shopProducts/getAvailableFurgonetkaServices",
        {
          point,
        }
      );
    },
    async handleNewAddress() {
      let currentDeliveryAddress = {
        postcode: this.sendAddressPostcode,
        city: this.sendAddressCity,
        street: this.sendAddressStreet,
      };

      await this.$store.dispatch(
        "shopProducts/getAvailableFurgonetkaServices",
        currentDeliveryAddress
      );

      currentDeliveryAddress.sendAddressPostcode = this.sendAddressPostcode;
      currentDeliveryAddress.sendAddressCity = this.sendAddressCity;
      currentDeliveryAddress.sendAddressStreet = this.sendAddressStreet;
      currentDeliveryAddress.sendAddressFirstName = this.sendAddressFirstName;
      currentDeliveryAddress.sendAddressLastName = this.sendAddressLastName;
      currentDeliveryAddress.sendAddressPhone = this.sendAddressPhone;

      window.localStorage.setItem(
        "dagajoga_delivery_address",
        JSON.stringify(currentDeliveryAddress)
      );
    },

    resetData() {
      this.$store.commit("payment/SET_INIT_VALUES");
      this.loading = false;
      this.email = "";
      this.email_confirmation = "";
      this.discountCode = "";
      this.newsletter = false;
      (this.name = ""),
        (this.companyName = ""),
        (this.companyNIP = ""),
        (this.address = "");
    },

    reset() {
      this.resetData();
      if (this.$refs.obs) {
        this.$refs.obs.reset();
      }
    },
    async onSubmit() {
      if (
        !this.productsWithoutDelivery &&
        this.selectedDeliveryType === 1 &&
        !this.selectedFurgonetkaPoint?.code
      ) {
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "red",
          message: "Wybierz punkt odbioru przesyłki",
        });
        return;
      }
      const body = {
        productsWithAmount: this.bucket.products.map((product) => {
          return {
            productId: product.id,
            amount: product.amount,
          };
        }),
        selectedFurgonetkaServiceId: !this.productsWithoutDelivery
          ? this.selectedFurgonetkaServiceId
          : null,
        address: !this.productsWithoutDelivery
          ? {
              firstName: this.sendAddressFirstName,
              lastName: this.sendAddressLastName,
              postcode: this.sendAddressPostcode,
              city: this.sendAddressCity,
              street: this.sendAddressStreet,
              phone: this.sendAddressPhone,
              pointCode:
                this.selectedDeliveryType === 1
                  ? this.selectedFurgonetkaPoint.code
                  : "",
            }
          : {},
        email: this.email,
        invoiceForCompany: this.invoiceForCompany,
        newsletter: this.newsletter,
        userDiscountCode: this.usedDiscountCode
          ? this.usedDiscountCode.code
          : "",
      };
      if (this.selectedDeliveryType !== 1) {
        delete body.address.pointCode;
      }
      if (this.invoiceForCompany) {
        body.companyName = this.companyName;
        body.companyNIP = this.companyNIP;
        body.companyAddress = this.companyAddress;
      } else {
        body.name = this.name;
      }
      this.loading = true;
      const response = await this.$store.dispatch(
        "payment/createShopProductsOrderAndStartPayment",
        body
      );
      if (response) {
        const submitButton = document.querySelector("#submit-button");
        submitButton.click();
        this.loading = false;
        this.$refs.obs.reset();
      } else {
        this.loading = false;
        this.$store.dispatch(
          "snackbar/setSnackbar",
          {
            color: "error",
            icon: "check",
            message: this.translateErrorMessage(
              this.transaction?.message?.message
            ),
          },
          { root: true }
        );
        this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
        this.$store.commit("payment/SET_INIT_VALUES");
      }
    },

    translateErrorMessage(errorMessage) {
      let message = "";
      if (
        errorMessage &&
        errorMessage.includes("Not enough products in stock")
      ) {
        const productName = errorMessage.split("-")[1];
        message = `Niestety produkt '${productName}' jest aktualnie niedostępny`;
      }

      if (errorMessage && errorMessage.includes("Product not found")) {
        message = `Niestety jeden z produktów jest aktualnie niedostępny`;
      }

      return message;
    },
  },
};
</script>

<style scoped>
.discounts {
  display: flex;
  gap: 1rem;
}
.submit-button {
  border-radius: 50px;
  /* background: rgb(167, 214, 253); */
  background: #d1acac;
  height: 35px;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  text-transform: uppercase;
  width: 200px;
  cursor: pointer;
}
.submit-button:hover {
  /* background: rgb(139, 185, 223); */
  background: #d19d9d;
}

.container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2rem;
  gap: 2rem;
}
.summary-row {
  display: flex;
  justify-content: space-between;
  margin: 0 0.5rem;
  margin-bottom: 1rem;
}
.form {
  width: 60%;
  justify-self: start;
  padding: 1rem;
}
.reset {
  text-transform: uppercase;
  cursor: pointer;
}
#submit-button {
  visibility: hidden;
}
.inline-fields {
  display: flex;
  gap: 0.5rem;
}
.inline-fields div {
  width: 50%;
}
.summary {
  background: #f5eded;
  padding: 1rem;
  width: 30%;
}

@media (max-width: 720px) {
  .form {
    order: 1;
    width: 100%;
  }
  .summary {
    width: 100%;
  }
  .submit-button {
    width: 100%;
  }
}
</style>
