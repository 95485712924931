<template>
  <div class="container">
    <theory-practice-page-header />
    <div>
      <path-navigation
        v-if="lesson"
        :paths="[
          {
            name: `Warsztaty - ${lesson.theoryPracticeWorkshop.name}`,
            to: `/warsztaty-teoria-i-praktyka/${lesson.theoryPracticeWorkshop.id}`,
          },
          {
            name: `${lesson.name} - Teoria`,
            to: `/warsztaty-teoria-i-praktyka/${lesson.theoryPracticeWorkshop.id}/lekcje/${this.$route.params.id}/teoria`,
          },
        ]"
      />

      <section>
        <div class="title">
          <img src="../../assets/theory-black.svg" alt="" />
          <span>Teoria</span>
        </div>

        <div v-if="theories && theories.length > 0">
          <theory-practice-lesson-theory
            v-for="theory in theories"
            :key="theory.id"
            :theory="theory"
          />
        </div>

        <p
          v-if="attachments && attachments.length > 0"
          class="attachments-title"
        >
          Pliki do pobrania
        </p>
        <div v-if="attachments && attachments.length > 0">
          <theory-practice-lesson-attachment
            :attachment="attachment"
            v-for="attachment in attachments"
            :key="attachment.id"
          />
        </div>

        <comment-section
          v-if="lesson"
          :id="lesson ? lesson.id : null"
          :type="'lesson'"
          :lesson="lesson"
          :key="lesson ? lesson.id : new Date()"
          :isTheory="true"
        />
      </section>
    </div>

    <div class="flex justify-center text-center" id="lessons-select"></div>
  </div>
</template>

<script>
import { SERVER_URL } from "../../axios";
import CommentSection from "../../components/CommentSection.vue";
import PathNavigation from "../../components/PathNavigation.vue";
import TheoryPracticeLessonAttachment from "./components/TheoryPracticeLessonAttachment.vue";
import TheoryPracticeLessonTheory from "./components/TheoryPracticeLessonTheory.vue";
import TheoryPracticePageHeader from "./components/TheoryPracticePageHeader.vue";

export default {
  metaInfo: {
    title: "Dagajoga warsztaty - Anatomia w praktyce",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Dagajoga to szkoła internetowa o wszechstronnym systemie budowania praktyki własnej.\nPraktyka jogi połączona z nowoczesnymi metodami ćwiczeń funkcjonalnych, ćwiczeń wzmacniających.",
      },
      {
        property: "og:image",
        content: "https://dagajoga.pl/wp-content/uploads/2022/02/image-5.png",
      },
    ],
  },
  name: "TheoryPracticeWorkshopLessonTheories",
  data: () => ({
    theories: [],
  }),
  components: {
    PathNavigation,
    TheoryPracticePageHeader,
    TheoryPracticeLessonTheory,
    TheoryPracticeLessonAttachment,
    CommentSection,
  },
  props: {
    path: String,
  },

  async mounted() {
    await this.$store.dispatch(
      "theoryPracticeWorkshopsLessons/fetchTheoryPracticeWorkshopsLesson",
      this.$route.params.id
    );

    this.theories = await this.$store.dispatch(
      "theoryPracticeWorkshopsLessonsTheories/fetchTheoryPracticeWorkshopsLessonsTheories",
      {
        theoryPracticeWorkshopsLessonId: this.$route.params.id,
      }
    );

    await this.$store.dispatch(
      "theoryPracticeWorkshopsLessonsAttachments/fetchTheoryPracticeWorkshopsLessonsAttachments",
      {
        theoryPracticeWorkshopsLessonId: this.$route.params.id,
        type: "THEORY",
      }
    );

    const isPaid = await this.$store.dispatch(
      "theoryPracticeWorkshops/checkUserPaidForWorkshop",
      this.$route.params.workshopId
    );
    if (!isPaid) {
      this.redirectLogin();
    }
  },

  computed: {
    theories() {
      return this.$store.getters[
        "theoryPracticeWorkshopsLessonsTheories/getTheoryPracticeWorkshopsLessonsTheories"
      ];
    },
    lesson() {
      return this.$store.getters[
        "theoryPracticeWorkshopsLessons/getTheoryPracticeWorkshopLesson"
      ];
    },
    attachments() {
      return this.$store.getters[
        "theoryPracticeWorkshopsLessonsAttachments/getTheoryPracticeWorkshopsLessonsAttachments"
      ];
    },
  },
  methods: {
    getImageSrc(lesson) {
      const extension = lesson.imagePath.split(".")[1];

      const src = `${SERVER_URL}lessonsImages/${
        lesson.id
      }.${extension}?d=${new Date().getTime()}`;
      return src;
    },
    redirectLogin(signup) {
      this.$router.push({
        name: "Auth",
        params: { name: "", signup: signup },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 2560px;
  padding: 0 10px !important;
}
.type-content {
  border: 1px solid rgb(0, 0, 0, 0.25);
  padding: 1rem;
  border-radius: 20px;
  margin-bottom: 3rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.type-label {
  font-size: 30px;
  font-weight: 500;
}

.lessons {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
.title {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 5px;
}
.title img {
  width: 50px;
}
.attachments-title {
  font-weight: 600;
  font-size: 22px;
}
</style>
