<template>
  <div class="nav-buttons">
    <v-btn @click="downloadMailingList" class="submit-button">
      Pobierz listę mailingową
    </v-btn>
    <v-btn @click="navigateTo('Account')" class="submit-button">
      Standardowe pakiety</v-btn
    >
    <v-btn @click="navigateTo('SubscriptionsManagement')" class="submit-button">
      Subskrypcje miesięczne</v-btn
    >
    <!-- <v-btn
      @click="navigateTo('SubscriptionsExtendedManagement')"
      class="submit-button"
    >
      Subskrypcje rozszerzone</v-btn
    > -->
    <!-- <v-btn
      @click="navigateTo('SubscriptionsItemsExtendedManagement')"
      class="submit-button"
    >
      Kursy w subskrypcjach rozszerzonych</v-btn
    >
    <v-btn
      @click="navigateTo('MovieCategoriesExtendedManagement')"
      class="submit-button"
    >
      Kategorie kursów w subskrypcjach rozszerzonych
    </v-btn>
    <v-btn
      @click="navigateTo('MovieSubcategoriesForExtendedManagement')"
      class="submit-button"
    >
      Podkategorie kursów w subskrypcjach rozszerzonych
    </v-btn> -->
    <!-- <v-btn
      @click="navigateTo('MovieSubcategoriesL2ForExtendedManagement')"
      class="submit-button"
    >
      Podkategorie nr 2 kursów w subskrypcjach rozszerzonych
    </v-btn> -->

    <v-btn
      @click="navigateTo('SubscriptionsForBeginnersManagement')"
      class="submit-button"
    >
      Subskrypcje miesięczne - DagaJoga Początek</v-btn
    >

    <v-btn
      @click="navigateTo('TimePackagesLinksManagement')"
      class="submit-button"
    >
      Subskrypcje miesięczne - linki
    </v-btn>

    <v-btn @click="navigateTo('SubscriptionsItems')" class="submit-button">
      Kursy w subskrypcjach</v-btn
    >
    <v-btn
      @click="navigateTo('SubscriptionsForBeginnersItems')"
      class="submit-button"
    >
      Kursy w subskrypcjach - DagaJoga Początek</v-btn
    >
    <v-btn
      @click="navigateTo('MovieCategoriesManagement')"
      class="submit-button"
    >
      Kategorie kursów
    </v-btn>

    <v-btn
      @click="navigateTo('MovieCategoriesForBeginnersManagement')"
      class="submit-button"
    >
      Kategorie kursów subskrypcji - DagaJoga Początek
    </v-btn>
    <v-btn
      @click="navigateTo('MovieSubcategoriesManagement')"
      class="submit-button"
    >
      Podkategorie
    </v-btn>
    <v-btn
      @click="navigateTo('MovieSubcategoriesForBeginnersManagement')"
      class="submit-button"
    >
      Podkategorie subskrypcji - DagaJoga Początek
    </v-btn>

    <v-btn
      @click="navigateTo('MovieSubcategoriesL2Management')"
      class="submit-button"
    >
      Podkategorie nr 2
    </v-btn>
    <v-btn
      @click="navigateTo('MovieSubcategoriesL2ForBeginnersManagement')"
      class="submit-button"
    >
      Podkategorie nr 2 subskrypcji - DagaJoga Początek
    </v-btn>
    <v-btn @click="navigateTo('GiftCardsManagement')" class="submit-button">
      Vouchery
    </v-btn>
    <v-btn
      @click="navigateTo('GiftCardsForBeginnersManagement')"
      class="submit-button"
    >
      Vouchery subskrypcji - DagaJoga Początek
    </v-btn>
    <v-btn @click="navigateTo('UsersListManagement')" class="submit-button">
      Lista użytkowników
    </v-btn>
    <v-btn @click="navigateTo('WorkshopsManagement')" class="submit-button">
      Warsztaty
    </v-btn>
    <v-btn @click="navigateTo('WorkshopsItems')" class="submit-button">
      Kursy do warsztatów
    </v-btn>
    <v-btn
      @click="navigateTo('SequencingWorkshopsManagement')"
      class="submit-button"
    >
      Warsztaty z sekwencjonowania
    </v-btn>
    <v-btn
      @click="navigateTo('SequencingWorkshopsItems')"
      class="submit-button"
    >
      Kursy do warsztatów z sekwencjonowania
    </v-btn>
    <v-btn
      @click="navigateTo('TariffWorkshopsManagement')"
      class="submit-button"
    >
      Warsztaty z taryfami
    </v-btn>
    <v-btn @click="navigateTo('TariffWorkshopsItems')" class="submit-button">
      Kursy do warsztatów z taryfami
    </v-btn>
    <v-btn
      @click="navigateTo('BoughtProductsManagement')"
      class="submit-button"
    >
      Płatności
    </v-btn>
    <v-btn @click="navigateTo('ShopProductsManagement')" class="submit-button">
      Sklep
    </v-btn>
    <v-btn
      @click="navigateTo('ShopProductsDiscountsManagement')"
      class="submit-button"
    >
      Sklep - kody rabatowe
    </v-btn>
    <v-btn
      @click="navigateTo('ShopProductsVideosManagement')"
      class="submit-button"
    >
      Filmy do produktów w sklepie
    </v-btn>
    <v-btn @click="navigateTo('TicketsManagement')" class="submit-button">
      Karnety
    </v-btn>
    <v-btn
      @click="navigateTo('TicketsBeginnersManagement')"
      class="submit-button"
    >
      Karnety dla początkujących
    </v-btn>
    <v-btn @click="navigateTo('TicketsLinksManagement')" class="submit-button">
      Karnety - linki
    </v-btn>
    <v-btn
      @click="navigateTo('TicketsBeginnersLinksManagement')"
      class="submit-button"
    >
      Karnety dla początkujących - linki
    </v-btn>

    <v-btn @click="navigateTo('ScheduleManagement')" class="submit-button">
      Harmonogram tygodnia
    </v-btn>

    <v-btn
      @click="navigateTo('TheoryPracticeWorkshopsManagement')"
      class="submit-button"
    >
      Warsztaty - teoria i praktyka - warsztaty
    </v-btn>
    <v-btn
      @click="navigateTo('TheoryPracticeWorkshopsLessonsManagement')"
      class="submit-button"
    >
      Warsztaty - teoria i praktyka - lekcje
    </v-btn>
    <v-btn
      @click="navigateTo('TheoryPracticeWorkshopsLessonsPracticesManagement')"
      class="submit-button"
    >
      Warsztaty - teoria i praktyka - praktyka do lekcji
    </v-btn>
    <v-btn
      @click="navigateTo('TheoryPracticeWorkshopsLessonsTheoriesManagement')"
      class="submit-button"
    >
      Warsztaty - teoria i praktyka - teoria do lekcji
    </v-btn>
    <v-btn
      @click="navigateTo('TheoryPracticeWorkshopsLessonsAttachmentsManagement')"
      class="submit-button"
    >
      Warsztaty - teoria i praktyka - załączniki do lekcji
    </v-btn>
  </div>
</template>

<script>
import axios from "../axios";
export default {
  methods: {
    navigateTo(routeName) {
      const currentComponentName = this.$router.currentRoute.name;
      if (currentComponentName && currentComponentName !== routeName) {
        this.$router.push({ name: routeName });
      }
    },

    async downloadMailingList() {
      const response = await axios.get(`management/newsletter`, {
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer",
      });
      let fileName = "";
      for (const [key, value] of Object.entries(response.headers)) {
        if (key === "content-disposition") {
          const res = value.split('"');
          fileName = res[1];
          fileName = unescape(fileName);
        }
      }
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `${fileName}`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(downloadUrl);
    },
  },
};
</script>

<style scoped>
.nav-buttons {
  display: flex;
  flex-wrap: wrap;
}
.submit-button {
  font-size: 12px;
  margin: 10px;
  /* flex-grow: 1; */
}
</style>