import axios from "../axios";
const packages = {
  namespaced: true,

  state: () => ({
    userWorkshops: [],
    selectedWorkshopsForPayment: null,
    availableWorkshops: [],
  }),

  mutations: {
    SET_USER_WORKSHOPS(state, workshops) {
      state.userWorkshops = workshops;
    },
    SET_SELECTED_WORKSHOPS_FOR_PAYMENT(state, workshops) {
      state.selectedWorkshopsForPayment = workshops;
    },
    SET_AVAILABLE_WORKSHOPS(state, workshops) {
      state.availableWorkshops = workshops;
    },
  },

  actions: {
    fetchAvaiableWorkshops: async ({ commit }, allWorkshops) => {
      try {
        const workshops = await axios.get(
          !allWorkshops
            ? "timePackages?type=WORKSHOPS"
            : "timePackages?type=all_workshops"
        );
        commit("SET_AVAILABLE_WORKSHOPS", workshops.data);
      } catch (error) {
        commit("SET_AVAILABLE_WORKSHOPS", []);
        console.log(error);
      }
    },

    fetchUserWorkshops: async ({ commit }) => {
      const token = window.localStorage.getItem("userToken");
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      try {
        const workshops = await axios.get("workshops");
        commit("SET_USER_WORKSHOPS", workshops.data);
      } catch (error) {
        commit("SET_USER_WORKSHOPS", []);
        console.log(error);
      }
    },
    async fetchWorkshop({ commit }, id) {
      try {
        const response = await axios.get(`workshops/${id}`);
        commit("SET_SELECTED_WORKSHOPS_FOR_PAYMENT", response.data);
      } catch (err) {
        commit("SET_SELECTED_WORKSHOPS_FOR_PAYMENT", null);
        console.log(err);
      }
    },
  },

  getters: {
    getUserWorkshops: (state) => state.userWorkshops,
    getSelectedWorkshopsForPayment: (state) =>
      state.selectedWorkshopsForPayment,
    getAvailableWorkshops: (state) => state.availableWorkshops,
  },
};
export default packages;
