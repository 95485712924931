import axios from "../axios";
import errorHandler from "../errorHandler";

const movieCategories = {
  namespaced: true,

  state: () => ({
    movieSubcategories: [],
    selectedMovieSubcategory: null,
    selectedMovieSubcategoryL2: null,
    movieSubcategoriesL2: []
  }),
  mutations: {
    SET_MOVIE_SUBCATEGORIES(state, movieSubcategories) {
      state.movieSubcategories = movieSubcategories;
    },
    SET_SELECTED_MOVIE_SUBCATEGORY(state, movieSubcategory) {
      state.selectedMovieSubcategory = movieSubcategory;
    },
    SET_SELECTED_MOVIE_SUBCATEGORYL2(state, movieSubcategoryL2) {
      state.selectedMovieSubcategoryL2 = movieSubcategoryL2;
    },
    IMAGE_FETCHED() { },
    SET_MOVIE_SUBCATEGORIES_L2(state, movieSubcategories) {
      state.movieSubcategoriesL2 = movieSubcategories;
    },
  },
  actions: {
    fetchAll: async ({ commit }, data) => {
      try {
        const forBeginners = data && data.forBeginners !== null && data.forBeginners !== undefined && data.forBeginners ? true : false
        const forExtendedSubscription = data && data.forExtendedSubscription !== null && data.forExtendedSubscription !== undefined && data.forExtendedSubscription ? true : false
        const response = await axios.get(`management/subCategories`, {
          params: {
            forBeginners,
            forExtendedSubscription
          }
        })
        commit("SET_MOVIE_SUBCATEGORIES", response.data);
      } catch (error) {
        console.log(error);
      }
    },

    fetchAllSubcategoriesL2: async ({ commit }, data) => {
      try {
        const forBeginners = data && data.forBeginners !== null && data.forBeginners !== undefined && data.forBeginners ? true : false
        const forExtendedSubscription = data && data.forExtendedSubscription !== null && data.forExtendedSubscription !== undefined && data.forExtendedSubscription ? true : false
        const response = await axios.get(`management/subCategoriesL2`, {
          params: {
            forBeginners,
            forExtendedSubscription
          }
        })
        commit("SET_MOVIE_SUBCATEGORIES_L2", response.data);
      } catch (error) {
        console.log(error);
      }
    },

    fetchAllSubcategoriesForUser: async ({ commit }, data) => {
      try {

        const forBeginners = data && data.forBeginners !== null && data.forBeginners !== undefined && data.forBeginners ? true : false
        const forExtendedSubscription = data && data.forExtendedSubscription !== null && data.forExtendedSubscription !== undefined && data.forExtendedSubscription ? true : false
        const search = data && data.search ? data.search : ''
        const response = await axios.get(`subCategories`, {
          params: {
            forBeginners,
            search,
            forExtendedSubscription
          }
        })
        commit("SET_MOVIE_SUBCATEGORIES", response.data);
      } catch (error) {
        console.log(error);
      }
    },

    setSelectedMovieSubCategory: async ({ commit }, selectedSubcategory) => {
      commit("SET_SELECTED_MOVIE_SUBCATEGORY", selectedSubcategory)
    },

    setSelectedMovieSubCategoryToNull: async ({ commit }) => {
      commit("SET_SELECTED_MOVIE_SUBCATEGORY", null)
    },

    fetchAllForUser: async ({ commit }) => {
      try {
        const response = await axios.get(`categories`)
        commit("SET_MOVIE_CATEGORIES", response.data);
      } catch (error) {
        console.log(error);
      }
    },

    async deleteMovieSubCategory({ dispatch }, data) {
      try {
        await axios.delete(`management/subCategories/${data.id}`);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie usunięto!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        if (data.forBeginners) {
          await dispatch("fetchAll", { forBeginners: true });
        }
        else if (data.forExtendedSubscription) {
          await dispatch("fetchAll", { forExtendedSubscription: true });
        }
        else {
          await dispatch("fetchAll");
        }

      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async deleteMovieSubCategoryL2({ dispatch }, data) {
      try {
        await axios.delete(`management/subCategoriesL2/${data.id}`);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie usunięto!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        if (data.forBeginners) {
          await dispatch("fetchAllSubcategoriesL2", { forBeginners: true });
        }
        else if (data.forExtendedSubscription) {
          await dispatch("fetchAllSubcategoriesL2", { forExtendedSubscription: true });
        }
        else {
          await dispatch("fetchAllSubcategoriesL2");
        }

      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async updateMovieCategory({ dispatch }, object) {
      try {
        const type = object.type
        const formData = new FormData();
        const {
          id,
          file,
          name,
          description,
          movieCategoryId,
          imagePath,
        } = object.data;
        if (file) {
          formData.append("file", file);
        }
        if (name) {
          formData.append("name", name);
        }
        if (description) {
          formData.append("description", description);
        }

        if (movieCategoryId) {
          formData.append("movieCategoryId", movieCategoryId);
        }

        if (imagePath) {
          formData.append("imagePath", imagePath);
        }

        if (type === 'FOR-BEGINNERS') {
          formData.append("forBeginners", true);
        }
        if (type === 'EXTENDED') {
          formData.append("forExtendedSubscription", true);
        }
        else {
          formData.append("forExtendedSubscription", false);
        }

        await axios.put(`management/subCategories/${id}`, formData);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Edycja przebiegła pomyślnie!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        if (type === 'FOR-BEGINNERS') {
          await dispatch("fetchAll", { forBeginners: true });
        }
        else if (type === 'EXTENDED') {
          await dispatch("fetchAll", { forExtendedSubscription: true });
        }
        else {
          await dispatch("fetchAll");
        }
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async updateMovieCategoryL2({ dispatch }, object) {
      try {
        const type = object.type
        const formData = new FormData();
        const {
          id,
          file,
          name,
          description,
          movieSubCategoryId,
          imagePath,
        } = object.data;
        if (file) {
          formData.append("file", file);
        }
        if (name) {
          formData.append("name", name);
        }
        if (description) {
          formData.append("description", description);
        }

        if (movieSubCategoryId) {
          formData.append("movieSubCategoryId", movieSubCategoryId);
        }

        if (imagePath) {
          formData.append("imagePath", imagePath);
        }

        if (type === 'FOR-BEGINNERS') {
          formData.append("forBeginners", true);
        }

        if (type === 'EXTENDED') {
          formData.append("forExtendedSubscription", true);
        }
        else {
          formData.append("forExtendedSubscription", false);
        }

        await axios.put(`management/subCategoriesL2/${id}`, formData);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Edycja przebiegła pomyślnie!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        if (type === 'FOR-BEGINNERS') {
          await dispatch("fetchAll", { forBeginners: true });
        }
        else if (type === 'EXTENDED') {
          await dispatch("fetchAll", { forExtendedSubscription: true });
        }
        else {
          await dispatch("fetchAll");
        }
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },


    async addMovieSubCategory({ dispatch }, object) {
      try {
        const type = object.type
        const formData = new FormData();
        const {
          file,
          name,
          description,
          movieCategoryId,
          imagePath,
        } = object.data;
        if (file) {
          formData.append("file", file);
        }
        if (name) {
          formData.append("name", name);
        }
        if (description) {
          formData.append("description", description);
        }

        if (movieCategoryId) {
          formData.append("movieCategoryId", movieCategoryId);
        }

        if (imagePath) {
          formData.append("imagePath", imagePath);
        }

        if (type === 'FOR-BEGINNERS') {
          formData.append("forBeginners", true);
        }
        else {
          formData.append("forBeginners", false);
        }

        if (type === 'EXTENDED') {
          formData.append("forExtendedSubscription", true);
        }
        else {
          formData.append("forExtendedSubscription", false);
        }


        await axios.post(`management/subCategories`, formData);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie dodano!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        if (type === 'FOR-BEGINNERS') {
          await dispatch("fetchAll", { forBeginners: true });
        }
        else if (type === 'EXTENDED') {
          await dispatch("fetchAll", { forExtendedSubscription: true });
        }
        else {
          await dispatch("fetchAll");
        }

      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async addMovieSubCategoryL2({ dispatch }, object) {
      try {
        const type = object.type
        const formData = new FormData();
        const {
          file,
          name,
          description,
          movieSubCategoryId,
          imagePath,
        } = object.data;
        if (file) {
          formData.append("file", file);
        }
        if (name) {
          formData.append("name", name);
        }
        if (description) {
          formData.append("description", description);
        }

        if (movieSubCategoryId) {
          formData.append("movieSubCategoryId", movieSubCategoryId);
        }

        if (imagePath) {
          formData.append("imagePath", imagePath);
        }

        if (type === 'FOR-BEGINNERS') {
          formData.append("forBeginners", true);
        }
        else {
          formData.append("forBeginners", false);
        }
        if (type === 'EXTENDED') {
          formData.append("forExtendedSubscription", true);
        }
        else {
          formData.append("forExtendedSubscription", false);
        }


        await axios.post(`management/subCategoriesL2`, formData);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie dodano!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        if (type === 'FOR-BEGINNERS') {
          await dispatch("fetchAll", { forBeginners: true });
        }
        else if (type === 'EXTENDED') {
          await dispatch("fetchAll", { forExtendedSubscription: true });
        }
        else {
          await dispatch("fetchAll");
        }

      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async getSubCategoryImageSrc({ commit }, data) {
      try {
        const response = await axios.get(
          `/subCategories/${data.subCategoryId}/image`,
          {
            responseType: "blob",
          }
        );

        const blob = response.data;
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(blob);
        commit("IMAGE_FETCHED", true);
        return imageUrl;
      } catch (error) {
        console.log(error);
        return null;
      }
    },

    async getSubCategoryL2ImageSrc({ commit }, data) {
      try {
        const response = await axios.get(
          `/subCategoriesL2/${data.subCategoryL2Id}/image`,
          {
            responseType: "blob",
          }
        );

        const blob = response.data;
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(blob);
        commit("IMAGE_FETCHED", true);
        return imageUrl;
      } catch (error) {
        console.log(error);
        return null;
      }
    },

    async getSubCategoryL2Details({ commit }, subcategoryL2Id) {
      try {
        const response = await axios.get(`/subCategoriesL2/${subcategoryL2Id}`,);
        commit("SET_SELECTED_MOVIE_SUBCATEGORYL2", response.data)
      } catch (error) {
        commit("SET_SELECTED_MOVIE_SUBCATEGORYL2", null)
        console.log(error);
        return null;
      }
    },

  },
  getters: {
    getMovieSubCategories: (state) => state.movieSubcategories,
    getSelectedMovieSubCategory: state => state.selectedMovieSubcategory,
    getSelectedMovieSubCategoryL2: state => state.selectedMovieSubcategoryL2,
    getMovieSubCategoriesL2: (state) => state.movieSubcategoriesL2,
  },
}
export default movieCategories;