<template>
  <v-card width="100%">
    <management-nav />
    <v-card-title>
      Karnety dla początkujących - linki
      <v-spacer></v-spacer>
    </v-card-title>

    <v-row class="ml-1">
      <v-col cols="12">
        <v-row
          class="pb-0"
          v-for="(ticketLink, index) in ticketsLinks"
          :key="`codeValue${index}`"
        >
          <v-col cols="12" sm="5">
            <v-text-field-with-validation
              v-model="ticketLink.link"
              label="Link"
              color="indigo"
              outlined
              dense
            />
          </v-col>

          <v-col cols="12" sm="5">
            <v-text-field-with-validation
              v-model="ticketLink.name"
              label="Podpis"
              color="indigo"
              outlined
              dense
            />
          </v-col>

          <v-col cols="12" sm="2">
            <v-btn
              @click="removeTicketLink(ticketLink, index)"
              class="ml-2"
              fab
              x-small
              color="red"
            >
              <font-awesome-icon icon="minus" />
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="ml-1 mb-4">
      <v-col>
        <v-btn @click="addNextTicketLink" color="orange">
          Dodaj kolejny link
          <font-awesome-icon icon="plus" />
        </v-btn>
      </v-col>
    </v-row>

    <v-btn class="ml-4" style="width: 100%" @click="saveLinks" color="green"
      >Zapisz</v-btn
    >
  </v-card>
</template>

<script>
import VTextFieldWithValidation from "../components/inputs/VTextFieldWithValidation.vue";
import ManagementNav from "../components/ManagementNav.vue";
export default {
  name: "TicketsLinksManagement",
  components: {
    ManagementNav,
    VTextFieldWithValidation,
  },
  async created() {
    await this.$store.dispatch("coursesTickets/fetchCourseTicketsLinks", {
      type: "BEGINNERS",
    });
  },
  data: () => ({
    ticketsLinks: [],
    ticketsLinksToRemove: [],
  }),
  computed: {
    coursesTicketsLinks() {
      return this.$store.getters[
        "coursesTickets/getCoursesTicketsBeginnersLinks"
      ];
    },
  },

  watch: {
    coursesTicketsLinks() {
      this.ticketsLinks = this.coursesTicketsLinks.map((link) => {
        return {
          id: link.id,
          link: link.link,
          name: link.name,
          type: link.type,
        };
      });
    },
  },
  methods: {
    async saveLinks() {
      let isEmptyLink = false;
      const linksArray = this.ticketsLinks.map(function (item) {
        if (!item.link || !item.name) {
          isEmptyLink = true;
        }
        return item.link;
      });

      let moreThanOneSameLinks = linksArray.some(function (item, idx) {
        return linksArray.indexOf(item) != idx;
      });
      if (isEmptyLink) {
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "red",
          message: "Linki oraz ich podpisy muszą być uzupełnione",
        });
        return;
      } else if (moreThanOneSameLinks) {
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "red",
          message: "Wszystkie linki muszą być unikalne",
        });
        return;
      } else {
        await this.handleRemoveTicketsLinks();
        await this.handleAddAndUpdateTicketsLinks();
      }
    },
    addNextTicketLink() {
      this.ticketsLinks.push({
        link: "",
        name: "",
        type: "BEGINNERS",
      });
    },
    removeTicketLink(ticketLink, index) {
      if (ticketLink.id) {
        this.ticketsLinksToRemove.push(ticketLink);
      }
      this.ticketsLinks.splice(index, 1);
    },

    async handleRemoveTicketsLinks() {
      if (this.ticketsLinksToRemove && this.ticketsLinksToRemove.length > 0) {
        for await (const ticket of this.ticketsLinksToRemove) {
          await this.$store.dispatch(
            "coursesTickets/deleteCourseTicketsLinks",
            ticket.id
          );
        }
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "success",
          message: "Pomyślnie usunięto linki",
        });

        this.ticketsLinksToRemove = [];
      }
    },

    async handleAddAndUpdateTicketsLinks() {
      if (this.ticketsLinks) {
        for await (const ticket of this.ticketsLinks) {
          const { id, link, name, type } = ticket;
          if (!id) {
            const createdTicketId = await this.$store.dispatch(
              "coursesTickets/createCourseTicketsLinks",
              {
                name,
                link,
                type,
              }
            );
            ticket.id = createdTicketId;
          } else {
            await this.$store.dispatch(
              "coursesTickets/updateCourseTicketsLinks",
              {
                id,
                name,
                link,
                type,
              }
            );
          }
        }

        this.$store.dispatch("snackbar/showSnackbar", {
          color: "success",
          message: "Pomyślnie zaktuazlizowano linki",
        });
      }
    },
  },
};
</script>

<style scoped>
.item-image {
  width: 130px;
  height: 130px;
  border-radius: 1rem;
  margin: 1rem;
  cursor: pointer;
  object-fit: cover;
}
.image-container {
  position: relative;
}
.slider-prev,
.slider-next {
  position: absolute;
  top: 40%;
  font-weight: lighter;
  color: #000;
  background: rgb(255, 255, 255, 0.75);
  cursor: pointer;
  border-radius: 100px;
  padding: 0 5px;
  font-size: 20px;
}
.slider-prev {
  left: 1.3rem;
}
.slider-next {
  right: 1.3rem;
}
.delete-image-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
</style>
