<template>
  <v-row justify="center">
    <v-dialog
      max-width="500px"
      :value="isVisible"
      persistent
      @keydown.esc="closeModal"
    >
      <v-card>
        <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager">
          <v-card-title>
            <p class="ml-1">Wybierz dzień tygodnia</p>
          </v-card-title>
          <v-card-text>
            <v-select
              v-if="days && selectedDay"
              v-model="selectedDay"
              :items="days"
              item-value="value"
              item-text="name"
              outlined
              color="indigo"
              dense
              label="Dzień tygodnia"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text v-on:click="closeModal">
              <font-awesome-icon icon="times" class="mr-1" />
              Anuluj
            </v-btn>
            <v-btn
              color="success"
              text
              :disabled="invalid"
              v-on:click="confirm"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              Zapisz
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import moment from "moment";

export default {
  name: "AddVideoToScheduleModal",
  props: ["isVisible"],
  emits: ["closeModal", "confirm"],
  components: {
    ValidationObserver,
  },

  data: () => ({
    selectedDay: "MONDAY",
  }),

  updated() {
    this.decision = true;
  },

  methods: {
    closeModal() {
      this.decision = false;
      this.$emit("closeModal");
    },

    confirm() {
      this.$emit("confirm", this.selectedDay);
      this.$emit("closeModal");
    },
  },

  mounted() {
    this.selectedDay = moment().format("dddd").toUpperCase();
  },

  computed: {
    days() {
      return [
        {
          name: "Poniedziałek",
          value: "MONDAY",
        },
        {
          name: "Wtorek",
          value: "TUESDAY",
        },
        {
          name: "Środa",
          value: "WEDNESDAY",
        },
        {
          name: "Czwartek",
          value: "THURSDAY",
        },
        {
          name: "Piątek",
          value: "FRIDAY",
        },
        {
          name: "Sobota",
          value: "SATURDAY",
        },
        {
          name: "Niedziela",
          value: "SUNDAY",
        },
      ];
    },
  },
};
</script>
<style type="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
