<template>
  <v-card width="100%">
    <management-nav />
    <v-card-title>
      Lista użytkowników
      <v-spacer></v-spacer>
      <v-text-field v-model="search" label="Szukaj" single-line hide-details
        ><font-awesome-icon
          icon="search"
          slot="prepend-inner"
          class="mr-1 mt-1"
      /></v-text-field>
    </v-card-title>
    <v-data-table
      :loading="!items"
      loading-text="Trwa pobieranie danych..."
      :headers="headers"
      :items="items"
      :search="search"
      :items-per-page="30"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 30, -1] }"
    >
      <template v-slot:no-data>
        <v-alert :value="items && items.length === 0">
          Brak użytkowników
        </v-alert>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          icon
          color="green"
          @click="getUserSubscriptions(item.id)"
          v-on:click="isModalVisible = true"
        >
          <font-awesome-icon icon="shopping-cart" />
        </v-btn>
        <span>Lista zakupów</span>
      </template>

      <template v-slot:[`item.usersWorkshops`]="{ item }" v-if="showWorkshops">
        <div
          class="workshopItem"
          v-for="availableWorkshop in availableWorkshops"
          :key="availableWorkshop.id"
        >
          <v-checkbox
            :label="availableWorkshop.name"
            v-model="userWorkshops[`${item.id}_${availableWorkshop.id}`]"
            @change="handleWorkshopsChange(availableWorkshop.id, item.id)"
            :id="`${item.id}_${availableWorkshop.id}`"
          ></v-checkbox>
        </div>
      </template>

      <template v-slot:[`item.subscriptionExpiryDate`]="{ item }">
        <template>
          <p
            :id="item.id"
            v-html="formatExpiryDate(item.subscriptionExpiryDate)"
          />
          <v-btn icon color="green" @click="editDate(item)">
            <font-awesome-icon icon="edit" />
          </v-btn>
        </template>
      </template>

      <template v-slot:[`item.extendedSubscriptionExpiryDate`]="{ item }">
        <template>
          <p
            :id="item.id"
            v-html="formatExpiryDate(item.extendedSubscriptionExpiryDate)"
          />
          <v-btn icon color="green" @click="editDateForExtended(item)">
            <font-awesome-icon icon="edit" />
          </v-btn>
        </template>
      </template>

      <template v-slot:[`item.subscriptionForBeginnersExpiryDate`]="{ item }">
        <template>
          <p
            :id="item.id"
            v-html="formatExpiryDate(item.subscriptionForBeginnersExpiryDate)"
          />
          <v-btn icon color="green" @click="editDateForBeginners(item)">
            <font-awesome-icon icon="edit" />
          </v-btn>
        </template>
      </template>
    </v-data-table>

    <UserSubscriptionsModal
      :isVisible="isModalVisible"
      @closeModal="closeModal()"
    />
    <SelectDateModal
      :isVisible="isSelectDateModalVisible"
      @closeModal="closeSelectDateModal()"
      @dateSelected="dateSelected"
      :withPrice="true"
    />
    <SelectDateModal
      :isVisible="isSelectDateForBeginnersModalVisible"
      @closeModal="closeSelectDateForBeginnersModal()"
      @dateSelected="dateForBeginnersSelected"
      :withPrice="true"
    />

    <SelectDateModal
      :isVisible="isSelectDateForExtendedModalVisible"
      @closeModal="closeSelectDateForExtendedModal()"
      @dateSelected="dateSelectedExtended"
      :withPrice="true"
    />

    <v-btn
      fab
      dark
      large
      color="green"
      fixed
      left
      bottom
      v-on:click="isModalVisible = true"
    >
      <font-awesome-icon icon="plus" />
    </v-btn>
  </v-card>
</template>

<script>
import UserSubscriptionsModal from "../components/modals/UserSubscriptionsModal.vue";
import axios from "../axios";
import ManagementNav from "../components/ManagementNav.vue";
import moment from "moment";
import SelectDateModal from "../components/modals/SelectDateModal";
export default {
  name: "UsersListManagement",
  components: {
    UserSubscriptionsModal,
    ManagementNav,
    SelectDateModal,
  },
  async mounted() {
    await this.$store.dispatch("workshops/fetchAvaiableWorkshops", true);
    await this.$store.dispatch("users/fetchAll");

    this.items.forEach((user) => {
      const userWorkshopsIds = user.workshopsIds;
      this.availableWorkshops.forEach((availableWorkshop) => {
        this.userWorkshops[`${user.id}_${availableWorkshop.id}`] =
          userWorkshopsIds.includes(availableWorkshop.id) ? true : false;
      });
    });
    setTimeout(() => {
      this.showWorkshops = true;
    }, 500);
  },
  data: () => ({
    headers: [
      { text: "Imię i nazwisko", value: "fullName" },
      { text: "Email", value: "email" },
      { text: "Numer telefonu", value: "phone" },
      { text: "Aktywna subskrypcja", value: "activeSubscription" },
      { text: "Data wygaśnięcia subskrypcji", value: "subscriptionExpiryDate" },
      // {
      //   text: "Aktywna subskrypcja rozszerzona",
      //   value: "activeExtendedSubscription",
      // },
      {
        text: "Data wygaśnięcia subskrypcji rozszerzonej",
        value: "extendedSubscriptionExpiryDate",
      },
      {
        text: "Aktywna subskrypcja dla początkujących",
        value: "activeSubscriptionForBeginners",
      },
      {
        text: "Data wygaśnięcia subskrypcji dla początkujących",
        value: "subscriptionForBeginnersExpiryDate",
      },
      { text: "Zakupy użytkownika", value: "actions", sortable: false },
      {
        text: "Warsztaty użytkownika",
        value: "usersWorkshops",
        sortable: false,
      },
    ],
    search: "",
    isModalVisible: false,
    giftCard: null,
    isSelectDateModalVisible: false,
    selectedUser: null,
    userWorkshops: {},
    showWorkshops: false,
    loading: false,
    isSelectDateForBeginnersModalVisible: false,
    isSelectDateForExtendedModalVisible: false,
  }),
  computed: {
    items() {
      return this.$store.getters["users/getUsers"];
    },
    availableWorkshops() {
      return this.$store.getters["workshops/getAvailableWorkshops"];
    },
  },
  methods: {
    async handleWorkshopsChange(workshopId, userId) {
      if (!this.loading) {
        this.loading = true;
        try {
          await axios.post(`management/timePackages/${workshopId}/addUser`, {
            userId,
          });

          if (this.userWorkshops[`${userId}_${workshopId}`]) {
            this.$store.dispatch(
              "snackbar/setSnackbar",
              {
                color: "success",
                icon: "check",
                message: "Pomyślnie dodano użytkownika do warsztatów!",
              },
              { root: true }
            );
            this.$store.dispatch("snackbar/toggleSnackbar", true, {
              root: true,
            });
          } else {
            this.$store.dispatch(
              "snackbar/setSnackbar",
              {
                color: "success",
                icon: "check",
                message: "Pomyślnie usunięto użytkownika z warsztatów!",
              },
              { root: true }
            );
            this.$store.dispatch("snackbar/toggleSnackbar", true, {
              root: true,
            });
          }
        } catch (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message === "Too many users"
          ) {
            const checkboxElement = document.getElementById(
              `${userId}_${workshopId}`
            );
            checkboxElement.click();
            this.$store.dispatch(
              "snackbar/setSnackbar",
              {
                color: "red",
                icon: "exclamation-triangle",
                message:
                  "Do warsztatów zapisała się już maksymalna liczba uczestników",
              },
              { root: true }
            );
            this.$store.dispatch("snackbar/toggleSnackbar", true, {
              root: true,
            });
          }
        } finally {
          this.loading = false;
        }
      }
    },
    editDate(user) {
      this.selectedUser = user;
      this.isSelectDateModalVisible = true;
    },
    editDateForBeginners(user) {
      this.selectedUser = user;
      this.isSelectDateForBeginnersModalVisible = true;
    },
    editDateForExtended(user) {
      this.selectedUser = user;
      this.isSelectDateForExtendedModalVisible = true;
    },
    closeSelectDateModal() {
      this.isSelectDateModalVisible = false;
    },
    closeSelectDateForBeginnersModal() {
      this.isSelectDateForBeginnersModalVisible = false;
    },
    closeSelectDateForExtendedModal() {
      this.isSelectDateForExtendedModalVisible = false;
    },
    async dateSelected(e, date, price) {
      this.isSelectDateModalVisible = false;
      await axios.post(`management/timePackages/order`, {
        userId: this.selectedUser.id,
        price,
        date,
      });
      this.$store.dispatch("users/fetchAll");
    },

    async dateSelectedExtended(e, date, price) {
      this.isSelectDateModalVisible = false;
      await axios.post(`management/timePackagesExtended/order`, {
        userId: this.selectedUser.id,
        price,
        date,
      });
      this.$store.dispatch("users/fetchAll");
      this.closeSelectDateForExtendedModal();
    },

    async dateForBeginnersSelected(e, date, price) {
      this.isSelectDateForBeginnersModalVisible = false;
      await axios.post(`management/timePackages/orderForBeginners`, {
        userId: this.selectedUser.id,
        price,
        date,
      });
      this.$store.dispatch("users/fetchAll");
    },

    formatExpiryDate(date) {
      if (date != null) {
        const formattedDate = moment(date).format("YYYY-MM-DD");
        return formattedDate;
      }
      return null;
    },
    htmlToFormattedText(text) {
      const regex =
        /((http|https|ftp|ftps):\/\/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g;
      text = text.replace(regex, '<a href="$1">$1</a>');
      return text;
    },
    getUserSubscriptions(id) {
      this.$store.dispatch("userSubscriptions/fetchAllByUserId", id);
    },
    closeModal() {
      this.isModalVisible = false;
      this.giftCard = null;
    },
  },
};
</script>

<style></style>
