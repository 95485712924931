<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex xs12 sm6 offset-sm3 mt-6 class="transparent-bg">
        <h1 class="text-center">
          Dziękujemy za skorzystanie z naszego serwisu
        </h1>
        <blockquote class="blockquote text-center">
          Jeśli płatność przebiegła pomyślnie, zakupiony kurs powinien pojawić
          się na liście Twoich dostępnych kursów w platformie jogi online
          {{ isExtendedSubscription ? "rozszerzonej" : "ogólnej" }}.
        </blockquote>

        <!-- <blockquote
          class="blockquote text-center"
          v-if="!isExtendedSubscription"
        >
          Wykup również dostęp do platformy jogi online w wersji rozszerzonej
          <a class="center">
            <div class="primary-button" @click="redirectToCoursesExtended">
              Wykup dostęp do platformy rozszerzonej
            </div>
          </a>
        </blockquote> -->
        <!-- <telegram class="telegram" /> -->
        <blockquote class="blockquote text-center">
          <a class="center">
            <div class="primary-button" @click="redirect">
              Przejdź do zakupionych kursów
            </div>
          </a>
        </blockquote>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  metaInfo: {
    title: "Dagajoga subskrypcja - portal jogi online",
  },
  data() {
    return {
      isExtendedSubscription: false,
    };
  },
  mounted() {
    if (this.$route.query.rozszerzona && this.$route.query.rozszerzona == "1") {
      this.isExtendedSubscription = true;
    }
  },
  methods: {
    redirect() {
      if (this.isExtendedSubscription) {
        this.redirectToCoursesExtended();
      } else {
        this.$router.push({ name: "Courses" });
      }
    },
    redirectToCoursesExtended() {
      this.$router.push({ name: "CoursesExtended" });
    },
  },
};
</script>

<style scoped>
.telegram {
  position: absolute;
  bottom: 0;
  right: 1rem;
}
.transparent-bg {
  position: relative;
  padding: 50px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.664);
}
i {
  font-size: 120px;
}
.btn-gradient {
  width: fit-content;
  padding: 15px 25px;
  border-radius: 30px;
  font-size: 1.4rem;
  cursor: pointer;
}
.center {
  display: flex;
  justify-content: center;
  margin-right: 20px;
  margin-top: 15px;
  cursor: default;
}
a {
  text-decoration: none;
}
.primary-button {
  width: 320px;
}
</style>
