import axios from "axios";
import router from "./router";

//#PROD
export const PAYMENT_URL = "https://secure.przelewy24.pl/trnDirect";
export const API_URL = "https://web.dagajoga.pl/api";
export const SERVER_URL = "https://web.dagajoga.pl/api/static/";
export const FRONT_URL = "https://web.dagajoga.pl";

//#DEV
// export const PAYMENT_URL = "https://sandbox.przelewy24.pl/trnDirect";
// export const API_URL = "http://77.55.213.53:3010/api";
// export const SERVER_URL = "http://77.55.213.53:3010/api/static/";
// export const FRONT_URL = "http://77.55.213.53:4010";

//#LOCAL
// export const PAYMENT_URL = "https://sandbox.przelewy24.pl/trnDirect";
// export const API_URL = "http://localhost:3010/api";
// export const SERVER_URL = "http://localhost:3010/api/static/";
// export const FRONT_URL = "http://localhost:8080";

const instance = axios.create({
  baseURL: API_URL,
});

instance.interceptors.request.use(
  // Return a successful response back to the calling service
  (request) => {
    const userToken = window.localStorage.getItem("userToken");
    const adminToken = window.localStorage.getItem("token");
    if (adminToken) {
      request.headers.common["Authorization"] = `Bearer ${adminToken}`;
    } else if (userToken) {
      request.headers.common["Authorization"] = `Bearer ${userToken}`;
    }
    return request;
  }
);

instance.interceptors.response.use(
  (response) => {
    // Return a successful response back to the calling service
    return response;
  },
  (error) => {
    // Return any error which is not due to authentication back to the calling service
    if (
      error &&
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      window.localStorage.removeItem("userToken");
      window.localStorage.removeItem("token");
      router.push({ name: "Auth" });
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);

// // Obtain the fresh token each time the function is called
// const getToken = () => {
//   return localStorage.getItem("token");
// };

// // Use interceptor to inject the token to requests
// instance.interceptors.request.use((request) => {
//   request.headers["Authorization"] = `Bearer ${getToken()}`;
//   return request;
// });

export default instance;
