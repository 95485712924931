var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-s"},[_c('div',{staticClass:"d-flex title-row"},[(_vm.comments.length == 0)?_c('h1',[_vm._v("Brak komentarzy")]):_c('h1',[_vm._v("Komentarze("+_vm._s(_vm.numberOfComments)+")")]),(
        _vm.profile &&
        _vm.hasAdminPermissions &&
        _vm.members &&
        _vm.members.length > 0 &&
        _vm.lesson &&
        _vm.type === 'lesson'
      )?_c('div',{staticClass:"user-select"},[_c('v-select',{attrs:{"items":_vm.members,"item-value":"value","item-text":"name","label":"Wybierz użytkownika","clearable":""},model:{value:(_vm.selectedUserId),callback:function ($$v) {_vm.selectedUserId=$$v},expression:"selectedUserId"}})],1):_vm._e()]),(!_vm.isFetching)?_c('div',{staticClass:"display-comments"},_vm._l((_vm.comments),function(comment){return _c('Comment',{key:comment.comment_id,attrs:{"comment":comment,"timePackageId":_vm.id,"customKey":((comment.comment.id) + "_" + _vm.refreshKey),"hasAdminPermissions":_vm.hasAdminPermissions},on:{"subCommentAdded":function($event){return _vm.subCommentAdded()},"deleted":_vm.handleDeletedComment}})}),1):_vm._e(),(_vm.loggedIn)?_c('div',{staticClass:"add-comment"},[_c('quill-editor',{ref:"myQuillEditor",staticClass:"window-edit",attrs:{"options":_vm.editorOption},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}}),_c('v-file-input',{staticClass:"mt-8 mb-8",attrs:{"accept":"image/png, image/jpeg, image/jpg","placeholder":"Dodaj zdjęcie(a) do komentarza","prepend-icon":"mdi-camera","multiple":"","color":"#B28A8A"},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}}),_c('div',{staticClass:"button-s",on:{"click":function($event){return _vm.addComment(_vm.id)}}},[_vm._v("Dodaj komentarz")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }