var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],staticClass:"card"},[(_vm.withStars)?_c('font-awesome-icon',{staticClass:"star",class:_vm.timePackageItem.favorite ? 'star-gold' : '',attrs:{"icon":"star"},on:{"click":_vm.handleStarClick}}):_vm._e(),_c('img',{staticStyle:{"position":"relative"},attrs:{"id":_vm.timePackageItem.id,"alt":"\n    Nauli,\n    anatomia jogi.\n    kręgosłup,\n    joga wzmacniająca,\n    kurs jogi online,\n    joga online,\n    filmy jogi,\n    techniki oddechowe,\n    joga balanse,\n    joga video,\n    uważna praktyka,\n    praktyka dla początkujących\n    ","loading":"lazy"},on:{"click":function($event){return _vm.redirect(_vm.timePackageItem)}}}),_c('div',{staticClass:"description",on:{"click":function($event){return _vm.redirect(_vm.timePackageItem)}}},[_c('h2',{domProps:{"innerHTML":_vm._s(_vm.timePackageItem.name)}})]),_c('div',{staticClass:"nameContent",style:((_vm.isSubcategory && _vm.numberOfMoviesInSubcategories) ||
        _vm.timePackageItem.numberOfTimePackageItems
          ? 'bottom: 2rem'
          : ''),domProps:{"innerHTML":_vm._s(_vm.timePackageItem.name)},on:{"click":function($event){return _vm.redirect(_vm.timePackageItem)}}}),(
        (_vm.isSubcategory && _vm.numberOfMoviesInSubcategories) ||
        _vm.timePackageItem.numberOfTimePackageItems
      )?_c('div',{staticClass:"numberOfVideos",on:{"click":function($event){return _vm.redirect(_vm.timePackageItem)}}},[_vm._v(" "+_vm._s(_vm.timePackageItem.numberOfTimePackageItems || _vm.numberOfMoviesInSubcategories)+" "),_c('img',{staticStyle:{"opacity":"100% !important"},attrs:{"src":require("../assets/videos-icon.svg"),"alt":"videos-icon"}})]):_vm._e(),(
        this.activeSubscriptions &&
        this.activeSubscriptions.length < 2 &&
        this.activeSubscriptions[0].freeForRegistration &&
        _vm.timePackageItem.includedInFreeSubscription
      )?_c('div',{staticClass:"freeSubscription",on:{"click":function($event){return _vm.redirect(_vm.timePackageItem)}}},[_vm._v(" Dostępne za darmo przez 24h ")]):_vm._e()],1),(
      _vm.timePackageItem &&
      !_vm.isSubcategory &&
      !_vm.timePackageItem.numberOfTimePackageItems &&
      !_vm.isExtendedSubscription
    )?_c('div',{staticClass:"mt-1",staticStyle:{"margin-bottom":"3rem"}},[_c('add-video-to-schedule',{attrs:{"selectedVideo":_vm.timePackageItem,"withoutMargin":true}})],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"card-hidden"})])}
var staticRenderFns = []

export { render, staticRenderFns }