<template>
  <div>
    <div class="card" v-show="!isLoading">
      <font-awesome-icon
        icon="star"
        class="star"
        :class="timePackageItem.favorite ? 'star-gold' : ''"
        v-if="withStars"
        @click="handleStarClick"
      />
      <img
        @click="redirect(timePackageItem)"
        :id="timePackageItem.id"
        alt="
      Nauli,
      anatomia jogi.
      kręgosłup,
      joga wzmacniająca,
      kurs jogi online,
      joga online,
      filmy jogi,
      techniki oddechowe,
      joga balanse,
      joga video,
      uważna praktyka,
      praktyka dla początkujących
      "
        style="position: relative"
        loading="lazy"
      />
      <div class="description" @click="redirect(timePackageItem)">
        <h2 v-html="timePackageItem.name"></h2>
        <!-- <p v-html="timePackageItem.description"></p> -->
      </div>
      <div
        class="nameContent"
        @click="redirect(timePackageItem)"
        v-html="timePackageItem.name"
        :style="
          (isSubcategory && numberOfMoviesInSubcategories) ||
          timePackageItem.numberOfTimePackageItems
            ? 'bottom: 2rem'
            : ''
        "
      ></div>
      <div
        v-if="
          (isSubcategory && numberOfMoviesInSubcategories) ||
          timePackageItem.numberOfTimePackageItems
        "
        class="numberOfVideos"
        @click="redirect(timePackageItem)"
      >
        {{
          timePackageItem.numberOfTimePackageItems ||
          numberOfMoviesInSubcategories
        }}
        <img
          style="opacity: 100% !important"
          src="../assets/videos-icon.svg"
          alt="videos-icon"
        />
      </div>

      <div
        v-if="
          this.activeSubscriptions &&
          this.activeSubscriptions.length < 2 &&
          this.activeSubscriptions[0].freeForRegistration &&
          timePackageItem.includedInFreeSubscription
        "
        class="freeSubscription"
        @click="redirect(timePackageItem)"
      >
        Dostępne za darmo przez 24h
      </div>
    </div>
    <div
      class="mt-1"
      style="margin-bottom: 3rem"
      v-if="
        timePackageItem &&
        !isSubcategory &&
        !timePackageItem.numberOfTimePackageItems &&
        !isExtendedSubscription
      "
    >
      <add-video-to-schedule
        :selectedVideo="timePackageItem"
        :withoutMargin="true"
      />
    </div>
    <div class="card-hidden" v-show="isLoading"></div>
  </div>
</template>

<script>
import axios, { SERVER_URL } from "../axios";
import router from "../router";
import AddVideoToSchedule from "./AddVideoToSchedule.vue";

export default {
  components: { AddVideoToSchedule },
  name: "Course",
  props: {
    timePackageItem: Object,
    newCourses: Boolean,
    homePractiseCourses: Boolean,
    withStars: Boolean,
    currentCategory: String,
    freeSubscription: Boolean,
    selectedSubCategoryId: String,
    isSubcategory: Boolean,
    numberOfTimePackageItems: Number,
    isExtendedSubscription: Boolean,
  },

  data() {
    return {
      isLoading: false,
      numberOfMoviesInSubcategories: 0,
    };
  },

  watch: {
    async timePackageItem(item) {
      this.isLoading = true;
      if (item) {
        await this.loadImage(item.id);
      }
      setTimeout(() => {
        this.isLoading = false;
      }, 50);
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.isSubcategory && this.timePackageItem && this.timePackageItem.id) {
      this.getNumberOfMoviesInSubcategory(this.timePackageItem.id);
    }
    if (this.timePackageItem) {
      await this.loadImage(this.timePackageItem);
    }
    setTimeout(() => {
      this.isLoading = false;
    }, 50);
  },
  computed: {
    activeSubscriptions() {
      return this.$store.getters["timePackages/getActiveSubscriptions"];
    },
  },
  methods: {
    async getNumberOfMoviesInSubcategory(subcategoryId) {
      const response = await axios.get(
        `/subCategories/${subcategoryId}/numberOfMovies?forExtended=${
          this.isExtendedSubscription ? true : false
        }`
      );
      if (response && response.data && response.data.numberOfMovies) {
        this.numberOfMoviesInSubcategories = response.data.numberOfMovies;
      } else {
        return 0;
      }
    },
    async handleStarClick() {
      if (!this.timePackageItem.favorite) {
        await this.$store.dispatch("timePackages/addToFavorite", {
          timePackageItemId: !this.isExtendedSubscription
            ? this.timePackageItem.id
            : null,
          timePackageItemExtendedId: this.isExtendedSubscription
            ? this.timePackageItem.id
            : null,
          categoryId: this.currentCategory,
          favorites: this.currentCategory === "favourites" ? true : false,
          lastWatched: this.currentCategory === "lastWatched" ? true : false,
        });
      } else {
        await this.$store.dispatch("timePackages/deleteFromFavourites", {
          favoriteItemId: this.timePackageItem.favoriteItemId,
          categoryId: this.currentCategory,
          favorites: this.currentCategory === "favourites" ? true : false,
          lastWatched: this.currentCategory === "lastWatched" ? true : false,
          isExtendedSubscription: this.isExtendedSubscription,
        });
      }
    },
    async loadImage(timePackageItem) {
      const element = document.getElementById(timePackageItem.id);
      if (element) {
        if (timePackageItem.imagePath) {
          const extension = timePackageItem.imagePath?.split(".")[1];
          if (this.isSubcategory) {
            element.src = `${SERVER_URL}${timePackageItem.id}.${extension}`;
          } else {
            element.src = `${SERVER_URL}${timePackageItem.id}.${extension}`;
          }
        }
      }
    },
    redirect(timePackageItem) {
      const currentRouteName = this.$route.name;
      if (!this.isSubcategory && !timePackageItem.numberOfTimePackageItems) {
        window.localStorage.setItem(
          "selectedCourse",
          `course_${timePackageItem.id}`
        );
        axios.post(`timePackageItems/${this.timePackageItem.id}/viewsCounter`);
        this.$store.dispatch("videos/setSelectedVideo", {
          video: this.timePackageItem,
        });

        if (!this.isExtendedSubscription) {
          this.$router.push({
            name: "VideosDemo",
            params: {
              course: this.timePackageItem,
              path:
                currentRouteName && currentRouteName === "Courses"
                  ? "subskrypcja"
                  : currentRouteName &&
                    currentRouteName === "CoursesForBeginners"
                  ? "subskrypcja-podstawy"
                  : timePackageItem.movieSubcategoryL2
                  ? `podkategoria/${
                      timePackageItem.movieSubcategoryL2.id ||
                      timePackageItem.movieSubcategoryL2
                    }`
                  : this.selectedSubCategoryId
                  ? `kategoria/${this.selectedSubCategoryId}`
                  : "subskrypcja",
            },
            query: {
              id: `${this.timePackageItem.name.replaceAll(" ", "-")}_${
                this.timePackageItem.id
              }`,
            },
          });
        } else {
          this.$router.push({
            name: "VideosDemoExtendedSubscription",
            params: {
              course: this.timePackageItem,
              path:
                currentRouteName && currentRouteName === "CoursesExtended"
                  ? "subskrypcja-rozszerzona"
                  : timePackageItem.movieSubcategoryL2
                  ? `podkategoria/${
                      timePackageItem.movieSubcategoryL2.id ||
                      timePackageItem.movieSubcategoryL2
                    }`
                  : this.selectedSubCategoryId
                  ? `kategoria/${this.selectedSubCategoryId}`
                  : "subskrypcja-rozszerzona",
            },
            query: {
              id: `${this.timePackageItem.name.replaceAll(" ", "-")}_${
                this.timePackageItem.id
              }`,
            },
          });
        }
      } else if (this.isSubcategory) {
        window.localStorage.setItem(
          "selectedCourse",
          `course_${timePackageItem.id}`
        );
        this.$store.dispatch("movieSubCategories/setSelectedMovieSubCategory", {
          movieSubcategory: timePackageItem,
        });
        router.push({
          name: "SubcategoryView",
          params: { id: timePackageItem.id },
          query: { rozszerzona: this.isExtendedSubscription ? 1 : 0 },
        });
      } else {
        this.$store.dispatch(
          "movieSubCategories/getSubCategoryL2Details",
          timePackageItem.id
        );
        router.push({
          name: "SubcategoryL2View",
          params: { id: timePackageItem.id },
          query: { rozszerzona: this.isExtendedSubscription ? 1 : 0 },
        });
      }
    },
  },
};
</script>

<style scoped>
.star {
  color: rgb(172, 165, 165);
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 20px;
  cursor: pointer;
  z-index: 198;
}

.star-gold,
.star:hover {
  color: rgb(248, 204, 9);
}

.card {
  width: 323px;
  height: 250px;
  object-fit: cover;
  position: relative;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.5s ease;
}
.card-hidden {
  width: 230px;
  height: 200px;
}

.card::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 20px;
  color: #fff;
  font-size: 0.8rem;
  background: #df2c26;
  padding: 5px;
  border-radius: 5px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.card:hover img {
  opacity: 0.5;
}
.card:hover {
  background: rgba(0, 0, 0, 0.8);
}
.card:hover .description {
  transform: translateX(0);
}
.nameContent {
  position: absolute;
  bottom: 1.2rem;
  color: #000;
  font-size: 0.7rem;
  background: linear-gradient(270deg, #c4a2a2 0%, #b28a8a 90.06%);
  border-radius: 0px 10px 10px 0px;
  padding: 8px 10px;
}
.numberOfVideos {
  position: absolute;
  bottom: 0rem;
  color: #000;
  font-size: 0.7rem;
  background: linear-gradient(270deg, #c4a2a2 0%, #b28a8a 90.06%);
  border-radius: 0px 10px 10px 0px;
  padding: 8px 10px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
}
.numberOfVideos img {
  width: 15px;
  height: 15px;
  opacity: 100% !important;
}

.freeSubscription {
  position: absolute;
  top: 0rem;
  left: 0;
  color: #000;
  font-size: 0.85rem;
  background: #fff;
  border-radius: 0px 10px 10px 0px;
  padding: 8px 10px;
  font-weight: 500;
}

img {
  width: 100%;
  height: 100%;
}

.description {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: #fff;
  z-index: 100;
  top: 15px;
}
.description h2 {
  width: 100%;
  background: linear-gradient(270deg, #c4a2a2 0%, #b28a8a 90.06%);
  padding: 8px 0;
  text-align: center;
  color: #000;
  margin-top: 20px;
  font-weight: 300;
  font-size: 14px;
}
p {
  padding: 10px 20px;
  text-align: center;
  font-size: 0.9rem;
}
.description {
  transform: translateX(-10000px);
}

@media screen and (max-width: 400px) {
  .card {
    height: 200px;
    width: 283px;
  }
  img {
    width: 283px;
  }
  p {
    font-size: 0.8rem;
  }
  .description h2 {
    padding: 5px 0;
    margin-top: 10px;
    font-size: 1.3rem;
  }
}
</style>
