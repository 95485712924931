<template>
  <div class="container">
    <theory-practice-page-header />
    <div>
      <path-navigation
        v-if="lesson"
        :paths="[
          {
            name: `Warsztaty - ${lesson.theoryPracticeWorkshop.name}`,
            to: `/warsztaty-teoria-i-praktyka/${lesson.theoryPracticeWorkshop.id}`,
          },
          {
            name: `${lesson.name} - Praktyka`,
            to: `/warsztaty-teoria-i-praktyka/${lesson.theoryPracticeWorkshop.id}/lekcje/${this.$route.params.id}/praktyka`,
          },
        ]"
      />

      <section>
        <div class="title">
          <img src="../../assets/practice-black.svg" alt="" />
          <span>Praktyka</span>
        </div>

        <div v-if="practices && practices.length > 0">
          <theory-practice-lesson-practice
            v-for="practice in practices"
            :key="practice.id"
            :practice="practice"
            :customKey="practice.id"
          />
        </div>

        <p
          v-if="attachments && attachments.length > 0"
          class="attachments-title"
        >
          Pliki do pobrania
        </p>
        <div v-if="attachments && attachments.length > 0">
          <theory-practice-lesson-attachment
            :attachment="attachment"
            v-for="attachment in attachments"
            :key="attachment.id"
          />
        </div>

        <comment-section
          v-if="lesson"
          :id="lesson ? lesson.id : null"
          :type="'lesson'"
          :lesson="lesson"
          :key="lesson ? lesson.id : new Date()"
          :isTheory="false"
        />

        <div v-else>
          <h3 class="ml-1">Brak teorii</h3>
        </div>
      </section>
    </div>

    <div class="flex justify-center text-center" id="lessons-select"></div>
  </div>
</template>

<script>
import { SERVER_URL } from "../../axios";
import CommentSection from "../../components/CommentSection.vue";
import PathNavigation from "../../components/PathNavigation.vue";
import TheoryPracticeLessonAttachment from "./components/TheoryPracticeLessonAttachment.vue";
import TheoryPracticeLessonPractice from "./components/TheoryPracticeLessonPractice.vue";
import TheoryPracticePageHeader from "./components/TheoryPracticePageHeader.vue";

export default {
  metaInfo: {
    title: "Dagajoga warsztaty - Anatomia w praktyce",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Dagajoga to szkoła internetowa o wszechstronnym systemie budowania praktyki własnej.\nPraktyka jogi połączona z nowoczesnymi metodami ćwiczeń funkcjonalnych, ćwiczeń wzmacniających.",
      },
      {
        property: "og:image",
        content: "https://dagajoga.pl/wp-content/uploads/2022/02/image-5.png",
      },
    ],
  },
  name: "TheoryPracticeWorkshopLessonPractices",
  data: () => ({}),
  components: {
    PathNavigation,
    TheoryPracticePageHeader,
    TheoryPracticeLessonPractice,
    CommentSection,
    TheoryPracticeLessonAttachment,
  },
  props: {
    path: String,
  },

  async mounted() {
    await this.$store.dispatch(
      "theoryPracticeWorkshopsLessons/fetchTheoryPracticeWorkshopsLesson",
      this.$route.params.id
    );

    await this.$store.dispatch(
      "theoryPracticeWorkshopsLessonsPractices/fetchTheoryPracticeWorkshopsLessonsPractices",
      {
        theoryPracticeWorkshopsLessonId: this.$route.params.id,
      }
    );

    await this.$store.dispatch(
      "theoryPracticeWorkshopsLessonsAttachments/fetchTheoryPracticeWorkshopsLessonsAttachments",
      {
        theoryPracticeWorkshopsLessonId: this.$route.params.id,
        type: "PRACTICE",
      }
    );

    const isPaid = await this.$store.dispatch(
      "theoryPracticeWorkshops/checkUserPaidForWorkshop",
      this.$route.params.workshopId
    );

    if (!isPaid) {
      this.redirectLogin();
    }
  },

  computed: {
    practices() {
      return this.$store.getters[
        "theoryPracticeWorkshopsLessonsPractices/getTheoryPracticeWorkshopsLessonsPractices"
      ];
    },
    lesson() {
      return this.$store.getters[
        "theoryPracticeWorkshopsLessons/getTheoryPracticeWorkshopLesson"
      ];
    },
    attachments() {
      return this.$store.getters[
        "theoryPracticeWorkshopsLessonsAttachments/getTheoryPracticeWorkshopsLessonsAttachments"
      ];
    },
  },
  methods: {
    getImageSrc(lesson) {
      const extension = lesson.imagePath.split(".")[1];

      const src = `${SERVER_URL}lessonsImages/${
        lesson.id
      }.${extension}?d=${new Date().getTime()}`;
      return src;
    },
    redirectLogin(signup) {
      this.$router.push({
        name: "Auth",
        params: { name: "", signup: signup },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 2560px;
  padding: 0 10px !important;
}
.type-content {
  border: 1px solid rgb(0, 0, 0, 0.25);
  padding: 1rem;
  border-radius: 20px;
  margin-bottom: 3rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.type-label {
  font-size: 30px;
  font-weight: 500;
}

.lessons {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
.title {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.title img {
  width: 50px;
}
.attachments-title {
  font-weight: 600;
  font-size: 22px;
}
</style>
