<template>
  <div class="wrapper" :key="customKey">
    <div class="card">
      <div class="image-modal">
        <v-dialog v-model="dialog1" width="650">
          <template v-slot:activator="{ on, attrs }">
            <img
              v-bind="attrs"
              v-on="on"
              :src="imageSrc"
              alt="
              "
              loading="lazy"
            />
          </template>

          <v-card class="px-3 py-3">
            <img
              class="modal-img"
              :src="imageSrc"
              alt="
             "
              loading="lazy"
            />
          </v-card>
        </v-dialog>
      </div>
      <div class="text-username">
        <div class="username">{{ comment.user.login }}</div>
        <div class="text" v-html="comment.comment.text"></div>
      </div>
      <div class="comment-date">
        {{ formatCommentDate(comment.comment.createdAt) }}
        <v-btn
          v-if="hasAdminPermissions"
          icon
          color="red"
          @click="deleteComment(comment.comment.id, false)"
        >
          <font-awesome-icon icon="times" />
        </v-btn>
      </div>
      <div v-if="loggedIn">
        <v-dialog v-model="dialog" width="1000">
          <template v-slot:activator="{ on, attrs }">
            <div
              class="answer"
              v-bind="attrs"
              v-on="on"
              @click="addReply = true"
            >
              <i class="fas fa-reply"></i>
              <h5 class="small-screen-invisible">Odpowiedz</h5>
            </div>
          </template>

          <v-card class="px-3 py-3 modal">
            <div v-if="addReply" class="add-subcomment">
              <quill-editor
                v-model="subcontent"
                ref="myQuillEditor"
                :options="editorOption"
                class="window-edit"
              />
              <div class="background button-s" @click="addSubComment">
                Dodaj
              </div>
            </div>
          </v-card>
        </v-dialog>
      </div>

      <div
        class="comment-images"
        v-if="
          comment && comment.comment.images && comment.comment.images.length > 0
        "
      >
        <v-dialog
          width="650"
          v-for="image in comment.comment.images"
          :key="image.id"
          v-model="dialog3"
        >
          <template v-slot:activator="{ on, attrs }">
            <img
              @click="assignTemporarysrc(fetchCommentImage(image.id))"
              v-bind="attrs"
              v-on="on"
              :src="fetchCommentImage(image.id)"
              alt=""
              class="mini-image"
              loading="lazy"
            />
          </template>

          <v-card class="px-3 py-3">
            <img
              class="modal-img"
              :src="temporarySrc"
              alt=" kręgosłup
            "
              loading="lazy"
            />
          </v-card>
        </v-dialog>
      </div>
    </div>

    <div
      class="subcomment-card"
      v-for="subcomment in subComments"
      :key="subcomment.id"
    >
      <div class="image-modal">
        <v-dialog v-model="dialog2" width="650">
          <template v-slot:activator="{ on, attrs }">
            <img
              v-bind="attrs"
              v-on="on"
              :src="fetchSubProfilePic(subcomment.user.id)"
              alt="
              "
              loading="lazy"
            />
          </template>

          <v-card class="px-3 py-3">
            <img
              class="modal-img"
              :src="fetchSubProfilePic(subcomment.user.id)"
              alt="
              Nauli,
              subskrypcja DagaJoga
              filmy jogi,
              szkoła online,
              joga,
              kurs jogi online,
              uważna praktyka,
              joga wzmacniająca,
              joga online,
              praktyka wzmacniająca,
              praktyka,
              ćwiczenia online
              "
              loading="lazy"
            />
          </v-card>
        </v-dialog>
      </div>
      <div class="text-username">
        <div class="username">{{ subcomment.user.login }}</div>
        <div class="text" v-html="subcomment.subComment.text"></div>
      </div>
      <div class="comment-date">
        {{ formatCommentDate(subcomment.subComment.createdAt) }}

        <v-btn
          v-if="hasAdminPermissions"
          icon
          color="red"
          @click="deleteComment(subcomment.subComment.id, true)"
        >
          <font-awesome-icon icon="times" />
        </v-btn>
      </div>
    </div>
    <before-delete-modal
      :isVisible="isDeleteModalVisible"
      @closeModal="closeDeleteModal()"
      @confirmDeletion="confirmDeleteComment()"
    />
  </div>
</template>

<script>
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import { API_URL } from "../axios";
import moment from "moment";
import BeforeDeleteModal from "./modals/BeforeDeleteModal.vue";
export default {
  name: "Comment",
  props: {
    comment: Object,
    timePackageId: String,
    customKey: String,
    hasAdminPermissions: Boolean,
  },
  data: () => ({
    loggedIn: false,
    addReply: false,
    dialog1: null,
    dialog: null,
    dialog2: null,
    dialog3: null,
    isFetching: true,
    image_url: "",
    pic: "",
    imageSrc: "",
    subImageSrc: "",
    commentPics: "",
    subcontent: "",
    temporarySrc: "",
    editorOption: {
      debug: "error",
      placeholder: "Dodaj komentarz...",
      readOnly: true,
      theme: "snow",
      formats: [
        "bold",
        "color",
        "background",
        "size",
        "italic",
        "underline",
        "emoji",
        "strike",
        "align",
      ],
      modules: {
        toolbar: {
          container: [
            [{ size: ["small", false, "large"] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            ["emoji"],
          ],
          handlers: {
            emoji: function () {},
          },
        },
        toolbar_emoji: true,
      },
    },
    delta: undefined,
    showImageModal: false,
    formEntered: false,
    subComments: [],
    selectedForDelete: null,
    isDeleteModalVisible: false,
  }),
  components: {
    quillEditor,
    BeforeDeleteModal,
  },

  watch: {
    async customKey() {
      this.pic = await this.$store.dispatch(
        `auth/fetchCommentPicture`,
        this.comment.user.id
      );
      this.imageSrc = this.pic;
      await this.getSubComments();
    },
  },

  async created() {
    const token = window.localStorage.getItem("userToken");
    if (token) {
      this.loggedIn = true;
    }
    this.pic = await this.$store.dispatch(
      `auth/fetchCommentPicture`,
      this.comment.user.id
    );
    this.commentPics = this.comment.images;
    this.imageSrc = this.pic;
    this.getSubComments();
  },

  methods: {
    closeDeleteModal() {
      this.isDeleteModalVisible = false;
    },
    async confirmDeleteComment() {
      const { id, isSubComment } = this.selectedForDelete;
      if (isSubComment) {
        await this.$store.dispatch("comments/deleteSubComment", {
          subCommentId: id,
        });
      } else {
        await this.$store.dispatch("comments/deleteComment", {
          id: id,
        });
      }
      this.$emit("deleted");
    },
    async deleteComment(id, isSubComment) {
      this.isDeleteModalVisible = true;
      this.selectedForDelete = { id, isSubComment };
    },
    fetchSubProfilePic(id) {
      return `${API_URL}/profile/${id}/image`;
    },
    formatCommentDate(date) {
      const formattedDate = moment(date).format("YYYY-DD-MM HH:mm");
      return formattedDate;
    },
    fetchCommentImage(id) {
      return `${API_URL}/comments/images/${id}`;
    },
    async addSubComment() {
      const response = await this.$store.dispatch("comments/addSubComment", {
        id: this.comment.comment.id,
        text: this.subcontent,
      });
      if (response) {
        this.getSubComments();
        this.dialog = false;
        this.subcontent = "";
        this.$emit("subCommentAdded");
      }
    },
    async getSubComments() {
      const response = await this.$store.dispatch(
        "comments/fetchSubcommentsForComments",
        {
          id: this.comment.comment.id,
        }
      );
      this.subComments = response;
    },
    assignTemporarysrc(src) {
      this.temporarySrc = src;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Poppins:wght@300;400;600;700&family=Raleway:wght@400;500&family=Roboto:wght@300;400;500;700&display=swap");
* {
  font-size: "Roboto", sans-serif;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.comment-images {
  display: flex;
  gap: 0.1rem;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  .mini-image {
    width: 75px;
    height: 75px;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    cursor: pointer;
  }
}
.button-s {
  margin: 0;
  margin-top: 50px;
}

.text-username {
  width: 80%;
  margin-top: 10px;
  .username {
    max-width: 60%;
    font-weight: 600;
    font-size: 1.2rem;
    word-wrap: break-word;
    margin-left: 20px;
  }
}

.subcomment-card {
  .text-username {
    width: 100%;
    margin-left: 20px;
    .username {
      max-width: 100%;
      margin-left: 0px;
    }
  }
}
.comment-date {
  position: absolute;
  font-size: 12px;
  bottom: 0.5rem;
  right: 0.5rem;
}
.card,
.subcomment-card {
  width: 1000px;
  height: fit-content;
  overflow: hidden;
  min-height: 100px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.5s ease;
  display: flex;
  position: relative;
  margin-top: 50px;
  flex-wrap: wrap;

  img {
    height: 100px;
    width: 100px;
    object-fit: cover;
    margin: 20px;
    border-radius: 100px;
  }
  .text {
    max-width: 80%;
    display: block;
    height: fit-content;
    text-align: left;
    margin: 20px;
    margin-right: 0;
    word-wrap: break-word;
    object-fit: cover;
  }
  h5 {
    font-size: 1.2rem;
    margin: 0 7px;
  }
  i {
    font-size: 1.3rem;
    padding-top: 5px;
    transition: all 0.3s;
  }
  .answer {
    cursor: pointer;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    &:hover {
      i {
        color: #d1acac;
      }
    }
  }
}

.window-edit {
  width: 100%;
}
.button-s {
  width: 100%;
  margin-top: 20px;
}

.subcomment-card {
  clip-path: polygon(0 0, 100% 0, 100% 60%, 100% 100%, 5% 100%, 5% 60%, 5% 19%);
  background: #eeecece3;
  margin-left: 100px;
  width: 800px;
  margin-bottom: 40px;
  padding-left: 40px;
}
.add-subcomment {
  width: 100%;
  margin-bottom: 50px;
}
.modal {
  overflow: hidden;
}

::v-deep img {
  max-width: 100%;
}

.modal-img {
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1050px) {
  .card {
    width: 90vw;
    padding: 1rem;
  }
  .subcomment-card {
    width: 70vw;
  }
}

@media screen and (max-width: 600px) {
  .card,
  .subcomment-card {
    img {
      height: 70px;
      width: 70px;
      margin: 20px;
    }
    .text {
      max-width: 90%;
      margin: 20px 0;
    }
    .username {
      font-size: 1rem;
    }
    h5 {
      font-size: 0.9rem;
    }
    i {
      font-size: 0.9rem;
      padding-top: 2px;
    }
  }
  .subcomment-card {
    margin-left: 40px;
    width: 80vw;
    .text-username {
      .username {
        font-size: 0.8rem;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .small-screen-invisible {
    margin-right: 100px;
  }
  .subcomment-card {
    width: 80vw;
  }
}
@media screen and (max-width: 630px) {
  .button-s {
    margin-top: 80px;
  }
}

@media screen and (max-width: 500px) {
  .text {
    max-width: 100%;
    margin: 0px;
  }
  .card,
  .subcomment-card {
    width: 95vw;
    margin-left: 0px;
  }
  .subcomment-card {
    img {
      height: 55px;
      width: 55px;
      margin: 20px;
    }
  }

  .small-screen-invisible {
    display: none;
  }
}
@media screen and (max-width: 400px) {
  .button-s {
    margin-top: 100px;
  }
}
</style>
