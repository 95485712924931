var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"700px","value":_vm.isVisible,"persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeModal.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Lista uczestników ")])]),_c('v-card-text',[_c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.withoutExpirationDate
                ? _vm.headersWithoutExpirationDate
                : _vm.headersWithExpirationDate,"items":_vm.membersList,"items-per-page":30,"footer-props":{ 'items-per-page-options': [30, -1] },"item-class":_vm.itemRowBackground},scopedSlots:_vm._u([{key:"item.isPaid",fn:function(ref){
                var item = ref.item;
return [[_c('p',[_vm._v(" "+_vm._s(item.isPaid ? "Tak" : "Nie")+" ")])]]}},{key:"item.expirationTime",fn:function(ref){
                var item = ref.item;
return [[_c('p',[_vm._v(" "+_vm._s(_vm.formatDate(item.expirationTime))+" "),_c('v-btn',{attrs:{"icon":"","color":"indigo"},on:{"click":function($event){return _vm.editEndDate(item)}}},[_c('font-awesome-icon',{attrs:{"icon":"edit"}})],1)],1)]]}},{key:"item.delete",fn:function(ref){
                var item = ref.item;
return [[_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){(_vm.isDeleteModalVisible = true), (_vm.selectedMember = item)}}},[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1)]]}}],null,true)}),_c('before-delete-modal',{attrs:{"isVisible":_vm.isDeleteModalVisible},on:{"closeModal":function($event){return _vm.closeDeleteModal()},"confirmDeletion":function($event){return _vm.confirmDelete()}}}),_c('select-date-modal',{attrs:{"isVisible":_vm.isSelectDateModalVisible,"currentDate":_vm.currentDate},on:{"closeModal":function($event){return _vm.closeSelectDateModal()},"dateSelected":_vm.handleEndDate}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"warning","text":""},on:{"click":_vm.closeModal}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":"times"}}),_vm._v(" Zamknij ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }