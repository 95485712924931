<template>
  <div
    style="max-width: 100%"
    :class="{
      'mobile-version': mobile,
      'tablet-shown': tablet && !hideNav && !mobile,
      'tablet-hidden': ((tablet && hideNav) || (!tablet && hideNav)) && !mobile,
      'web-shown': !hideNav && !tablet && !mobile,
    }"
  >
    <div class="mobile-logo-container" v-if="mobile">
      <div class="container">
        <img
          style="width: 120px; margin-top: 25px; cursor: pointer"
          src="../assets/dagajoga-logo-cut.png"
          alt=""
          @click="redirectToHomePage"
        />
      </div>
    </div>
    <header id="site-header" v-show="!mobile">
      <div class="header-top">
        <div class="container">
          <img
            style="width: 123px; cursor: pointer"
            src="../assets/dagajoga-logo-cut.png"
            alt=""
            @click="redirectToHomePage"
          />
        </div>
      </div>
      <div
        class="content-container"
        :class="token && token.id ? 'content-container-logged' : ''"
      >
        <div class="header-main">
          <div class="container">
            <nav
              id="site-navigation"
              class="main-navigation"
              role="navigation"
              itemscope
              itemtype="http://schema.org/SiteNavigationElement"
            >
              <button class="toggle-btn"></button>
              <ul class="nav-menu">
                <li class="menu-item">
                  <a href="https://dagajoga.pl/">Strona Główna</a>
                </li>
                <li class="menu-item offer">
                  <a
                    href="https://dagajoga.pl/index.php/oferta/"
                    @mouseover="showSubMenu"
                    @mouseleave="hideSubMenu(1000)"
                  >
                    Oferta</a
                  >
                  <span
                    class="submenu-toggle"
                    @mouseover="showSubMenu"
                    @mouseleave="hideSubMenu(1000)"
                    ><svg
                      class="svg-inline--fa fa-caret-down fa-w10"
                      aria-hidden="true"
                      data-prefix="fas"
                      data-icon="caret-down"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path
                        fill="currentColor"
                        d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                      ></path>
                    </svg>
                  </span>
                  <ul
                    class="sub-menu"
                    v-if="subMenuShowed"
                    @mouseover="showSubMenu"
                    @mouseleave="hideSubMenu"
                    :class="windowHeight < 590 ? 'scrollable-menu' : ''"
                  >
                    <li
                      @mouseenter="showPlatform = true"
                      @mouseleave="showPlatform = false"
                    >
                      <div class="extra-sub-menu">
                        Platformy jogi online
                        <span class="submenu-toggle"
                          ><svg
                            class="svg-inline--fa fa-caret-down fa-w10"
                            aria-hidden="true"
                            data-prefix="fas"
                            data-icon="caret-down"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                          >
                            <path
                              fill="currentColor"
                              d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                            ></path>
                          </svg>
                        </span>
                        <div v-if="showPlatform" class="extra-sub-unfold">
                          <li>
                            <router-link class="sub" to="/subskrypcja"
                              >Platforma jogi online - Ogólna</router-link
                            >
                          </li>
                          <!-- <li>
                            <router-link
                              class="sub"
                              to="/subskrypcja-rozszerzona"
                              >Platforma jogi online - Rozszerzona</router-link
                            >
                          </li> -->
                          <li>
                            <router-link class="sub" to="/subskrypcja-podstawy"
                              >Platforma jogi online - Początek</router-link
                            >
                          </li>
                        </div>
                      </div>
                    </li>
                    <li>
                      <a
                        class="sub"
                        href="https://dagajoga.pl/index.php/zajecia-na-zywo/"
                        >Zajęcia na żywo ZOOM</a
                      >
                    </li>
                    <!-- <li>
                      <router-link class="sub" to="/warsztaty"
                        >Kursy online</router-link
                      >
                    </li> -->
                    <li
                      v-for="sequencingWorkshop in sequencingWorkshops"
                      :key="sequencingWorkshop.id"
                    >
                      <router-link
                        class="sub"
                        :to="`/warsztaty-z-sekwencjonowania/${sequencingWorkshop.id}`"
                        >{{ `${sequencingWorkshop.name}` }}</router-link
                      >
                    </li>
                    <li>
                      <a
                        class="sub"
                        href="https://dagajoga.pl/index.php/aktualnosci/"
                        >Wyjazdy z jogą i warsztaty/kursy</a
                      >
                    </li>

                    <li>
                      <a
                        class="sub"
                        href="https://dagajoga.pl/index.php/zapros-mnie/"
                        >Zaproś mnie / Konsultacje online</a
                      >
                    </li>
                    <li
                      @mouseenter="showBons = true"
                      @mouseleave="showBons = false"
                    >
                      <div class="extra-sub-menu">
                        Bony podarunkowe
                        <span class="submenu-toggle"
                          ><svg
                            class="svg-inline--fa fa-caret-down fa-w10"
                            aria-hidden="true"
                            data-prefix="fas"
                            data-icon="caret-down"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                          >
                            <path
                              fill="currentColor"
                              d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                            ></path>
                          </svg>
                        </span>
                        <div v-if="showBons" class="extra-sub-unfold">
                          <li>
                            <router-link class="sub" to="/vouchery"
                              >Bony podarunkowe</router-link
                            >
                          </li>
                          <li>
                            <router-link class="sub" to="/vouchery-podstawy"
                              >Bony podarunkowe Początek</router-link
                            >
                          </li>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="menu-item">
                  <a href="https://dagajoga.pl/index.php/o-studiu/"> O mnie</a>
                </li>
                <li
                  @mouseover="showPlatforms"
                  @mouseleave="hidePlatformOuter(1000)"
                  class="menu-item offer"
                >
                  <a href="https://dagajoga.pl/index.php/oferta/">
                    Platformy jogi online</a
                  >
                  <span class="submenu-toggle"
                    ><svg
                      class="svg-inline--fa fa-caret-down fa-w10"
                      aria-hidden="true"
                      data-prefix="fas"
                      data-icon="caret-down"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path
                        fill="currentColor"
                        d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                      ></path>
                    </svg>
                  </span>
                  <ul
                    @mouseover="showPlatforms"
                    @mouseleave="hidePlatformOuter(1000)"
                    class="sub-menu"
                    v-if="showPlatformOuter"
                  >
                    <li>
                      <router-link class="sub" to="/subskrypcja"
                        >Platforma jogi online - Ogólna</router-link
                      >
                    </li>
                    <!-- <li>
                      <router-link class="sub" to="/subskrypcja-rozszerzona"
                        >Platforma jogi online - Rozszerzona</router-link
                      >
                    </li> -->
                    <li>
                      <router-link class="sub" to="/subskrypcja-podstawy"
                        >Platforma jogi online - Początek</router-link
                      >
                    </li>
                  </ul>
                </li>
                <li class="menu-item">
                  <a href="https://dagajoga.pl/index.php/faq/">FAQ</a>
                </li>
                <li class="menu-item">
                  <a href="https://dagajoga.pl/index.php/blog/">Blog</a>
                </li>
                <li class="menu-item">
                  <router-link class="sub" to="/sklep/produkty"
                    >Sklep</router-link
                  >
                </li>
                <li v-if="token" class="menu-item">
                  <router-link
                    class="sub"
                    :to="
                      loggedIn || (token && token.id) ? '/konto' : '/logowanie'
                    "
                    >Moje konto</router-link
                  >
                </li>
                <li class="menu-item" v-if="!token || !token.id">
                  <router-link
                    class="sub"
                    :to="{ path: '/logowanie', params: { signup: 0 } }"
                    >Logowanie</router-link
                  >
                </li>
                <li class="menu-item" v-if="!token || !token.id">
                  <router-link
                    class="sub"
                    :to="{ path: '/rejestracja', params: { signup: 1 } }"
                    >Rejestracja</router-link
                  >
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="header-bottom">
          <div class="icons">
            <ul class="social-list">
              <li class="social-icon">
                <a href="https://www.instagram.com/dagajoga/">
                  <svg
                    class="svg-inline--fa fa-instagram fa-w-14"
                    aria-hidden="true"
                    data-prefix="fab"
                    data-icon="instagram"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                    ></path>
                  </svg>
                </a>
              </li>
              <li class="social-icon">
                <a href="https://www.facebook.com/Dagajoga">
                  <svg
                    class="svg-inline--fa fa-facebook fa-w-14"
                    aria-hidden="true"
                    data-prefix="fab"
                    data-icon="facebook"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M448 56.7v398.5c0 13.7-11.1 24.7-24.7 24.7H309.1V306.5h58.2l8.7-67.6h-67v-43.2c0-19.6 5.4-32.9 33.5-32.9h35.8v-60.5c-6.2-.8-27.4-2.7-52.2-2.7-51.6 0-87 31.5-87 89.4v49.9h-58.4v67.6h58.4V480H24.7C11.1 480 0 468.9 0 455.3V56.7C0 43.1 11.1 32 24.7 32h398.5c13.7 0 24.8 11.1 24.8 24.7z"
                    ></path>
                  </svg>
                </a>
              </li>
              <li class="social-icon">
                <a href="https://web.dagajoga.pl/newsletter">
                  <svg
                    class="svg-inline--fa fa-newspaper fa-w-17"
                    aria-hidden="true"
                    data-prefix="fas"
                    data-icon="newspaper"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                  >
                    <path
                      fill="currentColor"
                      d="M552 64H88c-13.255 0-24 10.745-24 24v8H24c-13.255 0-24 10.745-24 24v272c0 30.928 25.072 56 56 56h472c26.51 0 48-21.49 48-48V88c0-13.255-10.745-24-24-24zM56 400a8 8 0 0 1-8-8V144h16v248a8 8 0 0 1-8 8zm236-16H140c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h152c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12zm208 0H348c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h152c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12zm-208-96H140c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h152c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12zm208 0H348c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h152c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12zm0-96H140c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h360c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12z"
                    ></path>
                  </svg>
                </a>
              </li>
              <li class="social-icon">
                <a
                  href="https://api.whatsapp.com/send/?phone=512053818&text&type=phone_number&app_absent=0"
                >
                  <svg
                    class="svg-inline--fa fa-whatsapp fa-w-14"
                    aria-hidden="true"
                    data-prefix="fas"
                    data-icon="whatsapp"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
                    ></path>
                  </svg>
                </a>
              </li>

              <li class="social-icon" @click="moveToBucket">
                <div class="bucket-icon">
                  <div class="bucket-number">
                    {{ bucket ? bucket.numberOfProducts : "0" }}
                  </div>
                  <img src="../assets/bucket.svg" class="bucket" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>

    <SideMenu v-if="mobile" />
  </div>
</template>

<script>
import SideMenu from "../components/SideMenu.vue";
export default {
  name: "Navbar",
  components: {
    SideMenu,
  },
  computed: {
    token() {
      return this.$store.getters["auth/getToken"];
    },
    bucket() {
      return this.$store.getters["shopProducts/getBucket"];
    },
    sequencingWorkshops() {
      if (!this.token) {
        return [];
      } else {
        return this.$store.getters[
          "sequencingWorkshops/getSequencingWorkshops"
        ].filter(
          (workshop) =>
            workshop.link && workshop.link.includes("sekwencjonowania")
        );
      }
    },
  },

  watch: {
    token() {
      this.$store.dispatch("sequencingWorkshops/getOwnSequencingWorkshops");
    },
  },
  data() {
    return {
      mobile: null,
      tablet: null,
      mobileNav: null,
      windowWidth: null,
      loggedIn: false,
      subMenuShowed: false,
      timeoutSubMenu: null,
      timeoutPlatforms: null,
      showBons: false,
      showPlatform: false,
      showPlatformOuter: false,
      windowHeight: 0,
      direction: 0,
      prevDirection: 0,
      curScroll: 0,
      hideNav: false,
      logged: window.localStorage.getItem("userToken") ? true : false,
      prevScroll: window.scrollY || document.documentElement.scrollTop,
    };
  },
  beforeMount() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },

  created() {
    const token = window.localStorage.getItem("userToken");
    if (token) {
      this.loggedIn = true;
      this.$store.dispatch("sequencingWorkshops/getOwnSequencingWorkshops");
    }
    window.addEventListener("scroll", this.toggleHeader);
  },

  methods: {
    moveToBucket() {
      this.$router.push({ name: "ShopBucket" });
    },
    redirectToHomePage() {
      this.$router.push({ name: "Courses" });
    },
    toggleHeader() {
      var header = document.getElementById("site-header");
      if (!window.pageYOffset) {
        header.classList.remove("hide");
        this.hideNav = false;
      } else {
        header.classList.add("hide");
        this.hideNav = true;
      }

      // if (this.direction === 2 && this.curScroll > 52) {
      //   header.classList.add("hide");
      //   this.prevDirection = this.direction;
      // } else if (this.direction === 1) {
      //   header.classList.remove("hide");
      //   this.prevDirection = this.direction;
      // }
    },
    checkScroll() {
      this.curScroll = window.scrollY || document.documentElement.scrollTop;
      if (this.curScroll > this.prevScroll) {
        this.direction = 2;
      } else if (this.curScroll < this.prevScroll) {
        this.direction = 1;
      }
      if (this.direction !== this.prevDirection) {
        this.toggleHeader();
      }

      this.prevScroll = this.curScroll;
    },
    showSubMenu() {
      if (this.timeoutSubMenu) {
        clearTimeout(this.timeoutSubMenu);
      }
      this.subMenuShowed = true;
    },
    showPlatforms() {
      if (this.timeoutPlatforms) {
        clearTimeout(this.timeoutPlatforms);
      }
      this.showPlatformOuter = true;
    },
    hideSubMenu(delay) {
      if (delay) {
        this.timeoutSubMenu = setTimeout(() => {
          this.subMenuShowed = false;
        }, delay);
      } else {
        this.subMenuShowed = false;
      }
    },
    hidePlatformOuter(delay) {
      if (delay) {
        this.timeoutPlatforms = setTimeout(() => {
          this.showPlatformOuter = false;
        }, delay);
      } else {
        this.showPlatformOuter = false;
      }
    },

    toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    },
    checkScreen() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
      if (this.windowWidth <= 1024) {
        this.mobile = true;
        return;
      }
      if (this.windowWidth <= 1263) {
        this.tablet = true;
        return;
      }
      this.mobile = false;
      this.tablet = false;
      this.mobileNav = false;
      return;
    },
    redirectLogin(signup) {
      this.$router.push({
        name: "Auth",
        params: { name: "Courses", signup: signup },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Poppins:wght@300;400;600;700&family=Raleway:wght@400;500&display=swap");
* {
  font-family: "Montserrat";
}

.bucket {
  cursor: pointer;
  width: 15px;
}
.bucket-icon {
  position: relative;
  margin-left: 2rem;
}
.bucket-icon svg {
  width: fit-content;
}
.bucket-number {
  position: absolute;
  top: -0.75rem;
  right: -1rem;
  // color: rgb(167, 214, 253);
  color: #d1acac;
  font-weight: bold;
  cursor: pointer;
}

.header-top {
  padding: 22px 0 20px 0;
  text-align: center;
  padding-bottom: 18px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  .site-title {
    h1 {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 1.6667em;
      font-weight: 700;

      a {
        color: #000;
        text-decoration: none;
      }
      a:hover {
        color: #b28a8a;
        transition: all ease 0.35s;
      }
    }
  }

  .site-description {
    p {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 14px;
      color: #777;
    }
  }
}

.content-container {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 31px;
}
.content-container-logged {
  gap: 202px;
}

.header-main {
  margin-right: 0rem;
  padding: 0.75rem 0rem;
  padding-right: 0;

  .container {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;

    .main-navigation {
      display: flex;
      flex: auto;
      .nav-menu {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        list-style: none;

        .menu-item {
          margin: 0 1.4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          @media screen and (max-width: 1250px) {
            margin: 0 15px;
          }
          a {
            position: relative;
            text-decoration: none;
            padding: 7px 0;
            font-size: 13.8px;
            display: inline-block;
            color: #333;
            letter-spacing: 1px;
            font-weight: 400;
          }
          a::after {
            background: none repeat scroll 0 0 transparent;
            bottom: 0;
            content: "";
            display: block;
            height: 2px;
            left: 50%;
            bottom: -11px;
            position: absolute;
            background: #b28a8a;
            transition: width 0.2s ease 0s, left 0.2s ease 0s;
            width: 0;
          }

          a:hover::after {
            width: 150%;
            left: -20%;
          }
        }
      }
    }
  }
}

.offer {
  position: relative;

  .submenu-toggle {
    padding-left: 0.3rem;
  }

  a::after {
    content: none;
  }
  a:hover::after {
    width: 0% !important;
  }
}

.offer:hover .sub-menu {
  opacity: 1;
}

.v-application ul {
  padding-left: 0;
}

.offer {
  .sub-menu {
    overflow: visible;
    position: absolute;
    top: 131%;
    left: -20px;
    background: #fff;
    width: 220px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
    opacity: 0;
    z-index: 1111;
    -webkit-transition: all ease 0.35s;
    -moz-transition: all ease 0.35s;
    transition: all ease 0.35s;
    list-style: none;
    li {
      padding: 9px 20px;
      cursor: pointer;
      transition: all ease 0.35s;
      box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
      .extra-sub-menu {
        padding: 10px 0;
        display: flex;
        text-align: left;
        align-items: center;
        position: relative;
        transition: 0.3s all;
        color: #343434 !important;
        line-height: 1.65em !important;
        font-size: 13px !important;
        display: block;
        font-weight: 700 !important;
        svg {
          transform: rotate(-90deg);
          margin-top: 6px;
          margin-left: 10px;
        }
        .extra-sub-unfold {
          position: absolute;
          left: 110%;
          top: -10px;
          background: #fff;
          min-width: 230px;
          transition: 0.3s all;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
            rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
          .sub {
            padding: 11px 0 !important;
          }
        }
        // &:hover{
        //   .extra-sub-unfold{
        //     display: block;
        //   }
        // }
      }
      .sub {
        color: #343434 !important;
        line-height: 28px !important;
        line-height: 30px !important;
        font-size: 13px !important;
        display: block;
        font-weight: 700 !important;
      }
      .sub::after {
        content: none;
      }
      .sub:hover::after {
        left: 0;
      }
    }
    li:hover {
      background: #b28a8a;
    }
  }
}

.scrollable-menu {
  height: 300px !important;
  overflow: scroll !important;
}

.header-bottom {
  padding: 1rem 0;
  // @media screen and (max-width: 1450px) {
  //   padding-left: 50px;
  // }
  @media screen and (max-width: 1350px) {
    padding-left: 0px;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  ul {
    display: flex;
    list-style: none;
    li {
      margin: 0 0.5rem;
      svg {
        transition: 0.3s;
      }
    }
    li:hover svg {
      color: #b28a8a;
    }
  }
  svg {
    color: #000;
  }
}

@media (max-width: 1024px) {
  .content-container {
    width: 100%;
  }
  .header-main {
    margin-right: 2rem;
  }
}
#site-header {
  background: #fff;
  top: 0;
  max-width: 100%;
  z-index: 199;
  transition: all 0.8s;
  box-shadow: 0 1px 25px rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
}
#site-header.hide {
  top: -180px;
  // .header-top{
  //    display: none;
  // }

  @media screen and (max-width: 1263px) {
    top: -180px;
  }
  .header-main {
    margin-right: 0rem;
    padding: 0.95rem 0rem;
  }
  .header-bottom {
    display: none;
  }
}
.mobile-version {
  height: 167px;
}
.tablet-shown {
  height: 233px;
  transition: all 0.8s;
}
.tablet-hidden {
  height: 75px;
  transition: all 0.8s;
}
.web-shown {
  height: 230px;
  transition: all 0.8s;
}
.mobile-logo-container {
  img {
    margin-left: 80px;
    @media screen and (max-width: 500px) {
      margin-left: 15px;
    }
  }
}
</style>
