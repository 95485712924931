var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"width":"100%"}},[_c('management-nav'),_c('v-card-title',[_vm._v(" Dostępne warsztaty z taryfami "),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Szukaj","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('font-awesome-icon',{staticClass:"mr-1 mt-1",attrs:{"slot":"prepend-inner","icon":"search"},slot:"prepend-inner"})],1)],1),_c('v-data-table',{attrs:{"loading":!_vm.items,"loading-text":"Trwa pobieranie danych...","headers":_vm.headers,"items":_vm.items,"search":_vm.search},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":_vm.items && _vm.items.length === 0}},[_vm._v(" Brak warsztatów z taryfami ")])]},proxy:true},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('p',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(item.active ? "Tak" : "Nie"))]),_c('v-btn',{staticStyle:{"font-size":"12px","width":"100px"},on:{"click":function($event){return _vm.markStatusAsActiveOrInactive(item)}}},[_vm._v(" "+_vm._s(item.active ? "Dezaktywuj" : "Aktywuj")+" ")])]}},{key:"item.members",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('v-btn',{on:{"click":function($event){return _vm.showMembers(item)}}},[_vm._v(" Pokaż ("+_vm._s(item.members.length)+") ")]),_c('v-btn',{staticClass:"mt-4",on:{"click":function($event){return _vm.showAddEmailModal(item.id)}}},[_vm._v(" Dodaj ")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"green"},on:{"click":[function($event){return _vm.editWorkshop(item)},function($event){_vm.isModalVisible = true}]}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"edit"}})],1)]}}],null,true)},[_c('span',[_vm._v("Edytuj")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red"},on:{"click":function($event){(_vm.isDeleteModalVisible = true), (_vm.packageData = item)}}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1)]}}],null,true)},[_c('span',[_vm._v("Usuń")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"orange"},on:{"click":[function($event){return _vm.copyWorkshop(item)},function($event){_vm.isModalVisible = true}]}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"copy"}})],1)]}}],null,true)},[_c('span',[_vm._v("Kopiuj")])])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [[_c('p',{staticStyle:{"max-height":"200px","min-width":"300px","overflow":"scroll"},attrs:{"id":item.id},domProps:{"innerHTML":_vm._s(_vm.htmlToFormattedText(item.description))}})]]}},{key:"item.photo",fn:function(ref){
var item = ref.item;
return [[_c('img',{staticClass:"item-image",attrs:{"id":("sequencingWorkshopImage_" + (item.id)),"alt":"\n            joga zdrowy,\n            filmy jogi,\n            techniki oddechowe,\n            praktyka wzmacniająca,\n            joga online,\n            praktyka dla początkujących,\n            szkoła online,\n            prana vyavama,\n            joga wzmacniająca,\n            joga video,\n            ćwiczenia online,\n            uważna praktyka,\n            anatomia jogi\n            ","loading":"lazy"},on:{"click":[function($event){return _vm.editWorkshop(item)},function($event){_vm.isModalVisible = true}]}})]]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [(item.discountCodes && item.discountCodes.length > 0)?_vm._l((item.discountCodes),function(discountCode){return _c('p',{key:discountCode.id},[_c('b',[_vm._v(_vm._s(discountCode.code))]),_vm._v(" ("+_vm._s((((discountCode.value * 100).toFixed()) + "%"))+") ")])}):[_vm._v(" Brak ")]]}}],null,true)}),_c('AddTariffWorkshopModal',{attrs:{"isVisible":_vm.isModalVisible,"workshopItemData":_vm.workshopData},on:{"closeModal":function($event){return _vm.closeModal()},"updated":function($event){return _vm.refreshWorkshops()}}}),_c('SequencingWorkshopMembersModal',{attrs:{"isVisible":_vm.isWorkshopMembersModalVisible,"members":_vm.workshopMembers},on:{"closeModal":function($event){return _vm.closeWorkshopMembersModal()},"memberDeleted":function($event){return _vm.refreshWorkshops()}}}),_c('AddEmailModal',{attrs:{"label":'Dodaj użytkownika do warsztatów',"isVisible":_vm.addEmailModalVisible},on:{"confirm":_vm.addMemberToSequencingWorkshop,"closeModal":function($event){return _vm.closeAddEmailModal()}}}),_c('before-delete-modal',{attrs:{"isVisible":_vm.isDeleteModalVisible},on:{"closeModal":function($event){return _vm.closeDeleteModal()},"confirmDeletion":function($event){return _vm.confirmDelete(_vm.packageData.id)}}}),_c('v-btn',{attrs:{"fab":"","dark":"","large":"","color":"green","fixed":"","left":"","bottom":""},on:{"click":function($event){_vm.isModalVisible = true}}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }