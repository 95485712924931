var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pack"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"title"},[_c('h2',[_vm._v(_vm._s(_vm.giftCard.name))])]),_c('div',{staticClass:"price"},[(!_vm.giftCard.priceBeforeDiscount)?_c('span',[_c('p',{staticClass:"price-amount"},[_c('span',[_vm._v(_vm._s(_vm.giftCard.price.substring(0, _vm.giftCard.price.length - 3)))]),(
              _vm.giftCard.price.split('.')[1] &&
              _vm.giftCard.price.split('.')[1] !== '00'
            )?_c('span',[_vm._v("."+_vm._s(_vm.giftCard.price.split(".")[1]))]):_vm._e(),_c('span',[_vm._v(" zł")])])]):_c('span',[_c('p',{staticClass:"price-amount"},[(_vm.giftCard.price.split('.')[1] == '00')?_c('span',[_vm._v(_vm._s(_vm.giftCard.price.substring(0, _vm.giftCard.price.length - 3)))]):_c('span',[_vm._v(_vm._s(_vm.giftCard.price))]),_c('span',[_vm._v(" zł")])]),_c('div',{staticClass:"price-amount"},[_c('s',[_c('span',{},[_vm._v(_vm._s(_vm.giftCard.priceBeforeDiscount.substring( 0, _vm.giftCard.priceBeforeDiscount.length - 3 )))]),(
                _vm.giftCard.priceBeforeDiscount.split('.')[1] &&
                _vm.giftCard.priceBeforeDiscount.split('.')[1] !== '00'
              )?_c('span',[_vm._v("."+_vm._s(_vm.giftCard.priceBeforeDiscount.split(".")[1]))]):_vm._e(),_c('span',[_vm._v(" zł")])])])])]),(_vm.basicPrice)?_c('div',{staticClass:"saving"},[_c('p',{style:(_vm.getNumberOfMonthsFromPackage(_vm.giftCard) == 1
            ? 'visibility: hidden'
            : '')},[_vm._v(" oszczędzasz "+_vm._s(String( ( Number(_vm.basicPrice) * _vm.getNumberOfMonthsFromPackage(_vm.giftCard) - _vm.giftCard.price ).toFixed(0) ).replaceAll("-", ""))+" zł ")])]):_vm._e(),_c('div',{staticClass:"buy-button buyNow",on:{"click":function($event){return _vm.moveToGiftCardPayment(_vm.giftCard)}}},[_c('img',{attrs:{"src":"https://i.imgur.com/y1jQ3w9.png","loading":"lazy","alt":""}}),_c('h1',[_vm._v("Kup teraz")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }