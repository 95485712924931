<template>
  <div>
    <ReturnBtn
      :path="this.path ? this.path : `subskrypcja`"
      blackColor
    ></ReturnBtn>
    <v-row class="card">
      <div class="switch" v-if="!selectedVideo.timePackageId">
        <div
          :class="{ selectedSwitch: selectedVersion === 'DEMO' }"
          class="demo btn-gradient"
          @click="changeVersion()"
        >
          Demo
        </div>
        <div
          :class="{ selectedSwitch: selectedVersion === 'FULL' }"
          class="full-video btn-gradient"
        >
          Pełna wersja
        </div>
      </div>

      <v-col v-if="selectedVersion === 'FULL'">
        <video-player :videoId="getCourseYoutubeId()" />
        <!-- <div class="text-center no-access" v-else>
          <h3>
            Aby uzyskać dostęp do kursów, musisz posiadać konto w serwisie oraz
            mieć aktywną subskrypcję.
          </h3>
          <button class="btn-gradient" @click="moveToAuth" v-if="!loggedIn">
            Zaloguj się
          </button>
          <button class="btn-gradient" @click="moveToSubscriptions">
            Wykup dostęp
          </button>
        </div> -->
      </v-col>
      <div class="text-under">
        <h3 class="btn-gradient">
          {{ selectedVideo ? selectedVideo.name : course ? course.name : "" }}
        </h3>
        <p
          v-html="
            selectedVideo
              ? htmlToFormattedText(selectedVideo.description)
              : htmlToFormattedText(course.description)
          "
        ></p>
      </div>
    </v-row>
    <CommentSection :id="selectedVideo.id" />
  </div>
</template>

<script>
import ReturnBtn from "../components/ReturnBtn.vue";
import VideoPlayer from "../components/VideoPlayer.vue";
import CommentSection from "../components/CommentSection.vue";

export default {
  name: "WorkshopsVideos",

  components: {
    ReturnBtn,
    VideoPlayer,
    CommentSection,
  },
  props: {
    course: Object,
  },

  data: () => ({
    playing: true,
    path: "",
    loggedIn: false,
    selectedVersion: "FULL",
  }),
  mounted() {},

  async created() {
    if (this.$route.params.path) {
      this.path = this.$route.params.path;
    }
    await this.$store.dispatch(`auth/checkUserActiveSubscriptions`);
    const token = window.localStorage.getItem("userToken");
    if (token) {
      this.loggedIn = true;
    }
  },

  methods: {
    htmlToFormattedText(text) {
      const regex = /((http|https|ftp|ftps):\/\/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g;
      text = text.replace(regex, '<a href="$1">$1</a>');
      return text;
    },
    changeVersion() {
      this.$router.push({ name: "VideosDemo" });
    },
    getDemoCourseYoutubeId() {
      let courseLink;
      if (this.selectedVideo) {
        courseLink = this.selectedVideo.demoLink;
      } else if (this.course) {
        courseLink = this.course.demoLink;
      } else {
        return "";
      }
      const splittedCourseLink = courseLink.split("/");
      let videoId = splittedCourseLink[splittedCourseLink.length - 1];
      if (videoId) {
        videoId = videoId.replace(/ /g, "");
      }
      return videoId;
    },
    getCourseYoutubeId() {
      let courseLink;
      if (this.selectedVideo) {
        courseLink = this.selectedVideo.link;
      } else if (this.course) {
        courseLink = this.course.link;
      } else {
        return "";
      }

      const splittedCourseLink = courseLink.split("/");
      let videoId = splittedCourseLink[splittedCourseLink.length - 1];
      if (videoId) {
        videoId = videoId.replace(/ /g, "");
      }
      return videoId;
    },

    moveToAuth() {
      this.$router.push({
        name: "Auth",
        params: { previousPage: "WorkshopsVideos" },
      });
    },
    moveToSubscriptions() {
      this.$router.push({ name: "TimeSubscriptions" });
    },
  },

  computed: {
    selectedVideo() {
      return this.$store.getters["videos/getSelectedVideo"];
    },
    timePackageItems() {
      let courses = this.$store.getters["timePackages/getTimePackageItems"];
      return courses;
    },
    hasActiveSubscription() {
      return this.$store.getters["auth/getHasActiveSubscription"];
    },
  },
};
</script>

<style scoped>
.card {
  width: 70vw;
  margin: 0 auto;
  margin-top: 5rem;
  margin-bottom: 40px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 10px;
  display: block;
  position: relative;
  padding-top: 4rem;
}
.text-under {
  padding: 20px 30px;
  border-top: 1px solid lightgray;
}
h3 {
  margin-bottom: 20px;
  font-size: 1.3rem;
  font-weight: 400;
}

.no-access .btn-gradient {
  width: 220px;
  padding: 8px 22px;
  border-radius: 30px;
  font-size: 1rem;
  margin: 15px;
}
.no-access {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  padding-top: 3rem;
  height: 100%;
  min-height: 300px;
  max-height: 600px;
  width: 80%;
  margin: 0 auto;
  background-image: url("../assets/background-video.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.switch {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
}
.switch div {
  padding: 1rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  font-size: 15px;
  cursor: pointer;
}
.switch .btn-gradient {
  height: 40px;
  display: flex;
  align-items: center;
  background: rgb(233, 230, 230);
  color: black;
}
.switch .btn-gradient:hover {
  transform: none;
}
.selectedSwitch {
  background: rgb(179, 106, 103) !important;
  background: linear-gradient(
    90deg,
    rgba(179, 106, 103, 1) 0%,
    rgba(223, 44, 38, 1) 100%
  ) !important;
  color: #fff !important;
}
@media screen and (max-width: 700px) {
  .card {
    width: 90vw;
  }
}
@media screen and (max-width: 550px) {
  .no-access h3 {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 450px) {
  .no-access .btn-gradient {
    font-size: 0.8;
    width: 160px;
  }
  .no-access h3 {
    font-size: 1rem;
  }
  .card {
    width: 100vw;
  }
  .text-under {
    font-size: 0.8rem;
    padding: 20px 5px;
  }
}
@media screen and (max-width: 375px) {
  .no-access h3 {
    font-size: 0.8rem;
  }
}
</style>
