<template>
  <div>
    <v-row class="card">
      <v-col
        v-if="selectedPackage24h && getCourseYoutubeId() !== '' && !isExpired"
      >
        <div>
          <video-player :videoId="getCourseYoutubeId()" />
        </div>
      </v-col>
      <v-col v-else>
        <h4 class="text-center">
          Nagranie nie istnieje lub jest już niedostępne z powodu upłynięcia 24
          godzin od momentu otrzymania go na adres e-mail
        </h4>
      </v-col>

      <div class="text-under">
        <h3 class="background">Nagranie z zajęć online</h3>
      </div>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import VideoPlayer from "../components/VideoPlayer.vue";

export default {
  name: "Videos24h",

  components: {
    VideoPlayer,
  },

  data: () => ({
    inverval: null,
    isExpired: false,
  }),

  async mounted() {
    if (this.package24hId) {
      await this.fetchPackage24h();
      this.checkPackage24hIsExpired();
    }

    this.interval = setInterval(async () => {
      await this.fetchPackage24h();
      this.checkPackage24hIsExpired();
    }, 60000);
  },

  methods: {
    async fetchPackage24h() {
      await this.$store.dispatch(
        "packages24h/fetchPackage24h",
        this.package24hId
      );
    },
    getCourseYoutubeId() {
      return this.selectedPackage24h.link;
    },

    checkPackage24hIsExpired(clearInterval) {
      const expirationTime = this.selectedPackage24h?.expirationTime;
      const isExpired = expirationTime
        ? moment(expirationTime).isBefore(moment())
        : true;
      if (!expirationTime || isExpired) {
        if (clearInterval) {
          clearInterval(this.interval);
        }
        this.isExpired = true;
      }
    },
  },

  computed: {
    package24hId() {
      return this.$route.params.id;
    },

    selectedPackage24h() {
      return this.$store.getters["packages24h/getSelectedPackage"];
    },
  },
};
</script>

<style scoped>
.card {
  width: 70vw;
  margin: 0 auto;
  margin-top: 5rem;
  margin-bottom: 40px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 10px;
  display: block;
  position: relative;
  padding-top: 4rem;
}
.text-under {
  padding: 20px 30px;
  border-top: 1px solid lightgray;
}

.text-under .background {
  padding: 5px 40px;
  font-size: 1.25rem;
  background: linear-gradient(270deg, #c4a2a2 0%, #b28a8a 90.06%);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  transition: all 0.3s ease;
}

h3 {
  margin-bottom: 20px;
  font-size: 1.3rem;
  font-weight: 400;
}

.no-access .background {
  padding: 8px 22px;
  width: 150px;
  border-radius: 30px;
  font-size: 1rem;
  margin: 15px;
  color: #fff;
  background: linear-gradient(270deg, #c4a2a2 0%, #b28a8a 90.06%);
  border-radius: 5px;
  text-align: center;
  transition: all 0.3s ease;
  font-weight: 500;
}
.no-access {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  padding-top: 3rem;
  height: 100%;
  min-height: 300px;
  max-height: 600px;
  width: 80%;
  margin: 0 auto;
  background-image: url("../assets/background-video.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.switch {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
}
.switch div {
  padding: 1rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  font-size: 15px;
  cursor: pointer;
}
.switch .btn-gradient {
  height: 40px;
  display: flex;
  align-items: center;
  background: rgb(233, 230, 230);
  color: black;
}
.switch .btn-gradient:hover {
  transform: none;
}
.selectedSwitch {
  background: rgb(179, 106, 103) !important;
  background: linear-gradient(270deg, #c4a2a2 0%, #b28a8a 90.06%) !important;
  color: #fff !important;
}
.viewsCounter {
  color: grey;
  font-size: 0.8rem;
  margin-right: 0.8rem;
}
.favorite-button {
  color: rgb(78, 74, 74);
  font-size: 0.9rem;
  margin-right: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: right;
  text-decoration: underline;
  cursor: pointer;
}
.favorite-icon {
  margin-right: 8px;
  font-size: 0.9rem;
  cursor: pointer;
}

/* @media (max-width: 1024px) {

} */

@media screen and (max-width: 700px) {
  .card {
    width: 90vw;
  }
}
@media screen and (max-width: 550px) {
  .no-access h3 {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 450px) {
  .no-access .btn-gradient {
    font-size: 0.8;
    width: 160px;
  }
  .no-access h3 {
    font-size: 1rem;
  }
  .card {
    width: 100vw;
  }
  .text-under {
    font-size: 0.8rem;
    padding: 20px 5px;
  }
}
@media screen and (max-width: 375px) {
  .no-access h3 {
    font-size: 0.8rem;
  }
}
</style>
