<template>
  <div class="box">
    <div class="lesson-container">
      <div class="lesson-image">
        <img v-if="lesson.imagePath" :src="getImageSrc()" alt="" />
        <img v-else src="../../../assets/dagajoga-logo.png" alt="" />
      </div>
      <div class="lesson-info">
        <p class="lesson-name">{{ lesson.name }}</p>
        <p class="lesson-description" v-html="lesson.description"></p>
      </div>
    </div>
    <div class="buttons">
      <rounded-color-button
        width="100%"
        style="margin: 0 auto"
        @clicked="moveToTheory"
        :label="'Teoria'"
      />
      <rounded-color-button
        width="100%"
        style="margin: 0 auto"
        @clicked="moveToPractice"
        :label="'Praktyka'"
      />
    </div>
  </div>
</template>

<script>
import { SERVER_URL } from "../../../axios";
import RoundedColorButton from "../../../components/buttons/RoundedColorButton.vue";
import router from "../../../router";
export default {
  components: { RoundedColorButton },
  name: "TheoryPracticeLesson",
  props: ["lesson"],
  methods: {
    getImageSrc() {
      if (this.lesson.imagePath) {
        const extension = this.lesson.imagePath.split(".")[1];
        const src = `${SERVER_URL}lessonsImages/${
          this.lesson.id
        }.${extension}?d=${new Date().getTime()}`;
        return src;
      } else {
        return "../../assets/dagajoga-logo.png";
      }
    },

    moveToTheory() {
      router.push({
        name: "TheoryPracticeWorkshopLessonTheories",
        params: {
          workshopId: this.lesson.theoryPracticeWorkshop.id,
          id: this.lesson.id,
        },
      });
    },
    moveToPractice() {
      router.push({
        name: "TheoryPracticeWorkshopLessonPractices",
        params: {
          workshopId: this.lesson.theoryPracticeWorkshop.id,
          id: this.lesson.id,
        },
      });
    },
  },
};
</script>

<style scoped>
.box {
  padding: 20px;
  border-radius: 20px;
  border: 1px solid grey;
  border: 1px solid rgb(0, 0, 0, 0.25);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-bottom: 1rem;
}
.lesson-container {
  display: flex;
  align-items: center;
  margin: 0 5px;
  @media (max-width: 425px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
.lesson-info {
  width: 100%;
}
.lesson-name {
  font-weight: 600;
  font-size: 24px;
  margin-left: 1rem;
}
.lesson-description {
  font-weight: 22px;
  display: flex;
  flex-direction: column;
  margin-left: 1rem;

  @media (max-width: 425px) {
    text-align: justify;
    margin-left: 10px;
  }
}
.lesson-image {
  width: 200px;
}
.lesson-image img {
  width: 130px;
  height: 130px;
  max-height: 200px;
  border-radius: 0.5rem;
  object-fit: cover;
}
.buttons {
  display: flex;
  gap: 2rem;
  justify-content: center;
  margin-top: 2rem;
}
</style>