<template>
  <v-row justify="center">
    <v-dialog
      max-width="700px"
      :value="isVisible"
      persistent
      @keydown.esc="closeModal"
    >
      <v-card>
        <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager">
          <v-card-title>
            <span class="headline"
              >{{ updated ? "Edytuj" : "Dodaj" }} podkategorię</span
            >
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    type="text"
                    rules="required"
                    v-model="name"
                    label="Nazwa"
                    color="indigo"
                    outlined
                    dense
                    maxLength="1024"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextAreaWithValidation
                    type="text"
                    rules="required"
                    v-model="description"
                    label="Opis"
                    color="indigo"
                    outlined
                    dense
                    maxLength="1024"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <v-file-input
                    v-model="file"
                    label="Zdjęcie kursu"
                  ></v-file-input>
                </v-col>
              </v-row>

              <v-row v-if="!level">
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <v-select
                    v-model="movieCategoryId"
                    :items="movieCategories"
                    item-value="value"
                    item-text="name"
                    outlined
                    color="indigo"
                    dense
                    label="Kategoria"
                  />
                </v-col>
              </v-row>
              <v-row v-if="level && level === 2">
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <v-select
                    v-model="movieSubCategoryId"
                    :items="movieSubcategories"
                    item-value="value"
                    item-text="name"
                    outlined
                    color="indigo"
                    dense
                    label="Podkategoria"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text v-on:click="closeModal">
              <font-awesome-icon icon="times" class="mr-1" />
              Anuluj
            </v-btn>
            <v-btn
              color="success"
              text
              :disabled="invalid"
              v-on:click="addSubCategory"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              Zapisz zmiany
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "../inputs/VTextFieldWithValidation";
import VTextAreaWithValidation from "../inputs/VTextAreaWithValidation.vue";

export default {
  name: "AddSubCategoryModal",
  props: ["isVisible", "movieSubCategoryData", "type", "level"],

  components: {
    ValidationObserver,
    VTextFieldWithValidation,
    VTextAreaWithValidation,
  },

  data: () => ({
    subCategoryId: null,
    input: "",
    discountError: false,
    updated: false,
    name: "",
    description: "",
    file: null,
    movieCategoryId: null,
    movieSubCategoryId: null,
  }),
  updated() {
    if (this.movieSubCategoryData) {
      const {
        id,
        name,
        description,
        imagePath,
        movieCategoryId,
        movie_sub_category,
      } = this.movieSubCategoryData;
      this.subCategoryId = id;
      this.updated = true;
      this.name = name;
      this.description = description;
      this.imagePath = imagePath;
      this.movieCategoryId = movieCategoryId;
      this.movieSubCategoryId = movie_sub_category.id;
    }
  },

  async created() {
    if (this.type) {
      if (this.type === "FOR-BEGINNERS") {
        await this.$store.dispatch(
          "movieSubCategories/fetchAllSubcategoriesL2",
          {
            type: "FOR-BEGINNERS",
            forBeginners: true,
          }
        );
        await this.$store.dispatch("movieSubCategories/fetchAll", {
          type: "FOR-BEGINNERS",
          forBeginners: true,
        });
      }

      if (this.type === "EXTENDED") {
        await this.$store.dispatch(
          "movieSubCategories/fetchAllSubcategoriesL2",
          {
            type: "EXTENDED",
            forExtendedSubscription: true,
          }
        );
        await this.$store.dispatch("movieSubCategories/fetchAll", {
          type: "EXTENDED",
          forExtendedSubscription: true,
        });
      }
    } else {
      await this.$store.dispatch("movieSubCategories/fetchAllSubcategoriesL2");
      await this.$store.dispatch("movieSubCategories/fetchAll");
    }
  },

  methods: {
    closeModal() {
      this.updated = false;
      this.name = "";
      this.description = "";
      this.file = null;
      this.$emit("closeModal");
    },
    async addSubCategory() {
      let object = {
        id: this.movieSubCategoryData ? this.movieSubCategoryData.id : null,
        name: this.name,
        description: this.description,
        file: this.file,
      };
      if (this.movieCategoryId) {
        object.movieCategoryId = this.movieCategoryId;
      }
      if (this.movieSubCategoryId) {
        object.movieSubCategoryId = this.movieSubCategoryId;
      }

      if (this.updated) {
        if (!this.level) {
          await this.$store
            .dispatch("movieSubCategories/updateMovieCategory", {
              data: object,
              type: this.type,
            })
            .then(() => {
              this.closeModal();
              this.$emit("updated");
            });
        } else {
          await this.$store
            .dispatch("movieSubCategories/updateMovieCategoryL2", {
              data: object,
              type: this.type,
            })
            .then(() => {
              this.closeModal();
              this.$emit("updated");
            });
        }
      } else {
        if (!this.level) {
          await this.$store
            .dispatch("movieSubCategories/addMovieSubCategory", {
              data: object,
              type: this.type,
            })
            .then(() => {
              this.closeModal();
              this.$emit("updated");
            });
        } else {
          await this.$store
            .dispatch("movieSubCategories/addMovieSubCategoryL2", {
              data: object,
              type: this.type,
            })
            .then(() => {
              this.closeModal();
              this.$emit("updated");
            });
        }
      }
    },
  },

  computed: {
    movieCategories() {
      let categories =
        this.$store.getters["movieCategories/getMovieCategories"];
      let items = categories.map((category) => {
        return {
          name: category.categoryName,
          value: category.id,
        };
      });

      return items;
    },

    movieSubcategories() {
      let categories =
        this.$store.getters["movieSubCategories/getMovieSubCategories"];
      let items = categories.map((category) => {
        return {
          name: category.name,
          value: category.id,
        };
      });

      return items;
    },
  },
};
</script>
<style type="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
