<template>
  <ValidationProvider :name="$attrs.label" :rules="rules" v-slot="{ errors }">
    <v-select
      v-model="innerValue"
      :error-messages="errors"
      v-bind="$attrs"
      outlined
      color="indigo"
      dense
      :items="items"
      item-text="name"
      item-value="value"
    >
    </v-select>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  props: {
    rules: {
      type: [Object, String],
      default: "",
    },
    items: {
      type: Array,
    },
    returnValue: {
      type: Boolean,
      default: false,
    },
    selectedValue: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    innerValue: "",
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      if (this.returnValue) {
        const itemValue = this.items.find((item) => item.name === newVal)?.id;
        this.$emit("input", itemValue);
      } else {
        this.$emit("input", newVal);
      }
    },
    // Handles external model changes.
    items(newItems) {
      if (this.selectedValue) {
        const foundItem = newItems.find(
          (item) => item.id === this.selectedValue
        );
        if (foundItem) {
          this.innerValue = foundItem.value ?? foundItem.name;
        }
      }
    },
  },
  updated() {
    if (this.selectedValue) {
      const foundItem = this.items.find(
        (item) => item.id === this.selectedValue
      );
      if (foundItem) {
        this.innerValue = foundItem.value ?? foundItem.name;
      }
    }
  },
  created() {
    if (this.selectedValue) {
      const foundItem = this.items.find(
        (item) => item.id === this.selectedValue
      );
      if (foundItem) {
        this.innerValue = foundItem.value ?? foundItem.name;
      }
    }
  },
};
</script>
