<template>
  <v-container class="container mt-10" fluid>
    <v-row justify="center" align="baseline">
      <v-col cols="12" sm="6" md="4" lg="4" xl="3">
        <v-card shaped elevation="5" rounded>
          <v-toolbar
            class="rounded-t"
            style="background-color: #cc6666 !important"
            tile
          >
            <v-toolbar-title>Dokonaj płatności</v-toolbar-title>
          </v-toolbar>
          <v-container v-if="packageData" class="py-10 px-10 text-center">
            <ValidationObserver
              v-if="packageData.active"
              ref="obs"
              v-slot="{ invalid, handleSubmit }"
              mode="eager"
            >
              <v-form @submit.prevent="handleSubmit(onSubmit)">
                <v-checkbox
                  outlined
                  dense
                  v-model="invoiceForCompany"
                  :label="`Faktura na firmę`"
                  class="mb-4"
                  :disabled="loading || transaction.show"
                ></v-checkbox>
                <VTextFieldWithValidation
                  rules="email|required"
                  v-model.trim="email"
                  label="E-mail"
                  color="grey"
                  outlined
                  dense
                  :disabled="loading || transaction.show"
                />
                <VTextFieldWithValidation
                  rules="email|required"
                  v-model.trim="email_confirmation"
                  label="Potwierdź e-mail"
                  color="grey"
                  outlined
                  dense
                  :disabled="loading || transaction.show"
                />
                <VTextFieldWithValidation
                  :rules="!invoiceForCompany ? 'required' : ''"
                  v-if="!invoiceForCompany"
                  v-model="name"
                  label="Imię i nazwisko"
                  color="grey"
                  outlined
                  dense
                  :disabled="loading || transaction.show"
                />
                <VTextFieldWithValidation
                  :rules="invoiceForCompany ? 'required' : ''"
                  v-if="invoiceForCompany"
                  label="Nazwa firmy"
                  color="grey"
                  outlined
                  dense
                  :disabled="loading || transaction.show"
                  v-model="companyName"
                />
                <VTextFieldWithValidation
                  v-if="invoiceForCompany"
                  :rules="invoiceForCompany ? 'required|numeric|NIP' : ''"
                  label="Numer NIP"
                  color="grey"
                  outlined
                  dense
                  :disabled="loading || transaction.show"
                  v-model="companyNIP"
                />
                <VTextAreaWithValidation
                  :rules="invoiceForCompany ? 'required' : ''"
                  :label="'Ad\u200Bres'"
                  color="grey"
                  outlined
                  dense
                  :disabled="loading || transaction.show"
                  v-model="address"
                  :height="80"
                  v-if="invoiceForCompany"
                />
                <VTextFieldWithValidation
                  rules="numeric"
                  v-model="phone"
                  label="Numer telefonu"
                  color="grey"
                  outlined
                  dense
                  :disabled="loading || transaction.show"
                />

                <VTextFieldWithValidation
                  v-model="discountCode"
                  label="Kod rabatowy"
                  color="grey"
                  outlined
                  dense
                  :disabled="loading || transaction.show"
                />

                <v-checkbox
                  v-model="newsletter"
                  :label="`Zapisz się do newslettera`"
                  :disabled="loading || transaction.show"
                ></v-checkbox>

                <h4 v-if="transaction.data.p24_amount">
                  Cena po zatwierdzeniu kodu rabatowego:
                  {{ (transaction.data.p24_amount / 100).toFixed(2) }} zł
                </h4>
                <h4 v-else>
                  Całkowity koszt:
                  {{ choosePriceToShow() }}
                  zł
                </h4>
                <v-btn
                  type="submit"
                  class="mt-5 mb-3 text--white white--text"
                  color="grey darken-2"
                  :disabled="invalid || transaction.show"
                  :loading="loading"
                >
                  Zatwierdź dane
                </v-btn>
                <br />
                <small>
                  <u
                    class="mt-5 reset"
                    color="grey darken-2"
                    v-show="!invalid || !transaction.show"
                    @click="reset"
                  >
                    Zresetuj formularz
                  </u>
                </small>

                <form :action="PAYMENT_URL" method="post" id="form">
                  <input
                    type="hidden"
                    name="p24_session_id"
                    :value="transaction.data.p24_session_id"
                  />
                  <input
                    type="hidden"
                    name="p24_merchant_id"
                    :value="transaction.data.p24_merchant_id"
                  />
                  <input
                    type="hidden"
                    name="p24_pos_id"
                    :value="transaction.data.p24_pos_id"
                  />
                  <input
                    type="hidden"
                    name="p24_amount"
                    :value="transaction.data.p24_amount"
                  />
                  <input
                    type="hidden"
                    name="p24_currency"
                    :value="transaction.data.p24_currency"
                  />
                  <input
                    type="hidden"
                    name="p24_description"
                    :value="transaction.data.p24_description"
                  />
                  <input
                    type="hidden"
                    name="p24_country"
                    :value="transaction.data.p24_country"
                  />
                  <input
                    type="hidden"
                    name="p24_email"
                    :value="transaction.data.p24_email"
                  />
                  <input
                    type="hidden"
                    name="p24_url_return"
                    :value="transaction.data.p24_url_return"
                  />
                  <input
                    type="hidden"
                    name="p24_url_status"
                    :value="transaction.data.p24_url_status"
                  />
                  <input
                    type="hidden"
                    name="p24_api_version"
                    :value="transaction.data.p24_api_version"
                  />
                  <input
                    type="hidden"
                    name="p24_sign"
                    :value="transaction.data.p24_sign"
                  />
                  <v-btn
                    v-if="transaction.show"
                    type="submit"
                    class="mt-5 submit-button"
                    :loading="loading"
                  >
                    Dokonaj płatności
                  </v-btn>
                </form>

                <br />
                <div style="text-align: justify">
                  <small>
                    Jeżeli chcesz skorzystać w płatności
                    <img
                      style="width: 90px"
                      src="../assets/paypal-logo-svg-vector.svg"
                      alt="
                      joga,
                      joga balanse,
                      praktyka,
                      anatomia jogi.
                      techniki oddechowe,
                      praktyka dla początkujących,
                      uważna praktyka,
                      subskrypcja DagaJoga
                      kurs jogi online,
                      szkoła online,
                      joga wzmacniająca
                      "
                      loading="lazy"
                    />, kliknij przycisk <strong>DOKONAJ PŁATNOŚCI</strong> a
                    następnie przejdź do zakładki <strong>INNE</strong>.
                  </small>
                  <br />
                  <br />
                  <small
                    >Składając zamówienie, wyrażasz zgodę na dostarczenie treści
                    cyfrowych lub świadczenie usługi przed upływem terminu do
                    odstąpienia od umowy zawartej na odległość. Potwierdzasz, że
                    tym samym tracisz prawo do odstąpienia od umowy.</small
                  >
                </div>
              </v-form>
            </ValidationObserver>
            <div v-else>
              <p>
                Zajęcia obecnie są nieaktywne. Spróbuj ponownie bliżej ich
                rozpoczęcia.
              </p>

              <a
                href="https://dagajoga.pl/index.php/zajecia-na-zywo/"
                class="ok-button btn-gradient"
                >OK</a
              >
            </div>
          </v-container>

          <v-container v-else class="mb-12">
            <h3 class="text-center" style="padding: 1rem">
              Przepraszamy, te warsztaty nie są już dostępne
            </h3>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VTextFieldWithValidation from "../components/inputs/VTextFieldWithValidation";
import VTextAreaWithValidation from "../components/inputs/VTextAreaWithValidation";
import { ValidationObserver } from "vee-validate";
import { PAYMENT_URL } from "../axios";
import axios from "../axios";
export default {
  metaInfo: {
    title: "Dagajoga kurs online - portal jogi online",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Dagajoga to szkoła internetowa o wszechstronnym systemie budowania praktyki własnej. Praktyka jogi połączona z nowoczesnymi metodami ćwiczeń funkcjonalnych, ćwiczeń wzmacniających.",
      },
    ],
  },
  name: "SequencingWorkshopPayment",
  components: {
    VTextFieldWithValidation,
    ValidationObserver,
    VTextAreaWithValidation,
  },
  data: () => ({
    email: "",
    email_confirmation: "",
    name: "",
    phone: "",
    loading: false,
    PAYMENT_URL,
    newsletter: true,
    companyName: "",
    companyNIP: "",
    invoiceForCompany: false,
    address: "",
    discountCode: "",
  }),
  computed: {
    transaction: function () {
      return this.$store.getters["payment/getTransInfo"];
    },
    packageData: function () {
      return this.$store.getters["sequencingWorkshops/getSequencingWorkshop"];
    },
    profile() {
      return this.$store.getters["auth/getProfile"];
    },
  },
  async created() {
    this.resetData();
    await this.$store.dispatch(
      "sequencingWorkshops/fetchSequencingWorkshop",
      this.$route.params.id
    );
    if (this.$route.query.extension) {
      const response = await axios.get(
        `sequencingWorkshops/${this.$route.params.id}/checkIsAllowedToExtend`,
        { params: { code: this.$route.query.extension } }
      );
      const isAllowed = response.data;
      if (!isAllowed) {
        this.$store.dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: "Nie masz możliwości przedłużenia warsztatów",
          },
          { root: true }
        );
        this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
        this.$router.push({
          name: "SequencingWorkshops",
          params: {
            id: this.$route.params.id,
          },
        });
      }
    }
  },
  watch: {
    invoiceForCompany() {
      if (!this.companyNIP) {
        this.companyNIP = " ";
      }
    },
  },
  async mounted() {
    await this.$store.dispatch("auth/fetchUserProfile");
    if (this.profile) {
      this.email = this.profile.email;
      this.email_confirmation = this.profile.email;
      if (this.profile.name) this.name = this.profile.name;
      if (this.profile.phone) this.phone = this.profile.phone;
      if (this.profile.company) this.companyName = this.profile.company;
      if (this.profile.nip) this.companyNIP = this.profile.nip;
      if (this.profile.address) this.address = this.profile.address;
    }
    const token = window.localStorage.getItem("userToken");
    if (!token) {
      this.$store.dispatch(
        "snackbar/setSnackbar",
        {
          color: "red",
          icon: "exclamation-triangle",
          message:
            "Zakup warsztatów z sekwencjonowania dostępny jest wyłącznie dla zalogowanych użytkowników",
        },
        { root: true }
      );
      this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
      this.$router.push({
        name: "Auth",
        params: {
          previousPath: `/warsztaty-z-sekwencjonowania/platnosc/${this.$route.params.id}`,
        },
      });
    }
  },
  destroyed() {
    this.reset();
  },
  methods: {
    choosePriceToShow() {
      if (this.$route.query.extension && this.packageData.priceWhenExtending) {
        return this.packageData.priceWhenExtending;
      }
      return this.packageData.price;
    },
    resetData() {
      this.$store.commit("payment/SET_INIT_VALUES");
      this.loading = false;
      this.email = "";
      this.email_confirmation = "";
      this.name = "";
      this.phone = "";
      this.newsletter = true;
      (this.address = ""), (this.companyNIP = ""), (this.companyName = "");
      this.discountCode = "";
    },
    reset() {
      this.resetData();
      if (this.$refs.obs) {
        this.$refs.obs.reset();
      }
    },
    onSubmit() {
      if (
        (this.companyName && this.companyNIP && this.invoiceForCompany) ||
        (this.name && !this.invoiceForCompany)
      ) {
        this.loading = true;
        let object = {
          email: this.email,
          name: this.name,
          phone: this.phone,
          sequencingWorkshopId: this.$route.params.id,
          newsletter: this.newsletter,
          confirmEmail: this.email_confirmation,
          companyName: this.companyName,
          nip: this.companyNIP,
          address: this.address,
          invoiceForCompany: this.invoiceForCompany,
          type:
            this.packageData && this.packageData.type
              ? this.packageData.type
              : "STANDARD",
          discountCode: this.discountCode.toLowerCase().trim(),
          isExtension: this.$route.query.extension ? true : false,
          extensionCode: this.$route.query.extension,
        };
        this.$store
          .dispatch("payment/createSequencingWorkshopOrder", object)
          .then(() => {
            this.loading = false;
            this.$refs.obs.reset();
          });
      } else {
        this.$store.dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message:
              'Musisz wypełnić przynajmniej jedno z następujących: "Imię i Nazwisko" bądź "Nazwa firmy" i "Numer NIP"',
          },
          { root: true }
        );
        this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
        if (this.$refs.obs) {
          this.$refs.obs.reset();
        }
      }
    },
  },
};
</script>

<style scoped>
.container {
}
.reset {
  text-transform: uppercase;
  cursor: pointer;
}
.submit-button {
  background-color: #cc6666 !important;
  font-size: 1.1rem;
}
.ok-button {
  width: 150px;
  height: 2rem;
  text-decoration: none;
  color: #fff;
  display: block;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ok-button:hover {
  transform: none;
}
</style>
