var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"width":"100%"}},[_c('management-nav'),_c('v-card-title',[_vm._v(" Zestawy "),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Szukaj","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('font-awesome-icon',{staticClass:"mr-1 mt-1",attrs:{"slot":"prepend-inner","icon":"search"},slot:"prepend-inner"})],1)],1),_c('v-data-table',{attrs:{"loading":_vm.items.length === 0,"loading-text":"Trwa pobieranie danych...","headers":_vm.headers,"items":_vm.items,"search":_vm.search},scopedSlots:_vm._u([{key:"item.packageItems",fn:function(ref){
var item = ref.item;
return _vm._l((item.packageItems),function(link){return _c('div',{key:link.id},[_vm._v(" "+_vm._s(link.link)+" ")])})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"green"},on:{"click":[function($event){return _vm.editPackage(item)},function($event){_vm.isModalVisible = true}]}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"edit"}})],1)]}}],null,true)},[_c('span',[_vm._v("Edytuj")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red"},on:{"click":function($event){(_vm.isDeleteModalVisible = true), (_vm.currentItem = item)}}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1)]}}],null,true)},[_c('span',[_vm._v("Usuń")])])]}},{key:"item.recordingField",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"orange"},on:{"click":[function($event){return _vm.showRecordingModal(item)},function($event){_vm.isRecordingModalVisible = true}]}},[_c('font-awesome-icon',{attrs:{"icon":"edit"}})],1)]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [[_c('v-checkbox',{on:{"click":function($event){return _vm.editPackageStatus(item)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]]}}],null,true)}),_c('AddPackageModal',{attrs:{"isVisible":_vm.isModalVisible,"packageData":_vm.packageData},on:{"closeModal":function($event){return _vm.closeModal()}}}),_c('RecordingModal',{attrs:{"isVisible":_vm.isRecordingModalVisible,"packageData":_vm.packageData},on:{"closeModal":function($event){return _vm.closeRecordingModal()}}}),_c('BeforeDeleteModal',{attrs:{"isVisible":_vm.isDeleteModalVisible},on:{"closeModal":function($event){return _vm.closeDeleteModal()},"confirmDeletion":function($event){return _vm.confirm(_vm.currentItem.id)}}}),_c('v-btn',{attrs:{"fab":"","dark":"","large":"","color":"green","fixed":"","left":"","bottom":""},on:{"click":function($event){_vm.isModalVisible = true}}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }