import axios from "../axios";

const theoryPracticeWorkshopsLessonsPractices = {
  namespaced: true,

  state: () => ({
    theoryPracticeWorkshopsLessonsPractices: [],
    updatedLesson: null,
  }),

  mutations: {
    SET_THEORY_PRACTICE_WORKSHOPS_LESSONS_PRACTICES(state, practices) {
      state.theoryPracticeWorkshopsLessonsPractices = practices;
    },
    SET_THEORY_PRACTICE_WORKSHOP_LESSON_PRACTICE(state, updatedPractice) {
      state.updatedLesson = updatedPractice;
    },
  },

  actions: {
    async fetchTheoryPracticeWorkshopsLessonsPractices({ commit }, data) {
      const params = {};
      if (data && data.theoryPracticeWorkshopsLessonId) {
        params.theoryPracticeWorkshopsLessonId =
          data.theoryPracticeWorkshopsLessonId;
      }
      try {
        const response = await axios.get(
          `theoryPracticeWorkshopsLessonsPractices`,
          { params }
        );
        commit(
          "SET_THEORY_PRACTICE_WORKSHOPS_LESSONS_PRACTICES",
          response.data
        );
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOPS_LESSONS_PRACTICES", null);
        console.log(err);
      }
    },

    async createTheoryPracticeWorkshopLessonPractice(
      { commit, dispatch },
      object
    ) {
      const { data } = object;
      const formData = new FormData();
      for (let key in data) {
        if (data[key] !== null && data[key] !== undefined) {
          formData.append(key, data[key]);
        }
      }

      try {
        const response = await axios.post(
          `theoryPracticeWorkshopsLessonsPractices`,
          formData
        );
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON_PRACTICE", response.data);
        await dispatch("fetchTheoryPracticeWorkshopsLessonsPractices");
        return response.status;
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON_PRACTICE", null);
        console.log(err);
      }
    },

    async editTheoryPracticeWorkshopLessonPractice(
      { commit, dispatch },
      object
    ) {
      const { data, id } = object;
      const formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }

      try {
        const response = await axios.put(
          `theoryPracticeWorkshopsLessonsPractices/${id}`,
          formData
        );
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON_PRACTICE", response.data);
        await dispatch("fetchTheoryPracticeWorkshopsLessonsPractices");
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON_PRACTICE", null);
        console.log(err);
      }
    },

    async addTheoryPracticeWorkshopLessonPractice({ commit, dispatch }, data) {
      try {
        await axios.delete(
          `theoryPracticeWorkshopsLessonsPractices/${data.id}`
        );
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON_PRACTICE", true);
        await dispatch("fetchTheoryPracticeWorkshopsLessonsPractices", {
          withNotActive: true,
        });
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON_PRACTICE", false);
        console.log(err);
      }
    },

    async deleteTheoryPracticeWorkshopLessonPractice(
      { commit, dispatch },
      data
    ) {
      try {
        await axios.delete(
          `theoryPracticeWorkshopsLessonsPractices/${data.id}`
        );
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON_PRACTICE", true);
        await dispatch("fetchTheoryPracticeWorkshopsLessonsPractices", {
          withNotActive: true,
        });
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON_PRACTICE", false);
        console.log(err);
      }
    },
  },

  getters: {
    getTheoryPracticeWorkshopsLessonsPractices: (state) =>
      state.theoryPracticeWorkshopsLessonsPractices,
  },
};
export default theoryPracticeWorkshopsLessonsPractices;
