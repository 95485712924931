<template>
  <li @click="fetchTimePackageItems" v-html="categoryItem.categoryName"></li>
</template>

<script>
export default {
  name: "Category",
  props: {
    categoryItem: Object,
    categoryValue: Object,
    selectecCategoryId: String,
    forBeginners: Boolean,
    isExtendedCategory: Boolean,
  },

  methods: {
    async fetchTimePackageItems() {
      if (this.isExtendedCategory) {
        await this.$store.dispatch(
          "timePackages/fetchAllTimePackageItemsExtended",
          {
            categoryId: this.selectecCategoryId
              ? this.selectecCategoryId
              : this.categoryItem.id,
          }
        );
        this.$emit("selected");
      } else {
        await this.$store.dispatch("timePackages/fetchAllTimePackageItems", {
          categoryId: this.selectecCategoryId
            ? this.selectecCategoryId
            : this.categoryItem.id,
          forBeginners: this.forBeginners,
        });
        this.$emit("selected");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
  list-style-type: none;
  width: 300px;
  display: flex;
  flex-direction: column;
  li {
    position: relative;
    cursor: pointer;
    text-align: left;
    display: list-item;
    padding: 10px;
    z-index: 0;
    border-bottom: 1px solid #d2423c;
    a {
      color: white;
      font-size: 17px;
      z-index: 5;
    }
  }
  li::before {
    content: "> ";
    font-size: 17px;
    color: #d2423c;
  }
}
</style>
