<template>
  <div>
    <div class="header-image-container">
      <h1>Zapisy na warsztaty</h1>
    </div>
    <v-container class="container mt-12" fluid>
      <v-row justify="center" align="baseline">
        <v-col cols="12" sm="6" md="4" lg="6" xl="6">
          <v-card shaped elevation="5" rounded>
            <v-toolbar class="rounded-t primary-background" tile>
              <v-toolbar-title>Zapisz się do warsztatów</v-toolbar-title>
            </v-toolbar>
            <v-container class="py-10 px-10 text-center">
              <ValidationObserver
                ref="obs"
                v-slot="{ invalid, handleSubmit }"
                mode="eager"
              >
                <v-form @submit.prevent="handleSubmit(showConfirmModal)">
                  <VTextFieldWithValidation
                    rules="email|required"
                    v-model="email"
                    label="E-mail"
                    color="grey"
                    outlined
                    dense
                    :disabled="loading"
                  />
                  <v-btn
                    type="submit"
                    class="mt-5 mb-3 text--white white--text"
                    color="grey darken-2"
                    :loading="loading"
                    :disabled="invalid"
                  >
                    Zapisz się
                  </v-btn>
                  <br />
                </v-form>
              </ValidationObserver>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <confirm-signup-for-sequencing-workshop-modal
      :isVisible="isConfirmModalVisible"
      @closeModal="closeConfirmModal()"
      @confirm="onSubmit"
    />
  </div>
</template>

<script>
import VTextFieldWithValidation from "../../components/inputs/VTextFieldWithValidation";
import { ValidationObserver } from "vee-validate";
import axios from "../../axios";
import router from "../../router";
import ConfirmSignupForSequencingWorkshopModal from "../../components/modals/ConfirmSignupForSequencingWorkshopModal.vue";
export default {
  metaInfo: {
    title:
      "Dagajoga zapisy do wasztatów teoria i praktyka - portal jogi online",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Dagajoga to szkoła internetowa o wszechstronnym systemie budowania praktyki własnej. Praktyka jogi połączona z nowoczesnymi metodami ćwiczeń funkcjonalnych, ćwiczeń wzmacniających.",
      },
    ],
  },
  name: "TheoryPracticeWorkshopSignup",
  components: {
    VTextFieldWithValidation,
    ValidationObserver,
    ConfirmSignupForSequencingWorkshopModal,
  },
  data: () => ({
    email: "",
    loading: false,
    isConfirmModalVisible: false,
  }),
  computed: {
    profile() {
      return this.$store.getters["auth/getProfile"];
    },
  },
  async created() {
    await this.$store.dispatch("auth/fetchUserProfile");
    if (this.profile) {
      this.email = this.profile.email;
      this.isConfirmModalVisible = true;
    }
  },
  destroyed() {},
  methods: {
    showConfirmModal() {
      this.isConfirmModalVisible = true;
    },
    closeConfirmModal() {
      this.isConfirmModalVisible = false;
    },
    async onSubmit() {
      this.loading = true;
      try {
        const response = await this.addToWorkshop();
        if (response.status === 200) {
          if (this.$router.currentRoute.query.id) {
            router.push({
              name: "TheoryPracticeWorkshopPayment",
              params: { id: this.$router.currentRoute.query.id },
            });
          } else {
            router.push({ name: "TheoryPracticeWorkshopSignupSuccess" });
          }
        }
      } catch (error) {
        let errorMessage = "Wystąpił błąd. Spróbuj ponownie";
        if (
          error?.response?.data?.message ===
          "The maximum number of people has already signed up for the workshop"
        ) {
          errorMessage =
            "Do warsztatów zapisała się już maksymalna liczba uczestników";
        }
        this.$store.dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorMessage,
          },
          { root: true }
        );
        this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
        this.loading = false;
      }
    },

    async addToWorkshop() {
      const workshopId = this.$route.params.id;
      return await axios.post(`theoryPracticeWorkshops/${workshopId}/members`, {
        email: this.email,
      });
    },
  },
};
</script>

<style scoped>
.container {
  margin-bottom: 2rem;
}
.reset {
  text-transform: uppercase;
  cursor: pointer;
}
.submit-button {
  background-color: #cc6666 !important;
  font-size: 1.1rem;
}
</style>
