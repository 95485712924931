<template>
  <div v-if="selectedSubCategory !== null" class="container">
    <return-btn
      :path="
        isExtendedSubscription
          ? 'subskrypcja-rozszerzona'
          : selectedSubCategory &&
            selectedSubCategory.movieSubcategory &&
            selectedSubCategory.movieSubcategory.forBeginners
          ? 'subskrypcja-podstawy'
          : `subskrypcja`
      "
      blackColor
    ></return-btn>
    <div class="content">
      <img id="subcategory-view-image" loading="lazy" />
      <div class="description">
        <h1>{{ selectedSubCategory.movieSubcategory.name }}</h1>
        <p
          class="description-text"
          v-html="selectedSubCategory.movieSubcategory.description"
        ></p>
      </div>
    </div>
    <div class="courses">
      <Course
        v-for="timePackageItem in subcategoryItems"
        :timePackageItem="timePackageItem"
        :key="timePackageItem.id"
        :id="`course_${timePackageItem.id}`"
        :selectedSubCategoryId="selectedSubCategory.movieSubcategory.id"
        :numberOfTimePackageItems="selectedSubCategory.numberOfTimePackageItems"
        :isExtendedSubscription="isExtendedSubscription"
      >
      </Course>
    </div>
  </div>
</template>

<script>
// import moment from "moment";
// import Category from "../components/Category.vue";
// import Package from "../components/Package.vue";
// import Telegram from "../components/Telegram.vue";
import router from "../router";
// import MovieSubcategory from "../components/MovieSubcategory.vue";
import Course from "../components/Course.vue";
import ReturnBtn from "../components/ReturnBtn.vue";
import { SERVER_URL } from "../axios";
export default {
  metaInfo: {
    title: "Dagajoga subskrypcja - portal jogi online",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Dagajoga to szkoła internetowa o wszechstronnym systemie budowania praktyki własnej. Praktyka jogi połączona z nowoczesnymi metodami ćwiczeń funkcjonalnych, ćwiczeń wzmacniających.",
      },
    ],
  },
  name: "SubcategoryView",
  data: () => ({ fetched: true, img: "", isExtendedSubscription: false }),
  components: { Course, ReturnBtn },
  props: {
    path: String,
  },

  async mounted() {
    const id = router.currentRoute.params.id;
    if (this.$route.query.rozszerzona && this.$route.query.rozszerzona == "1") {
      this.isExtendedSubscription = true;
    }
    if (
      this.selectedSubCategory &&
      this.selectedSubCategory.movieSubcategory &&
      this.selectedSubCategory.movieSubcategory.imagePath
    ) {
      this.loadImage(this.selectedSubCategory.movieSubcategory);
    } else {
      this.img = await this.$store.dispatch(
        "movieSubCategories/getSubCategoryImageSrc",
        { subCategoryId: router.currentRoute.params.id }
      );
    }
    if (!this.isExtendedSubscription) {
      await this.$store.dispatch(
        "timePackages/fetchAllTimePackageItemsBySubCategoryId",
        id
      );
    } else {
      await this.$store.dispatch(
        "timePackages/fetchAllTimePackageItemsExtendedBySubCategoryId",
        id
      );
    }
    if (this.selectedSubCategory === null) {
      await this.$store.dispatch("movieCategories/fetchAllForUser");
      this.$store.getters["movieCategories/getMovieCategories"].forEach(
        (course) => {
          course.movie_sub_categories.forEach((subcourse) => {
            if (subcourse.id === id) {
              this.$store.dispatch(
                "movieSubCategories/setSelectedMovieSubCategory",
                {
                  movieSubcategory: subcourse,
                }
              );
            }
          });
        }
      );
    }
  },

  computed: {
    token() {
      return this.$store.getters["auth/getToken"];
    },
    subcategoryItems() {
      return this.$store.getters["timePackages/getSubCategoryTimePackageItems"];
    },
    selectedSubCategory() {
      return this.$store.getters[
        "movieSubCategories/getSelectedMovieSubCategory"
      ];
    },
  },
  methods: {
    async loadImage(subCategory) {
      const element = document.getElementById("subcategory-view-image");
      if (element) {
        if (subCategory.imagePath) {
          const extension = subCategory.imagePath.split(".")[1];
          element.src = `${SERVER_URL}${
            subCategory.id
          }.${extension}?${new Date().getTime()}`;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  min-height: 100vh;
}
.content {
  color: #000;
  margin: 5rem 0;
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}
.content {
  h1 {
    font-size: 2rem;
    margin: 0 1rem;
  }
  p {
    font-size: 1rem;
    margin: 0 1rem;
  }
}
#subcategory-view-image {
  width: 60vh;
  height: 50vh;
}
.courses {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 4rem;
  justify-content: center;
}
.content img,
.content div {
  width: 50%;
}

@media (max-width: 600px) {
  .content img,
  .content div {
    width: 100%;
  }
}
</style>
