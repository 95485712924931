<template>
  <div class="container">
    <div class="header-image">
      <img
        src="../assets/dagajoga-logo.png"
        alt="
        kręgosłup,
        joga,
        joga stanie na rękach,
        kurs jogi online,
        subskrypcja DagaJoga
        pranajama oddech,
        joga balanse,
        Nauli,
        praktyka,
        ćwiczenia online,
        joga wzmacniająca,
        szkoła online
        "
        loading="lazy"
        style="object-fit: contain"
      />
    </div>
    <ReturnBtn
      blackColor
      :path="path ? path : `subskrypcja-podstawy`"
    ></ReturnBtn>
    <div class="packages">
      <Package
        :pack="timePackage"
        :firstElement="index === 0"
        :lastElement="index === timePackages.length - 1"
        v-for="(timePackage, index) in timePackages"
        :key="timePackage.id"
        :timePackagesItemsNames="timePackagesItemsNames"
        :basicPrice="timePackages[0] ? timePackages[0].price : null"
      />
    </div>
    <DescriptionCard title="Co w ramach subskrypcji?">
      <template slot="content">
        <p class="text-center description">
          Wykupując subskrypcję, otrzymujesz pełen dostęp do wszystkich grup
          praktyk:
          <span class="font-weight-medium"
            >praktyki docelowe, uzupełniające, praktyki dla początkujących,
            analiza asan, oddech i medytacja.</span
          >
        </p>
        <p class="text-center description">
          <span class="font-weight-medium">Każdego miesiąca</span> są dodawane
          dwie <span class="font-weight-medium">nowe praktyki</span>, jedna na
          początku miesiąca, druga w połowie. Praktyki są pełnowartościowe, z
          jasnym przekazem. Sekwencje zawierają również modyfikacje
          trudniejszych asan, które możesz dostosować do swojego poziomu i
          samopoczucia.
        </p>
        <p class="text-center description">
          Praktyki są ułożone z dbałością o szczegóły,
          <span class="font-weight-medium"
            >oparte o wiedzę anatomiczną, równoważą układ współczuły oraz
            przywspółczulny</span
          >, dzięki czemu dają sto procent rezultatów na poziomie ciała oraz
          umysłu.
        </p>
      </template>
    </DescriptionCard>
  </div>
</template>

<script>
import ReturnBtn from "../components/ReturnBtn.vue";
import Package from "../components/Package.vue";
import DescriptionCard from "../components/DescriptionCard.vue";
export default {
  metaInfo: {
    title: "Dagajoga subskrypcja - portal jogi online",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Dagajoga to szkoła internetowa o wszechstronnym systemie budowania praktyki własnej. Praktyka jogi połączona z nowoczesnymi metodami ćwiczeń funkcjonalnych, ćwiczeń wzmacniających.",
      },
    ],
  },
  name: "TimeSubscriptionsForBeginners",
  components: {
    ReturnBtn,
    Package,
    DescriptionCard,
  },
  data: () => ({}),
  props: {
    path: String,
  },
  methods: {},
  async created() {
    await this.$store.dispatch(`auth/checkUserActiveSubscriptionsForBeginners`);
    if (this.hasActiveSubscription) {
      await this.$store.dispatch(
        `timePackages/fetchActiveSubscriptionsForBeginners`
      );
    } else {
      await this.$store.dispatch(`timePackages/setActiveSubscriptionsToNull`);
    }
    this.$store.dispatch("timePackages/fetchAllTimePackages", {
      type: "FOR-BEGINNERS",
    });
    this.$store.dispatch(
      "timePackages/fetchAllTimePackagesItemsForBeginnersNames"
    );
  },
  computed: {
    timePackages() {
      return this.$store.getters["timePackages/getAllTimePackages"];
    },
    timePackagesItemsNames() {
      return this.$store.getters[
        "timePackages/getAllTimePackagesItemsForBeginnersNames"
      ];
    },
  },
};
</script>

<style scoped>
.description {
  font-size: 20px;
  padding: 12px;
}
.container {
  height: 100%;
  min-width: 100%;
  margin: 0;
  padding: 0;
  background: #fff;
  z-index: 100;
}
.header-image {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 400px;
}
.header-image img {
  width: inherit;
  /* height: 100%; */
  max-width: 640px;
  padding-top: 3rem;
}

.packages {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  margin-top: 2rem;
  margin-left: 3rem;
  margin-right: 3rem;
  overflow: hidden;
}

@media screen and (max-width: 1200px) {
  .packages {
    top: 300px;
  }
}
@media screen and (max-width: 1050px) {
  .packages {
    top: 250px;
  }
}
@media screen and (max-width: 1000px) {
  .packages {
    flex-direction: column;
    top: 200px;
  }
}
@media screen and (max-width: 500px) {
  .header img {
    height: 300px;
    width: fit-content;
    object-fit: cover;
  }
}
</style>
