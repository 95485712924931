<template>
  <div class="payment-container">
    <div class="payment-left-side w-50">
      <PurchaseSumUp
        :selectedPackage="selectedTimePackage"
        :timePackagesItemsNames="timePackagesItemsNames"
        :activeSubscription="
          activeSubscriptions &&
          activeSubscriptions[0] &&
          activeSubscriptions[0].expiration_time
            ? activeSubscriptions[0]
            : null
        "
        :activeSubscriptionExtended="
          activeSubscriptionsExtended &&
          activeSubscriptionsExtended[0] &&
          activeSubscriptionsExtended[0].expiration_time
            ? activeSubscriptionsExtended[0]
            : null
        "
        :isExtendedSubscription="isExtendedSubscription"
      >
      </PurchaseSumUp>
    </div>
    <div class="payment-right-side w-50">
      <PaymentForm
        :key="profile ? profile.id : 0"
        :profile="profile"
        :selectedPackage="selectedTimePackage"
        :isExtendedSubscription="isExtendedSubscription"
      >
      </PaymentForm>
    </div>
  </div>
</template>

<script>
import PaymentForm from "../components/forms/PaymentForm.vue";
import PurchaseSumUp from "../components/PurchaseSumUp.vue";
export default {
  metaInfo: {
    title: "Dagajoga subskrypcja - portal jogi online",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Dagajoga to szkoła internetowa o wszechstronnym systemie budowania praktyki własnej. Praktyka jogi połączona z nowoczesnymi metodami ćwiczeń funkcjonalnych, ćwiczeń wzmacniających.",
      },
    ],
  },
  name: "SubscriptionPayment",

  components: {
    PaymentForm,
    PurchaseSumUp,
  },

  data: () => ({
    isExtendedSubscription: false,
  }),
  async mounted() {
    if (this.$route.query.rozszerzona && this.$route.query.rozszerzona == "1") {
      this.isExtendedSubscription = true;
    }
    await this.$store.dispatch("auth/fetchUserProfile");
    await this.$store.dispatch(`auth/checkUserActiveSubscriptions`, {
      extendedSubscription: this.isExtendedSubscription,
    });
    await this.$store.dispatch(`timePackages/fetchActiveSubscriptionsExtended`);
    if (this.hasActiveSubscription) {
      await this.$store.dispatch(`timePackages/fetchActiveSubscriptions`);
    } else {
      if (this.isExtendedSubscription) {
        this.$store.dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message:
              "Zakup subskrypcji rozszerzonej dostępny jest wyłącznie w przypadku, kiedy posiadasz wykupiony dostęp do subskrypcji ogólnej",
          },
          { root: true }
        );
        this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
        this.$router.push({
          name: "TimeSubscriptions",
          params: { previousPage: "SubscriptionPayment" },
        });
      } else {
        const token = window.localStorage.getItem("userToken");
        if (!token) {
          this.$store.dispatch(
            "snackbar/setSnackbar",
            {
              color: "red",
              icon: "exclamation-triangle",
              message:
                "Zakup subskrypcji dostępny jest wyłącznie dla zalogowanych użytkowników",
            },
            { root: true }
          );
          this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
          this.$router.push({
            name: "Auth",
            params: { previousPage: "SubscriptionPayment" },
          });
        }
      }
    }
  },

  computed: {
    selectedTimePackage() {
      return this.$store.getters["timePackages/getSelectedTimePackage"];
    },
    timePackagesItemsNames() {
      if (
        this.selectedTimePackage &&
        this.selectedTimePackage.type === "FOR-BEGINNERS"
      ) {
        return this.$store.getters[
          "timePackages/getAllTimePackagesItemsForBeginnersNames"
        ];
      } else {
        return this.$store.getters["timePackages/getAllTimePackagesItemsNames"];
      }
    },
    activeSubscriptions() {
      return this.$store.getters["timePackages/getActiveSubscriptions"];
    },
    activeSubscriptionsExtended() {
      return this.$store.getters["timePackages/getActiveSubscriptionsExtended"];
    },
    hasActiveSubscription() {
      return this.$store.getters["auth/getHasActiveSubscription"];
    },
    profile() {
      return this.$store.getters["auth/getProfile"];
    },
  },
};
</script>

<style scoped></style>
