import axios from "../axios";
const timePackagesLinks = {
  namespaced: true,

  state: () => ({
    linksForBeginners: [],
    linksForGeneral: [],
    linksUpdated: false,
  }),

  mutations: {
    SET_LINKS_FOR_BEGINNERS(state, links) {
      state.linksForBeginners = links;
    },
    SET_LINKS_FOR_GENERAL(state, links) {
      state.linksForGeneral = links;
    },
    SET_LINKS_UPDATE(state, status) {
      state.linksUpdated = status;
    },
  },

  actions: {
    async fetchTimePackagesLinks({ commit }) {
      try {
        const url = `timePackagesLinks`;
        const response = await axios.get(url);
        const linksForBeginners = [];
        const linksForGeneral = [];
        response.data.forEach((link) => {
          if (link.type === "FOR-BEGINNERS") {
            linksForBeginners.push(link);
          }
          if (link.type === "GENERAL") {
            linksForGeneral.push(link);
          }
        });
        commit("SET_LINKS_FOR_BEGINNERS", linksForBeginners);
        commit("SET_LINKS_FOR_GENERAL", linksForGeneral);
      } catch (error) {
        console.log(error);
        commit("SET_LINKS_FOR_BEGINNERS", []);
        commit("SET_LINKS_FOR_GENERAL", []);
      }
    },
    async updateTimePackageLink({ commit }, data) {
      try {
        const { id, link, name } = data;
        const url = `timePackagesLinks/${id}`;
        await axios.put(url, { link, name });
        commit("SET_LINKS_UPDATE", true);
      } catch (error) {
        commit("SET_LINKS_UPDATE", false);
        console.log(error);
      }
    },
  },

  getters: {
    getTimePackagesLinksForBeginner: (state) => state.linksForBeginners,
    getTimePackagesLinksForGeneral: (state) => state.linksForGeneral,
  },
};

export default timePackagesLinks;
