<template>
  <ValidationProvider
    :name="$attrs.label"
    :rules="rules"
    v-slot="{ errors }"
    :vid="vid"
  >
    <v-textarea
      :type="type"
      v-model="innerValue"
      :error-messages="errors"
      v-bind="$attrs"
      v-on="$listeners"
      outlined
      color="lightgrey"
      dense
      :height="height"
    ></v-textarea>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import * as Validator from "vee-validate";

Validator.extend("RegexEmail", {
  message: "Błędny format adresu email. pl|com|eu|uk|net|de|ru|br|fr|it|in",
  validate: (value) => {
    var patt = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+(pl|eu|com|uk|net|de|ru|br|fr|it|in)))$/
    );
    return patt.test(value);
  },
});

export default {
  components: {
    ValidationProvider
  },
  props: {
    rules: {
      type: [Object, String],
      default: "",
    },
    height: {
      type: Number,
      default: null
    },
    // must be included in props
    value: {
      type: null,
    },
    vid: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
  },
  data: () => ({
    innerValue: "",
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      if (
        this.type === "number" &&
        this.innerValue.toString().match(/^[0-9]+,[0-9]+$/)
      ) {
        this.innerValue = this.innerValue.toString().replace(",", ".");
      }

      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
};
</script>
