<template>
  <div>
    <div class="content-container">
      <p class="title">
        Zaproś znajomych i zyskaj 10 zł rabatu na subskrypcję platformy
        DagaJoga!
      </p>
      <div class="open-modal-label">
        <p class="" @click="openModal">Dowiedz się więcej!</p>
      </div>
      <div v-if="userRefLinksAmount" class="amount">
        <p>
          Twój całkowity rabat: <b>{{ userRefLinksAmount.toFixed(2) }} zł</b>
        </p>
      </div>
      <ref-links-info-modal
        :label="'Jak to działa?'"
        @closeModal="closeModal"
        :isVisible="infoModal"
        :userId="userId"
      />
    </div>
  </div>
</template>


<script>
import RefLinksInfoModal from "./modals/RefLinksInfoModal.vue";
export default {
  props: ["userId", "userRefLinksAmount"],
  components: { RefLinksInfoModal },
  name: "RefLinksInfo",
  data() {
    return {
      infoModal: false,
    };
  },
  setup() {
    return {};
  },
  methods: {
    closeModal() {
      this.infoModal = false;
    },
    openModal() {
      this.infoModal = true;
    },
  },
};
</script>

<style scoped>
.content-container {
  text-align: center;
  width: fit-content;
  margin: 1rem 0 4rem 0;
}
.title {
  font-size: 20px;
  font-weight: bold;
}
.open-modal-label {
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  text-decoration: underline;
  margin-top: 10px;
  width: fit-content;
  margin: 0 auto;
}
.amount {
  font-size: 19px;
}
</style>