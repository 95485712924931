<template>
  <div class="box">
    <font-awesome-icon icon="paperclip" />
    <span class="link" @click="openAttachment">{{ attachment.name }}</span>
    <font-awesome-icon
      class="download-icon ml-2"
      icon="download"
      @click="downloadAttachment"
    />
  </div>
</template>

<script>
import { SERVER_URL } from "../../../axios";
import axios from "../../../axios";
export default {
  name: "TheoryPracticeLessonAttachment",
  props: ["attachment"],
  methods: {
    openAttachment() {
      const url = `${SERVER_URL}lessonsAttachments/${this.attachment.theoryPracticeWorkshopsLessonId}_${this.attachment.filenameWithExtension}`;
      window.open(url, "_blank");
    },
    async downloadAttachment() {
      const url = `/theoryPracticeWorkshopsLessons/${this.attachment.theoryPracticeWorkshopsLessonId}/attachments/${this.attachment.id}`;
      const response = await axios.get(url, { responseType: "arraybuffer" });

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `${this.attachment.filenameWithExtension}`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(downloadUrl);
    },
  },
};
</script>

<style scoped>
.box {
  display: flex;
  align-items: center;
  height: 20px;
  gap: 10px;
  margin-bottom: 10px;
}
.link,
.download-icon {
  cursor: pointer;
}
</style>