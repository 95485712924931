<template>
  <div class="container-div" v-if="selectedPackage">
    <h1>Podsumowanie zakupu</h1>

    <table>
      <thead>
        <tr class="main-row">
          <th style="min-width: 70%; text-align: left">
            Informacje o produkcie
          </th>
          <th style="min-width: 15%; text-align: left">Ilość</th>
          <th style="min-width: 15%; text-align: right">Cena</th>
        </tr>
      </thead>
      <tbody>
        <tr class="content">
          <td class="prod-info">
            <div
              class="background"
              v-if="selectedPackage && selectedPackage.name"
            >
              <span class="uppercase">{{ selectedPackage.name }} </span><br />
              <!-- <span class="btn-under"> za {{correctTyping(selectedPackage.numberOfDays)}} </span> -->
            </div>
            <div
              class="details"
              :style="
                !selectedPackage || !selectedPackage.name
                  ? 'padding-left: 0px;'
                  : ''
              "
            >
              <h4
                v-if="
                  selectedPackage &&
                  selectedPackage.name &&
                  (!packageItem || routeName !== 'Payment')
                "
              >
                <span class="coloring-grey">PRODUKT:</span>
                {{ selectedPackage.numberOfDays ? "Subskrypcja" : "" }}
                {{ selectedPackage.name }}
              </h4>
              <h4 v-if="packageItem && routeName === 'Payment'">
                <span class="coloring-grey">PRODUKT:</span>
                {{
                  isPackage24h
                    ? "Nagranie z zajęć ZOOM "
                    : "Zajęcia na żywo ZOOM"
                }}
              </h4>

              <h4 v-if="!isCoursesTicket">
                <span v-if="selectedPackage.numberOfDays" class="coloring-grey"
                  >WAŻNA DO:</span
                >
                <span v-else class="coloring-grey">DATA:</span>
                {{ getExpirationDate(selectedPackage.numberOfDays) }}
              </h4>
              <h4 v-if="isCoursesTicket">
                {{
                  selectedPackage.type === "STANDARD"
                    ? "Karnet"
                    : "Karnet dla początkujących"
                }}
                - {{ selectedPackage.description }}
              </h4>
              <h4 v-if="isPackage24h" style="color: #c3a1a1">
                Nagranie otrzymasz po zakończeniu zajęć, nagranie ważne 24h
              </h4>
            </div>
          </td>

          <td style="min-width: 15%">1</td>
          <td style="min-width: 15%; text-align: right">
            <span class="coloring" v-if="selectedPackage.priceBeforeDiscount">
              <span
                v-if="selectedPackage.priceBeforeDiscount.split('.')[1] == '00'"
                >{{
                  selectedPackage.priceBeforeDiscount.substring(
                    0,
                    selectedPackage.priceBeforeDiscount.length - 3
                  )
                }}</span
              >
              <span v-else>{{ selectedPackage.priceBeforeDiscount }}</span>
              zł</span
            >

            <span class="coloring" v-else>
              <span v-if="selectedPackage.price.split('.')[1] == '00'">{{
                selectedPackage.price.substring(
                  0,
                  selectedPackage.price.length - 3
                )
              }}</span>
              <span v-else>{{ selectedPackage.price }}</span> zł</span
            >
          </td>
        </tr>
      </tbody>

      <div
        class="includings"
        v-if="!isCoursesTicket && (!packageItem || routeName !== 'Payment')"
      >
        <h5>PAKIET ZAWIERA:</h5>
        <ul>
          <li
            :key="`${item}_${index}`"
            v-for="(item, index) in timePackagesItemsNames"
          >
            <span class="size-up">{{ item }} </span>
          </li>
        </ul>
      </div>
    </table>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "PurchaseSumUp",
  props: {
    isCoursesTicket: Boolean,
    selectedPackage: Object,
    timePackagesItemsNames: Array,
    activeSubscription: Object,
    activeSubscriptionExtended: Object,
    isExtendedSubscription: Boolean,
  },
  correctTyping(numberOfDays) {
    if (numberOfDays / 31 === 1) {
      return numberOfDays / 31 + " miesiąc";
    } else if (numberOfDays / 31 > 1 && numberOfDays / 31 < 5) {
      return numberOfDays / 31 + " miesiące";
    } else {
      return numberOfDays / 31 + " miesięcy";
    }
  },

  methods: {
    getExpirationDate(numberOfDays) {
      if (this.packageItem && this.routeName === "Payment") {
        return this.packageItem.date;
      } else {
        const activeSubscription = this.activeSubscription;
        const activeSubscriptionExtended = this.activeSubscriptionExtended;
        if (!this.isExtendedSubscription && activeSubscription) {
          const subscriptionDate = moment(activeSubscription.expiration_time);
          const newExpirationDate = moment().add(numberOfDays, "days");
          newExpirationDate.add(subscriptionDate.diff(moment()));
          return newExpirationDate.format("DD.MM.YYYY");
        }
        if (this.isExtendedSubscription && activeSubscriptionExtended) {
          const subscriptionDate = moment(
            activeSubscriptionExtended.expiration_time
          );
          const newExpirationDate = moment().add(numberOfDays, "days");
          newExpirationDate.add(subscriptionDate.diff(moment()));
          return newExpirationDate.format("DD.MM.YYYY");
        }
        return moment().add(numberOfDays, "days").format("DD.MM.YYYY");
      }
    },
    correctTyping(numberOfDays) {
      if (numberOfDays / 31 === 1) {
        return numberOfDays / 31 + " miesiąc";
      } else if (numberOfDays / 31 > 1 && numberOfDays / 31 < 5) {
        return numberOfDays / 31 + " miesiące";
      } else {
        return numberOfDays / 31 + " miesięcy";
      }
    },
  },

  computed: {
    timePackageItems() {
      return this.$store.getters["timePackages/getTimePackageItems"];
    },
    packageItem() {
      return this.$store.getters["packages/getPackage"];
    },
    routeName() {
      return this.$route.name;
    },
    isPackage24h() {
      return this.$route.query.recording;
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Poppins:wght@300;400;600;700&family=Raleway:wght@400;500&family=Roboto:wght@300;400;500;700&display=swap");
* {
  font-size: "Roboto", sans-serif;
}

.container-div {
  display: flex;
  flex-direction: column;
  padding: 40px 70px;
}
/* HEADER */
h1 {
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 10px;
}
/* CATEGORY NAMES */
h5 {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.8rem;
  color: #666666;
}
/* FIRST SECTION POSITIONING */
.main-row,
.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.ilosc {
  padding-right: 50px;
  justify-content: center;
}
.uppercase {
  text-transform: uppercase;
}
/* BORDERS */
.main-row {
  border-bottom: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
}

.prod-info {
  display: flex;
  text-align: left;
  min-width: 70%;
}
/* BTN STYLING */
.background {
  padding: 40px 25px;
  font-weight: 400;
  line-height: 1rem;
  font-size: 1.2rem;
  background: linear-gradient(270deg, #c4a2a2 0%, #b28a8a 90.06%);
  border-radius: 5px;
  color: #fff;
  text-align: center;
  transition: all 0.3s ease;
  font-weight: 500;
}

.background:hover {
  transform: translateY(-5px);
}
.btn-under {
  font-size: 0.7rem;
  font-weight: 400;
}
/* DETAILS STYLING */
.details {
  padding: 10px 20px;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.details h4 {
  margin-bottom: 10px;
  font-weight: 500;
}

/* BORDERS */
.content {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 20px;
}
/* LIST ITEM STYLING */
/* RESIZING THE DOTS */
ul li {
  font-size: 0.4rem;
  padding: 5px 0;
  text-align: left;
}
.size-up {
  font-size: 0.8rem;
}

/* BORDER UNDER THE ARRAY OF PROMOTIONS*/
.includings {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 10px;
  margin-top: 1rem;
}

/* LOGO POSITIONING */
.logo-pic {
  width: 70%;
}
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.text {
  width: 30%;
  padding-bottom: 60px;
}
.padd {
  padding-left: 50px;
}

/* SPAN COLORIZING*/
.coloring {
  color: #df2c26;
}
.coloring-grey {
  color: #858585;
}

.prod-info .uppercase {
  font-size: 0.7rem;
}
/* MEDIA QUERIES */
@media screen and (max-width: 1300px) {
  .container-div {
    padding: 40px 35px;
  }
}
@media screen and (max-width: 1140px) {
  .container-div {
    padding: 0px 10px;
  }
}
@media screen and (max-width: 1000px) {
  .container-div {
    padding: 0px 0px;
  }
  .logo-pic {
    width: 90%;
  }
  .text {
    width: 50%;
  }
}

@media screen and (max-width: 800px) {
  .container-div {
    padding: 0px 70px;
  }
}
@media screen and (max-width: 650px) {
  .prod-info .background {
    display: none;
  }
  .details {
    padding: 0;
  }
  .container-div {
    padding: 0px 0px;
  }
}
@media screen and (max-width: 400px) {
  .main-row h5 {
    font-size: 0.6rem;
  }
  .padd {
    padding-left: 0px;
  }
  .prod-info {
    flex-direction: column;
  }
  .background {
    padding: 25px 0px;
  }
  .ilosc {
    padding-right: 0px;
    padding-left: 30px;
  }
}
</style>
