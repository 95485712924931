<template>
  <ValidationProvider
    :name="$attrs.label"
    :rules="rules"
    v-slot="{ errors }"
    :vid="vid"
  >
    <v-text-field
      :style="width ? `width: ${width}px; margin: 0 auto;` : ''"
      :type="type"
      v-model="innerValue"
      :error-messages="errors"
      v-bind="$attrs"
      v-on="$listeners"
      outlined
      color="lightgrey"
      dense
      @change="emitChange"
      @keyup="handleNewCharacter"
      :value="innerValue"
      :disabled="disabled"
    ></v-text-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import * as Validator from "vee-validate";

Validator.extend("RegexEmail", {
  message: "Błędny format adresu email. pl|com|eu|uk|net|de|ru|br|fr|it|in",
  validate: (value) => {
    var patt = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+(pl|eu|com|uk|net|de|ru|br|fr|it|in)))$/
    );
    return patt.test(value);
  },
});

Validator.extend("postalCode", {
  message: "Nieprawidłowy kod pocztowy",
  validate: (value) => {
    var patt = new RegExp(/^[0-9]{2}-[0-9]{3}$/);
    return patt.test(value);
  },
});

export default {
  components: {
    ValidationProvider,
  },
  props: {
    rules: {
      type: [Object, String],
      default: "",
    },
    // must be included in props
    value: {
      type: null,
    },
    vid: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    width: {
      type: Number,
      default: null,
    },
    onlyNumbers: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    innerValue: "",
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      if (
        this.type === "number" &&
        this.innerValue &&
        this.innerValue.toString().match(/^[0-9]+,[0-9]+$/)
      ) {
        this.innerValue = this.innerValue.toString().replace(",", ".");
      }

      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      if (newVal) {
        this.innerValue = newVal.toString().trim();
      } else {
        this.innerValue = newVal;
      }
    },
  },
  methods: {
    handleNewCharacter(event) {
      if (this.onlyNumbers) {
        this.innerValue = this.innerValue.toString().replace(",", ".");
        const regex = /[^0-9.,]/g; // zmieniamy regex aby dopasowywał znaki do usunięcia
        this.innerValue = this.innerValue.replace(regex, "");
        if (event.key === "." && this.innerValue.includes(".")) {
          this.innerValue = this.innerValue.replace(/\.(?=.*\.)/g, "");
        }
      }
    },
    emitChange() {
      this.$emit("newValueAfterChange", this.innerValue);
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
};
</script>
