var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.customKey,staticClass:"wrapper"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"image-modal"},[_c('v-dialog',{attrs:{"width":"650"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({attrs:{"src":_vm.imageSrc,"alt":"\n            ","loading":"lazy"}},'img',attrs,false),on))]}}]),model:{value:(_vm.dialog1),callback:function ($$v) {_vm.dialog1=$$v},expression:"dialog1"}},[_c('v-card',{staticClass:"px-3 py-3"},[_c('img',{staticClass:"modal-img",attrs:{"src":_vm.imageSrc,"alt":"\n           ","loading":"lazy"}})])],1)],1),_c('div',{staticClass:"text-username"},[_c('div',{staticClass:"username"},[_vm._v(_vm._s(_vm.comment.user.login))]),_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s(_vm.comment.comment.text)}})]),_c('div',{staticClass:"comment-date"},[_vm._v(" "+_vm._s(_vm.formatCommentDate(_vm.comment.comment.createdAt))+" "),(_vm.hasAdminPermissions)?_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.deleteComment(_vm.comment.comment.id, false)}}},[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1):_vm._e()],1),(_vm.loggedIn)?_c('div',[_c('v-dialog',{attrs:{"width":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"answer",on:{"click":function($event){_vm.addReply = true}}},'div',attrs,false),on),[_c('i',{staticClass:"fas fa-reply"}),_c('h5',{staticClass:"small-screen-invisible"},[_vm._v("Odpowiedz")])])]}}],null,false,1756675178),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"px-3 py-3 modal"},[(_vm.addReply)?_c('div',{staticClass:"add-subcomment"},[_c('quill-editor',{ref:"myQuillEditor",staticClass:"window-edit",attrs:{"options":_vm.editorOption},model:{value:(_vm.subcontent),callback:function ($$v) {_vm.subcontent=$$v},expression:"subcontent"}}),_c('div',{staticClass:"background button-s",on:{"click":_vm.addSubComment}},[_vm._v(" Dodaj ")])],1):_vm._e()])],1)],1):_vm._e(),(
        _vm.comment && _vm.comment.comment.images && _vm.comment.comment.images.length > 0
      )?_c('div',{staticClass:"comment-images"},_vm._l((_vm.comment.comment.images),function(image){return _c('v-dialog',{key:image.id,attrs:{"width":"650"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"mini-image",attrs:{"src":_vm.fetchCommentImage(image.id),"alt":"","loading":"lazy"},on:{"click":function($event){_vm.assignTemporarysrc(_vm.fetchCommentImage(image.id))}}},'img',attrs,false),on))]}}],null,true),model:{value:(_vm.dialog3),callback:function ($$v) {_vm.dialog3=$$v},expression:"dialog3"}},[_c('v-card',{staticClass:"px-3 py-3"},[_c('img',{staticClass:"modal-img",attrs:{"src":_vm.temporarySrc,"alt":" kręgosłup\n          ","loading":"lazy"}})])],1)}),1):_vm._e()]),_vm._l((_vm.subComments),function(subcomment){return _c('div',{key:subcomment.id,staticClass:"subcomment-card"},[_c('div',{staticClass:"image-modal"},[_c('v-dialog',{attrs:{"width":"650"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({attrs:{"src":_vm.fetchSubProfilePic(subcomment.user.id),"alt":"\n            ","loading":"lazy"}},'img',attrs,false),on))]}}],null,true),model:{value:(_vm.dialog2),callback:function ($$v) {_vm.dialog2=$$v},expression:"dialog2"}},[_c('v-card',{staticClass:"px-3 py-3"},[_c('img',{staticClass:"modal-img",attrs:{"src":_vm.fetchSubProfilePic(subcomment.user.id),"alt":"\n            Nauli,\n            subskrypcja DagaJoga\n            filmy jogi,\n            szkoła online,\n            joga,\n            kurs jogi online,\n            uważna praktyka,\n            joga wzmacniająca,\n            joga online,\n            praktyka wzmacniająca,\n            praktyka,\n            ćwiczenia online\n            ","loading":"lazy"}})])],1)],1),_c('div',{staticClass:"text-username"},[_c('div',{staticClass:"username"},[_vm._v(_vm._s(subcomment.user.login))]),_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s(subcomment.subComment.text)}})]),_c('div',{staticClass:"comment-date"},[_vm._v(" "+_vm._s(_vm.formatCommentDate(subcomment.subComment.createdAt))+" "),(_vm.hasAdminPermissions)?_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.deleteComment(subcomment.subComment.id, true)}}},[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1):_vm._e()],1)])}),_c('before-delete-modal',{attrs:{"isVisible":_vm.isDeleteModalVisible},on:{"closeModal":function($event){return _vm.closeDeleteModal()},"confirmDeletion":function($event){return _vm.confirmDeleteComment()}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }