<template>
  <v-app>
    <div
      style="
        max-width: 1441px;
        margin: 0;
        padding: 0;
        width: 100%;
        position: relative;
      "
      :style="isMobile ? 'overflow: hidden;' : ''"
    >
      <Navbar />
      <snackbar />
      <v-main class="main-content">
        <v-container fluid>
          <router-view />
        </v-container>
      </v-main>

      <FloatingButton v-if="!withoutFloatingButton && !isMobile" />
    </div>
    <Footer />
  </v-app>
</template>

<script>
import Footer from "./components/Footer.vue";
import FloatingButton from "./components/FloatingButton";
// import SideMenu from "./components/SideMenu.vue";
import Navbar from "./components/Navbar.vue";
import Snackbar from "./components/Snackbar.vue";
export default {
  /* eslint-disable no-useless-escape */
  name: "App",
  components: {
    Footer,
    FloatingButton,
    // SideMenu,
    Navbar,
    Snackbar,
  },
  widthModal() {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return 280;
      case "sm":
        return 500;
      case "md":
        return 600;
      case "lg":
        return 650;
      case "xl":
        return 700;
      default:
        return 700;
    }
  },

  async mounted() {
    const bucket = window.localStorage.getItem("dagajoga_bucket");
    if (!bucket) {
      this.$store.dispatch("shopProducts/setEmptyBucket");
    } else {
      this.$store.dispatch("shopProducts/setBucketInStoreFromLocalStorage");
    }
    await this.$store.dispatch("auth/checkToken");
    await this.$store.dispatch(`auth/checkUserActiveSubscriptions`);
    await this.$store.dispatch(`auth/checkUserActiveSubscriptionsExtended`);
    const withoutFloatingButton = this.$route.meta.withoutFloatingButton;
    if (withoutFloatingButton) {
      this.withoutFloatingButton = true;
    }

    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        navigator.userAgent.substr(0, 4)
      )
    ) {
      this.isMobile = true;
    }
  },
  data() {
    return {
      withoutFloatingButton: true,
      isMobile: false,
    };
  },
  watch: {
    $route() {
      if (!window.localStorage.getItem("blockTokenCheck")) {
        this.$store.dispatch("auth/checkToken");
        const withoutFloatingButton = this.$route.meta.withoutFloatingButton;
        if (withoutFloatingButton) {
          this.withoutFloatingButton = true;
        } else {
          this.withoutFloatingButton = false;
        }
      } else {
        window.localStorage.removeItem("blockTokenCheck");
      }
    },
    breakpointName(breakpoint) {
      this.$store.dispatch("app/setScreenBreakpoint", breakpoint);
    },
  },
  computed: {
    breakpointName() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "xs";
        case "sm":
          return "sm";
        case "md":
          return "md";
        case "lg":
          return "lg";
        case "xl":
          return "xl";
        default:
          return "lg";
      }
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Poppins:wght@300;400;600;700&family=Raleway:wght@400;500&family=Roboto:wght@300;400;500;700&display=swap");
* {
  font-size: "Roboto", sans-serif;
}
html {
  width: 100vw;
}
.v-navigation-drawer {
  background-color: #fff !important;
  color: #000 !important;
  z-index: 200;
}
/* HIDE YOUTUBE CONTROLS IN PLYR VIDEO PLAYER */
.plyr iframe[id^="youtube"] {
  top: -50%;
  height: 200%;
}
iframe {
  pointer-events: none;
}
body {
  height: 100% !important;
  margin: 0 auto;
}
.v-application--wrap {
  align-items: center !important;
  margin: 0 !important;
  padding: 0 !important;
}
.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container {
  padding: 0px !important;
}
.outlined-form-section {
  position: relative;
  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
.btn {
  color: #c3a2a0;
  background: #d0cecc;
  width: fit-content;
  padding: 7px 15px;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.9rem;
  transition: all 0.5s ease;
  cursor: pointer;
  margin: 10px 0;
}
.btn:hover {
  background: #fcfbfa;
}

input:focus {
  outline: none;
}
.main-content {
  overflow: hidden;
  margin-bottom: 3rem;
}
.main-color-text {
  color: #9e7979 !important;
  letter-spacing: 2px !important;
  font-size: 54px;
  font-weight: 700;
}
.secondary-color-text {
  color: #d1b2b2;
}

.primary-button {
  color: #fff;
  background: linear-gradient(270deg, #c4a2a2 0%, #b28a8a 90.06%);
  border-radius: 8px;
  padding: 0px 15px;
  font-size: 15px !important;
  width: fit-content;
  cursor: pointer;
  font-weight: 400 !important;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.primary-background {
  background: linear-gradient(270deg, #c4a2a2 0%, #b28a8a 90.06%);
}

@media (max-width: 700px) {
  .header-image {
    height: auto !important;
  }
  .card-desc {
    margin-top: 0 !important;
  }
}

// .container {
//   height: 100%;
//   min-width: 100%;
//   margin: 0;
//   padding: 0;
//   background: #fff;
//   max-width: 2560px;
// }

.header-image-container {
  position: relative;
  .header-image {
    background-position: center;
    background-size: cover;
    width: 100%;
  }
  h1 {
    color: #fff;
    position: absolute;
    top: 70%;
    left: 7%;
    font-size: 3rem;
    @media (max-width: 1024px) {
      left: 3%;
      top: 65%;
    }
    @media (max-width: 768px) {
      font-size: 2.6rem;
      top: 35%;
      left: 1.5%;
    }
    @media (max-width: 425px) {
      font-size: 1.8rem;
      top: 35%;
    }
  }
}

.payment-container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  background: #fff;
  display: flex;
}
.payment-left-side,
.payment-right-side {
  width: 50%;
  padding: 3rem;
}
.payment-right-side {
  background: rgb(102, 102, 102);
  background: linear-gradient(
    90deg,
    rgba(102, 102, 102, 1) 19%,
    rgba(69, 78, 91, 1) 83%
  );
}
@media screen and (max-width: 800px) {
  .payment-container {
    flex-direction: column;
  }
  .payment-left-side,
  .payment-right-side {
    width: 100%;
  }
}
.sticky {
  background-color: #ffffff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  justify-self: flex-start !important;
  z-index: 200;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
</style>
