import axios from "../axios";
import errorHandler from "../errorHandler";

const movieCategories = {
  namespaced: true,

  state: () => ({
    movieCategories: [],
  }),
  mutations: {
    SET_MOVIE_CATEGORIES(state, movieCategories) {
      state.movieCategories = movieCategories;
    }
  },
  actions: {
    fetchAll: async ({ commit }, data) => {
      try {
        const type = data && data.type ? data.type : null
        const response = await axios.get(`management/categories`, {
          params: {
            forBeginners: type && type === 'FOR-BEGINNERS' ? true : false,
            forExtendedSubscription: type && type === 'EXTENDED' ? true : false
          }
        })
        commit("SET_MOVIE_CATEGORIES", response.data);
      } catch (error) {
        console.log(error);
      }
    },
    fetchAllForUser: async ({ commit }, data) => {
      try {
        const forBeginners = data && data.forBeginners;
        const forExtendedSubscription = data && data.forExtendedSubscription;
        let params = {};
        if (forBeginners) {
          params.forBeginners = true;
        }
        if (forExtendedSubscription) {
          params.forExtendedSubscription = true
        }
        const response = await axios.get(`categories`, {
          params
        })
        commit("SET_MOVIE_CATEGORIES", response.data);
      } catch (error) {
        console.log(error);
      }
    },

    async deleteMovieCategory({ dispatch }, data) {
      try {
        const type = data.type
        await axios.delete(`management/categories/${data.id}`);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie usunięto!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        await dispatch("fetchAll", { type });
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async updateMovieCategory({ dispatch }, object) {
      try {
        const type = object.type
        await axios.put(`management/categories`, object.data);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Edycja przebiegła pomyślnie!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        await dispatch("fetchAll", { type });
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async addMovieCategory({ dispatch }, object) {
      try {
        const type = object.type
        if (type) {
          object.data.type = type;
        }
        await axios.post(`management/categories`, object.data);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie dodano!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        await dispatch("fetchAll", { type });
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

  },
  getters: {
    getMovieCategories: (state) => state.movieCategories
  },
}
export default movieCategories;