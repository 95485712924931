var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"width":"100%"}},[_c('management-nav'),_c('v-card-title',[_vm._v(" Płatności "),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Szukaj","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('font-awesome-icon',{staticClass:"mr-1 mt-1",attrs:{"slot":"prepend-inner","icon":"search"},slot:"prepend-inner"})],1)],1),_c('v-data-table',{attrs:{"loading":!_vm.items,"loading-text":"Trwa pobieranie danych...","headers":_vm.headers,"items":_vm.items,"search":_vm.search},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":_vm.items && _vm.items.length === 0}},[_vm._v(" Brak płatności ")])]},proxy:true},{key:"item.timePackageExpirationTime",fn:function(ref){
var item = ref.item;
return [_c('p',{attrs:{"id":item.id},domProps:{"innerHTML":_vm._s(_vm.formatExpiryDate(item.timePackageExpirationTime))}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({attrs:{"id":item.id},domProps:{"innerHTML":_vm._s(_vm.formatDate(item.createdAt))}},'p',attrs,false),on))]}}],null,true)})]}},{key:"item.invoiceUrl",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticStyle:{"cursor":"pointer","text-decoration":"underline"},attrs:{"id":item.id,"href":_vm.getLink(item.invoiceUrl),"target":"_blank","rel":"noopener noreferrer"},domProps:{"innerHTML":_vm._s(_vm.downloadText(item.invoiceUrl))}},'a',attrs,false),on))]}}],null,true)})]}},{key:"item.packageItems",fn:function(ref){
var item = ref.item;
return _vm._l((item.packageItems),function(link){return _c('div',{key:link.id},[_vm._v(" "+_vm._s(link.link)+" ")])})}},{key:"item.isActiveTimePackage",fn:function(ref){
var item = ref.item;
return [_c('p',{attrs:{"id":item.id},domProps:{"innerHTML":_vm._s(_vm.isActive(item.isActiveTimePackage))}})]}},{key:"item.productType",fn:function(ref){
var item = ref.item;
return [_c('p',{attrs:{"id":item.id},domProps:{"innerHTML":_vm._s(_vm.getProductName(item))}})]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({attrs:{"id":item.id},domProps:{"innerHTML":_vm._s(_vm.htmlToFormattedText(item.description))}},'p',attrs,false),on))]}}],null,true)})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({attrs:{"id":item.id},domProps:{"innerHTML":_vm._s(_vm.getShortText(item.name))}},'p',attrs,false),on))]}}],null,true)})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }