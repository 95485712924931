var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"width":"100%"}},[_c('management-nav'),_c('v-card-title',[_vm._v(" Dostępne warsztaty "),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Szukaj","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('font-awesome-icon',{staticClass:"mr-1 mt-1",attrs:{"slot":"prepend-inner","icon":"search"},slot:"prepend-inner"})],1)],1),_c('v-data-table',{attrs:{"loading":!_vm.items,"loading-text":"Trwa pobieranie danych...","headers":_vm.headers,"items":_vm.items,"search":_vm.search},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":_vm.items && _vm.items.length === 0}},[_vm._v(" Brak warsztatów ")])]},proxy:true},{key:"item.startTime",fn:function(ref){
var item = ref.item;
return [(item.startTime)?_c('span',[_vm._v(_vm._s(_vm.formatDate(item.startTime))+" "),_c('v-btn',{attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.editDate(item)}}},[_c('font-awesome-icon',{attrs:{"icon":"edit"}})],1)],1):_c('span',[_vm._v("Brak")])]}},{key:"item.members",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{on:{"click":function($event){return _vm.showMembers(item)}}},[_vm._v(" Pokaż ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"green"},on:{"click":[function($event){return _vm.editPackage(item)},function($event){_vm.isModalVisible = true}]}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"edit"}})],1)]}}],null,true)},[_c('span',[_vm._v("Edytuj")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red"},on:{"click":function($event){(_vm.isDeleteModalVisible = true), (_vm.packageData = item)}}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1)]}}],null,true)},[_c('span',[_vm._v("Usuń")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"orange"},on:{"click":[function($event){return _vm.copyPackage(item)},function($event){_vm.isModalVisible = true}]}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"copy"}})],1)]}}],null,true)},[_c('span',[_vm._v("Kopiuj")])])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [[_c('p',{attrs:{"id":item.id},domProps:{"innerHTML":_vm._s(_vm.htmlToFormattedText(item.description))}})]]}}],null,true)}),_c('AddTimePackageModal',{attrs:{"isVisible":_vm.isModalVisible,"packageData":_vm.packageData,"withDiscount":true,"isWorkshops":true},on:{"closeModal":function($event){return _vm.closeModal()}}}),_c('SelectDateModal',{attrs:{"isVisible":_vm.isSelectDateModalVisible,"currentDate":_vm.currentDate},on:{"closeModal":function($event){return _vm.closeSelectDateModal()},"dateSelected":_vm.dateSelected}}),_c('TimePackageOrdersModal',{attrs:{"isVisible":_vm.isTimePackageMembersModalVisible,"orders":_vm.orders},on:{"closeModal":function($event){return _vm.closeTimePackageOrderModal()}}}),_c('before-delete-modal',{attrs:{"isVisible":_vm.isDeleteModalVisible},on:{"closeModal":function($event){return _vm.closeDeleteModal()},"confirmDeletion":function($event){return _vm.confirmDelete(_vm.packageData.id)}}}),_c('v-btn',{attrs:{"fab":"","dark":"","large":"","color":"green","fixed":"","left":"","bottom":""},on:{"click":function($event){_vm.isModalVisible = true}}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }