var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"width":"100%"}},[_c('management-nav'),_c('v-card-title',[_vm._v(" Podkategorie nr 2 "),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Szukaj","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('font-awesome-icon',{staticClass:"mt-1 mr-1",attrs:{"slot":"prepend-inner","icon":"search"},slot:"prepend-inner"})],1)],1),_c('v-data-table',{attrs:{"loading":!_vm.items,"loading-text":"Trwa pobieranie danych...","headers":_vm.headers,"items":_vm.items,"search":_vm.search},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":_vm.items && _vm.items.length === 0}},[_vm._v(" Brak podkategorii w subskrypcjach miesięcznych ")])]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"green"},on:{"click":[function($event){return _vm.editSubCategory(item)},function($event){_vm.isModalVisible = true}]}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"edit"}})],1)]}}],null,true)},[_c('span',[_vm._v("Edytuj")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red"},on:{"click":function($event){(_vm.isDeleteModalVisible = true), (_vm.currentItem = item)}}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1)]}}],null,true)},[_c('span',[_vm._v("Usuń")])])]}},{key:"item.photo",fn:function(ref){
var item = ref.item;
return [[_c('img',_vm._g(_vm._b({staticClass:"item-image",attrs:{"id":item.imagePath,"alt":"\n            joga zdrowy,\n            filmy jogi,\n            techniki oddechowe,\n            praktyka wzmacniająca,\n            joga online,\n            praktyka dla początkujących,\n            szkoła online,\n            prana vyavama,\n            joga wzmacniająca,\n            joga video,\n            ćwiczenia online,\n            uważna praktyka,\n            anatomia jogi\n            ","loading":"lazy"},on:{"click":[function($event){return _vm.editSubCategory(item)},function($event){_vm.isModalVisible = true}]}},'img',_vm.attrs,false),_vm.on))]]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [[_c('p',_vm._g(_vm._b({attrs:{"id":item.id},domProps:{"innerHTML":_vm._s(_vm.htmlToFormattedText(item.name))}},'p',_vm.attrs,false),_vm.on))]]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [[_c('p',_vm._g(_vm._b({attrs:{"id":item.id},domProps:{"innerHTML":_vm._s(_vm.htmlToFormattedText(item.description))}},'p',_vm.attrs,false),_vm.on))]]}}],null,true)}),_c('add-sub-category-modal',{attrs:{"isVisible":_vm.isModalVisible,"movieSubCategoryData":_vm.movieSubCategoryData,"level":2},on:{"closeModal":function($event){return _vm.closeModal()},"updated":function($event){return _vm.timePackageUpdated()}}}),_c('BeforeDeleteModal',{attrs:{"isVisible":_vm.isDeleteModalVisible},on:{"closeModal":function($event){return _vm.closeDeleteModal()},"confirmDeletion":function($event){return _vm.confirm(_vm.currentItem.id)}}}),_c('v-btn',{attrs:{"fab":"","dark":"","large":"","color":"green","fixed":"","left":"","bottom":""},on:{"click":function($event){_vm.isModalVisible = true}}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }