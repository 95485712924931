<template>
  <div style="margin-top: 0.5rem; padding: 0">
    <span v-if="maxLength"
      ><b>Maksymalna liczba znaków</b>: {{ maxLength }}</span
    >
    <p><b>Liczba znaków</b>: {{ content.length }}</p>
    <quill-editor
      ref="myQuillEditor"
      @change="onEditorChange($event)"
      style="width: 100%; margin-bottom: 1rem; padding: 0"
      :content="content"
      :id="editorId"
    >
    </quill-editor>
  </div>
</template>
<script>
export default {
  props: ["startContent", "editorId", "maxLength"],
  data() {
    return {
      content: "",
      replacedImages: [],
    };
  },
  created() {
    if (this.startContent.length > 0) this.content = this.startContent;
  },

  watch: {
    startContent() {
      this.content = this.startContent;
    },
  },

  methods: {
    uploadImage() {},
    async onEditorChange({ html }) {
      if (html !== null && html !== undefined) {
        let htmlWithReplacedCharacters = html;
        if (html) {
          htmlWithReplacedCharacters = html
            .replaceAll(`class="ql-align-center"`, `style="text-align: center"`)
            .replaceAll(
              `class="ql-align-justify"`,
              `style="text-align: justify"`
            )
            .replaceAll(`class="ql-size-huge"`, `style="font-size: 32px;"`)
            .replaceAll(`class="ql-size-large"`, `style="font-size: 19px;"`)
            .replaceAll(`class="ql-size-small"`, `style="font-size: 10px;"`)

            .replaceAll(`class="ql-align-right"`, `style="text-align: right"`);

          this.replacedImages.forEach((image) => {
            htmlWithReplacedCharacters = htmlWithReplacedCharacters.replace(
              image.base64,
              image.imageUrl
            );
          });
          const imageRegex = /<img src="data:image\/png;base64,[^"]*">/;

          // Wyrażenie regularne dla obrazków z googleusercontent, uwzględniając atrybuty height i width
          const imageFromGoogleUserContentRegex =
            /<img\s+src="(https:\/\/[^"]*\.googleusercontent\.com\/[^"]*)"(?:\s+height="(\d+)")?(?:\s+width="(\d+)")?\s*\/?>/g;

          const matchedGoogleImage = htmlWithReplacedCharacters.match(
            imageFromGoogleUserContentRegex
          );

          const base64StringRegex =
            /<img src="data:image\/png;base64,([^"]*)">/;
          const matchedBase64 =
            htmlWithReplacedCharacters.match(base64StringRegex);
          // Przetwarzanie obrazków base64
          if (matchedBase64 || matchedGoogleImage) {
            let base64String = "";
            let extension = "";

            // Jeśli dopasowano obrazek base64
            if (matchedBase64) {
              base64String = matchedBase64[1];
              const start = matchedBase64[0].substring(0, 40);

              // Określanie rozszerzenia obrazka
              if (start.includes("png")) {
                extension = "png";
              } else if (start.includes("jpeg") || start.includes("jpg")) {
                extension = "jpg";
              } else if (start.includes("gif")) {
                extension = "gif";
              } else if (start.includes("svg")) {
                extension = "svg";
              }

              const url = await this.$store.dispatch(
                "app/getImageUrlFromBase64",
                { base64: base64String, extension }
              );

              htmlWithReplacedCharacters = htmlWithReplacedCharacters.replace(
                imageRegex,
                `<img src="${url}" />`
              );
              this.replacedImages.push({
                base64: matchedBase64[0],
                imageUrl: `<img src="${url}" />`,
              });
            }

            // Jeśli dopasowano obrazek z googleusercontent
            if (matchedGoogleImage) {
              const matchedImage = matchedGoogleImage[0];
              //matchedImage = "

              const imageUrl = matchedImage.match(/src="([^"]*)"/)[1];
              const height = matchedImage.split(" ")[2]; // Atrybut height, jeśli istnieje
              const width = matchedImage.split(" ")[3]; // Atrybut width, jeśli istnieje

              const googleImageUrl = await this.$store.dispatch(
                "app/getImageUrlFromExternalUrl",
                { url: imageUrl }
              );

              // Buduj nowy tag img z zachowaniem height i width
              let newImgTag = `<img src="${googleImageUrl}"`;
              if (height) newImgTag += ` ${height}`;
              if (width) newImgTag += ` ${width.replace(">", "")}`;
              newImgTag += " />";
              // Zamień oryginalny obrazek na nowy z aktualizowanym URL
              htmlWithReplacedCharacters = htmlWithReplacedCharacters.replace(
                matchedImage,
                newImgTag
              );

              this.replacedImages.push({
                base64: matchedImage,
                imageUrl: newImgTag,
              });
            }
          }
        }

        this.$emit("contentchanged", htmlWithReplacedCharacters);
        this.content = html;
      }
    },
  },

  computed: {
    editor() {
      if (this.$refs.myQuillEditor) {
        return this.$refs.myQuillEditor.quill;
      } else {
        return null;
      }
    },
  },
};
</script>
<style scoped>
.a {
  text-align: right;
}
</style>
