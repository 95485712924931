<template>
  <v-row justify="center">
    <v-dialog
      max-width="290px"
      :value="isVisible"
      persistent
      @keydown.esc="closeModal"
    >
      <v-card>
        <ValidationObserver v-slot="{ invalid, handleSubmit }">
          <v-form @submit.prevent="handleSubmit(onSubmit)">
            <ValidationProvider rules="required">
              <v-date-picker
                v-model="selectedDate"
                show-adjacent-months
                width="290px"
                required
              ></v-date-picker>
            </ValidationProvider>

            <VTextFieldWithValidation
              v-if="withPrice"
              v-model="price"
              rules="numeric|required"
              label="Cena"
              :width="250"
            />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="warning" text @click="closeModal">
                <font-awesome-icon icon="times" class="mr-1" />
                Anuluj
              </v-btn>
              <v-btn type="submit" color="success" text :disabled="invalid">
                <font-awesome-icon icon="check" class="mr-1" />
                Zapisz
              </v-btn>
            </v-card-actions>
          </v-form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from "moment";
import VTextFieldWithValidation from "../inputs/VTextFieldWithValidation.vue";
import { ValidationObserver } from "vee-validate";
export default {
  name: "SelectDateModal",
  props: ["isVisible", "currentDate", "withPrice"],

  components: { VTextFieldWithValidation, ValidationObserver },

  data: () => ({
    selectedDate: null,
    price: 0,
  }),

  watch: {
    currentDate() {
      this.selectedDate = moment(this.currentDate).format("YYYY-MM-DD");
    },
  },
  mounted() {
    if (this.currentDate) {
      this.selectedDate = moment(this.currentDate).format("YYYY-MM-DD");
    }
  },
  methods: {
    onSubmit() {
      this.$emit("dateSelected", "", this.selectedDate, this.price);
    },
    closeModal() {
      this.selectedDate = null;
      this.$emit("closeModal");
    },
  },
};
</script>
<style type="scss" scoped></style>
