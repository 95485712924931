var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ReturnBtn',{attrs:{"path":this.path
        ? this.path
        : ("" + (_vm.selectedVideo && _vm.selectedVideo.forBeginners
              ? 'subskrypcja-podstawy'
              : 'subskrypcja')),"blackColor":""}}),_c('v-row',{staticClass:"card"},[_c('div',{staticClass:"switch"},[(_vm.getDemoCourseYoutubeId() !== '')?_c('div',{staticClass:"demo btn-gradient",class:{ selectedSwitch: _vm.selectedVersion === 'DEMO' }},[_vm._v(" Demo ")]):_vm._e(),_c('div',{staticClass:"full-video btn-gradient",class:{ selectedSwitch: _vm.selectedVersion === 'FULL' },on:{"click":function($event){return _vm.changeVersion()}}},[_vm._v(" Pełna wersja ")])]),(_vm.selectedVersion === 'DEMO' && _vm.getDemoCourseYoutubeId() !== '')?_c('v-col',[_c('video-player',{attrs:{"videoId":_vm.getDemoCourseYoutubeId()}})],1):_vm._e(),(_vm.selectedVideo && _vm.selectedVideo.viewsCounter)?_c('p',{staticClass:"viewsCounter",attrs:{"align":"right"}},[_vm._v(" wyświetlenia: "+_vm._s(_vm.selectedVideo.viewsCounter)+" ")]):_vm._e(),(_vm.selectedVideo && _vm.loggedIn)?_c('p',{staticClass:"favorite-button",attrs:{"align":"right"},on:{"click":_vm.handleFavourites}},[(!_vm.selectedVideo.favorite)?_c('font-awesome-icon',{staticClass:"favorite-icon",attrs:{"icon":"plus"}}):_c('font-awesome-icon',{staticClass:"favorite-icon",attrs:{"icon":"minus"}}),_vm._v(" "+_vm._s(_vm.selectedVideo.favorite ? "Usuń z ulubionych" : "Dodaj do ulubionych")+" ")],1):_vm._e(),_c('div',{staticClass:"text-under"},[_c('h3',{staticClass:"background"},[_vm._v(" "+_vm._s(_vm.selectedVideo ? _vm.selectedVideo.name : _vm.course ? _vm.course.name : "")+" ")]),_c('p',{domProps:{"innerHTML":_vm._s(
          _vm.selectedVideo
            ? _vm.htmlToFormattedText(_vm.selectedVideo.description)
            : _vm.course
            ? _vm.htmlToFormattedText(_vm.course.description)
            : ''
        )}})])],1),_c('CommentSection',{attrs:{"id":_vm.selectedVideo ? _vm.selectedVideo.id : null}}),_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.freeSusbcriptionDialog),callback:function ($$v) {_vm.freeSusbcriptionDialog=$$v},expression:"freeSusbcriptionDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Aktywuj subskrypcję ")]),_c('v-card-text',{staticClass:"mt-4"},[_vm._v(" W celu obejrzenia pełnej wersji filmu zakup subskrypcję ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.closeModal}},[_vm._v(" OK ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.moveToChooseSubscriptions}},[_vm._v(" Wybierz subskrypcję ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }