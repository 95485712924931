import axios from "../axios";

const theoryPracticeWorkshopsLessonsTheories = {
  namespaced: true,

  state: () => ({
    theoryPracticeWorkshopsLessonsTheories: [],
    updatedTheory: null,
  }),

  mutations: {
    SET_THEORY_PRACTICE_WORKSHOPS_LESSONS_THEORIES(state, theories) {
      state.theoryPracticeWorkshopsLessonsTheories = theories;
    },
    SET_THEORY_PRACTICE_WORKSHOP_LESSON_THEORY(state, updatedTheory) {
      state.updatedTheory = updatedTheory;
    },
  },

  actions: {
    async fetchTheoryPracticeWorkshopsLessonsTheories({ commit }, data) {
      commit("SET_THEORY_PRACTICE_WORKSHOPS_LESSONS_THEORIES", []);
      const params = {};
      if (data && data.theoryPracticeWorkshopsLessonId) {
        params.theoryPracticeWorkshopsLessonId =
          data.theoryPracticeWorkshopsLessonId;
      }
      try {
        const response = await axios.get(
          `theoryPracticeWorkshopsLessonsTheories`,
          { params }
        );
        return response.data;
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOPS_LESSONS_THEORIES", []);
        console.log(err);
      }
    },

    async createTheoryPracticeWorkshopLessonTheory(
      { commit, dispatch },
      object
    ) {
      const { data } = object;
      const formData = new FormData();
      for (let key in data) {
        if (data[key] !== null && data[key] !== undefined) {
          formData.append(key, data[key]);
        }
      }

      try {
        const response = await axios.post(
          `theoryPracticeWorkshopsLessonsTheories`,
          formData
        );
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON_THEORY", response.data);
        await dispatch("fetchTheoryPracticeWorkshopsLessonsTheories");
        return response.status;
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON_THEORY", null);
        console.log(err);
      }
    },

    async editTheoryPracticeWorkshopLessonTheory({ commit, dispatch }, object) {
      const { data, id } = object;
      const formData = new FormData();
      for (let key in data) {
        if (data[key]) {
          formData.append(key, data[key]);
        }
      }

      try {
        const response = await axios.put(
          `theoryPracticeWorkshopsLessonsTheories/${id}`,
          formData
        );
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON_THEORY", response.data);

        await dispatch("fetchTheoryPracticeWorkshopsLessonsTheories");
        return response.status;
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON_THEORY", null);
        console.log(err);
      }
    },

    async deleteTheoryPracticeWorkshopLessonTheory({ commit, dispatch }, data) {
      try {
        await axios.delete(`theoryPracticeWorkshopsLessonsTheories/${data.id}`);
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON_THEORY", true);
        await dispatch("fetchTheoryPracticeWorkshopsLessonsTheories", {
          withNotActive: true,
        });
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON_THEORY", false);
        console.log(err);
      }
    },

    async addTheoryPracticeWorkshopLessonTheoryImage({ commit }, data) {
      try {
        const { theoryPracticeWorkshopLessonTheoryId, file } = data;
        const formData = new FormData();
        formData.append("file", file);
        const response = await axios.post(
          `theoryPracticeWorkshopsLessonsTheories/${theoryPracticeWorkshopLessonTheoryId}/images`,
          formData
        );
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON_THEORY", true);
        return response.data;
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON_THEORY", false);
        console.log(err);
      }
    },

    async deleteTheoryPracticeWorkshopLessonTheoryImage(
      { commit, dispatch },
      data
    ) {
      try {
        const { theoryPracticeWorkshopLessonTheoryId, imageId } = data;
        await axios.delete(
          `theoryPracticeWorkshopsLessonsTheories/${theoryPracticeWorkshopLessonTheoryId}/images/${imageId}`
        );
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON_THEORY", true);
        await dispatch("fetchTheoryPracticeWorkshopsLessonsTheories", {
          withNotActive: true,
        });
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON_THEORY", false);
        console.log(err);
      }
    },
  },

  getters: {
    getTheoryPracticeWorkshopsLessonsTheories: (state) =>
      state.theoryPracticeWorkshopsLessonsTheories,
  },
};
export default theoryPracticeWorkshopsLessonsTheories;
