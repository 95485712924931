<template>
  <v-card width="100%">
    <management-nav />
    <v-card-title>
      Karnety dla początkujących
      <v-spacer></v-spacer>
      <v-text-field v-model="search" label="Szukaj" single-line hide-details
        ><font-awesome-icon
          icon="search"
          slot="prepend-inner"
          class="mr-1 mt-1"
      /></v-text-field>
    </v-card-title>
    <v-data-table
      :loading="!tickets"
      loading-text="Trwa pobieranie danych..."
      :headers="headers"
      :items="tickets"
      :search="search"
    >
      <template v-slot:[`item.value`]="{ item }">
        <template>
          <div class="quantity-selector">
            <div class="quantity-selector-minus" @click="minusQuantity(item)">
              <span class="prevent-select">-</span>
            </div>
            <p class="quantity-selector-value">{{ item.value }}</p>
            <div class="quantity-selector-minus" @click="addQuantity(item)">
              <span class="prevent-select">+</span>
            </div>
          </div>
        </template>
      </template>

      <template v-slot:[`item.expirationTime`]="{ item }">
        <template>
          <div class="expiration-time">
            <p :id="item.id" v-html="formatDate(item.expirationTime)" />
            <v-btn icon color="green" @click="editDate(item)">
              <font-awesome-icon icon="edit" />
            </v-btn>
          </div>
        </template>
      </template>

      <template v-slot:no-data>
        <v-alert :value="items && items.length === 0"> Brak karnetów </v-alert>
      </template>
    </v-data-table>
    <SelectDateModal
      v-if="this.selectedTicket"
      :isVisible="isSelectDateModalVisible"
      @closeModal="closeSelectDateModal()"
      @dateSelected="handleNewExpirationDate"
    />
  </v-card>
</template>

<script>
import ManagementNav from "../components/ManagementNav.vue";
import SelectDateModal from "../components/modals/SelectDateModal.vue";
import moment from "moment";
export default {
  name: "TicketsBeginnersManagement",
  components: {
    ManagementNav,
    SelectDateModal,
  },
  async created() {
    await this.$store.dispatch("coursesTickets/fetchListOfTickets", {
      type: "BEGINNERS",
    });
    this.tickets = this.items;
  },
  data: () => ({
    headers: [
      { text: "Email", value: "email" },
      { text: "Liczba dostępnych zajęć", value: "value" },
      { text: "Data wygaśnięcia", value: "expirationTime" },
    ],
    search: "",
    isSelectDateModalVisible: false,
    selectedTicket: null,
    addedQuantity: {},
    tickets: [],
  }),
  computed: {
    items() {
      const tickets = this.$store.getters["coursesTickets/getTickets"];
      return tickets;
    },
  },
  watch: {
    items() {
      this.tickets = this.items;
    },
  },
  methods: {
    formatDate(date) {
      if (date != null) {
        const formattedDate = moment(date).format("YYYY-DD-MM HH:mm");
        return formattedDate;
      }
      return null;
    },
    editDate(ticket) {
      this.selectedTicket = ticket;
      this.isSelectDateModalVisible = true;
    },
    closeSelectDateModal() {
      this.isSelectDateModalVisible = false;
    },
    async handleNewExpirationDate(e, newDate) {
      await this.$store.dispatch(
        "coursesTickets/changeExpirationTimeForTicket",
        {
          email: this.selectedTicket.email,
          date: newDate,
          type: "BEGINNERS",
        }
      );
      this.closeSelectDateModal();
    },
    async addQuantity(ticket) {
      await this.$store.dispatch("coursesTickets/addValueToTicket", {
        email: ticket.email,
        type: "BEGINNERS",
      });
      this.tickets.find((t) => t.id === ticket.id).value++;
    },
    async minusQuantity(ticket) {
      if (ticket.value > 0) {
        await this.$store.dispatch("coursesTickets/minusValueToTicket", {
          email: ticket.email,
          type: "BEGINNERS",
        });
        this.tickets.find((t) => t.id === ticket.id).value--;
      }
    },
  },
};
</script>

<style scoped>
.quantity-selector {
  display: flex;
  align-items: center;
  gap: 2rem;
  border-radius: 50px;
  height: 35px;
}
.quantity-selector-value {
  margin: 0;
  min-width: 30px;
  display: flex;
  justify-content: center;
}

.quantity-selector-plus,
.quantity-selector-minus {
  font-size: 25px;
  font-weight: lighter;
  color: #000;
  cursor: pointer;
}

.quantity-selector-plus:hover,
.quantity-selector-minus:hover {
  font-size: 25px;
  color: #b28a8a;
}
.expiration-time {
  display: flex;
  align-items: center;
}
.expiration-time p {
  margin: 0;
}
</style>
