var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"payment-container"},[_c('div',{staticClass:"payment-left-side w-50"},[_c('PurchaseSumUp',{attrs:{"selectedPackage":_vm.selectedTimePackage,"timePackagesItemsNames":_vm.timePackagesItemsNames,"activeSubscription":_vm.activeSubscriptions &&
        _vm.activeSubscriptions[0] &&
        _vm.activeSubscriptions[0].expiration_time
          ? _vm.activeSubscriptions[0]
          : null,"activeSubscriptionExtended":_vm.activeSubscriptionsExtended &&
        _vm.activeSubscriptionsExtended[0] &&
        _vm.activeSubscriptionsExtended[0].expiration_time
          ? _vm.activeSubscriptionsExtended[0]
          : null,"isExtendedSubscription":_vm.isExtendedSubscription}})],1),_c('div',{staticClass:"payment-right-side w-50"},[_c('PaymentForm',{key:_vm.profile ? _vm.profile.id : 0,attrs:{"profile":_vm.profile,"selectedPackage":_vm.selectedTimePackage,"isExtendedSubscription":_vm.isExtendedSubscription}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }