<template>
  <div class="text-left ml-3 mb-12">
    <span v-if="hasActiveSubscription">
      Subskrypcja ogólna kończy się:
      {{ getExpirationDate(activeSubscriptions[0].expiration_time) }}
      <p class="">
        <a @click="redirect" class="redirect-to-subscriptions"
          >Przedłuż subskrypcję ogólną</a
        >
      </p>
    </span>
    <span v-else>
      Subskrypcja "Szkoła online - platforma jogi online - Ogólna"
      <p class="">
        <a @click="redirect" class="redirect-to-subscriptions">Wykup dostęp</a>
      </p>
    </span>

    <!-- <span v-if="hasActiveSubscriptionExtended">
      Subskrypcja rozszerzona kończy się:
      {{ getExpirationDate(activeSubscriptionsExtended[0].expiration_time) }}
      <p class="">
        <a @click="redirect" class="redirect-to-subscriptions"
          >Przedłuż subskrypcję rozszerzoną</a
        >
      </p>
    </span>
    <span v-else>
      Subskrypcja "Szkoła online - platforma jogi online - Rozszerzona"
      <p class="">
        <a
          @click="redirectToExtendedSubscriptions"
          class="redirect-to-subscriptions"
          >Wykup dostęp</a
        >
      </p>
    </span> -->
    <telegram v-if="hasActiveSubscription" />
  </div>
</template>

<script>
import moment from "moment";
import Telegram from "./Telegram.vue";
export default {
  components: { Telegram },
  async mounted() {
    await this.$store.dispatch(`auth/checkUserActiveSubscriptions`);
    await this.$store.dispatch(`auth/checkUserActiveSubscriptionsExtended`);
    if (this.hasActiveSubscriptionExtended) {
      await this.$store.dispatch(
        `timePackages/fetchActiveSubscriptionsExtended`
      );
    } else {
      await this.$store.dispatch(
        `timePackages/setActiveSubscriptionsExtendedToNull`
      );
    }

    if (this.hasActiveSubscription) {
      await this.$store.dispatch(`timePackages/fetchActiveSubscriptions`);
    } else {
      await this.$store.dispatch(`timePackages/setActiveSubscriptionsToNull`);
    }
  },
  methods: {
    getExpirationDate(date) {
      return moment(date).locale("pl").format("DD MMMM YYYY");
    },
    redirect() {
      this.$router.push({
        name: "TimeSubscriptions",
        params: { name: "Courses" },
      });
    },
    redirectToExtendedSubscriptions() {
      if (this.$route.name === "CoursesExtended") {
        document.getElementById("buy-extended").scrollIntoView();
      } else {
        this.$router.push({
          name: "CoursesExtended",
          params: { name: "Courses" },
        });
      }
    },
  },

  computed: {
    activeSubscriptions() {
      return this.$store.getters["timePackages/getActiveSubscriptions"];
    },
    activeSubscriptionsExtended() {
      return this.$store.getters["timePackages/getActiveSubscriptionsExtended"];
    },
    hasActiveSubscription() {
      return this.$store.getters["auth/getHasActiveSubscription"];
    },
    hasActiveSubscriptionExtended() {
      return this.$store.getters["auth/getHasActiveSubscriptionExtended"];
    },
  },
};
</script>

<style scoped>
.redirect-to-subscriptions {
  color: #000;
  text-decoration: underline;
  font-size: 15px;
  font-weight: 600;
  width: fit-content;
}
</style>