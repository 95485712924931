<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex xs12 sm6 offset-sm3 mt-6 class="transparent-bg">
        <h1 class="text-center">Dziękujemy za zakup karnetu.</h1>
        <blockquote class="blockquote text-center">
          Jeśli płatność przebiegła pomyślnie, szczegóły dotyczące Twojego
          zamówienia znajdziesz w wiadomości wysłanej na podany przez Ciebie
          adres e-mail.
        </blockquote>
        <blockquote class="blockquote text-center">
          <a :href="homepage" class="center">
            <button class="primary-button">Strona główna</button>
          </a>
        </blockquote>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import config from "../config";
export default {
  name: "CoursesTicketsPaymentSuccess",
  metaInfo: {
    title: "Dagajoga karnety - portal jogi online",
  },
  data: function () {
    return {
      homepage: config.HOMEPAGE,
    };
  },
};
</script>

<style scoped>
.telegram {
  position: absolute;
  bottom: 0;
  right: 1rem;
}
.transparent-bg {
  position: relative;
  padding: 50px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.664);
}
i {
  font-size: 120px;
}
.center {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  cursor: default;
}
a {
  text-decoration: none;
}
</style>
