<template>
  <v-container fluid>
    <v-flex xs12 sm6 offset-sm3 mt-6 class="transparent-bg">
      <h1 class="text-center mb-4 font-weight-light">
        Niestety nie znaleziono podanej strony
      </h1>
      <div class="d-flex justify-center w-full flex-wrap">
        <a href="https://dagajoga.pl/"
          ><button
            type="submit"
            class="btn-gradient mx-2"
            @click="moveToDagajogaPage"
          >
            Strona główna Dagajoga.pl
          </button></a
        >
        <button type="submit" class="btn-gradient mx-2" @click="moveToCourses">
          Kursy jogi online
        </button>
      </div>
    </v-flex>
  </v-container>
</template>

<script>
import router from "../router";
export default {
  name: "NotFound",

  data() {
    return {};
  },

  created() {},
  methods: {
    moveToDagajogaPage() {
      router.resolve("https://dagajoga.pl/");
    },
    moveToCourses() {
      router.push({ name: "Courses" });
    },
  },
};
</script>

<style scoped>
.transparent-bg {
  padding: 100px 1rem;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.664);
  margin: 1rem;
}
.btn-gradient {
  padding: 8px 32px;
  border-radius: 30px;
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 500;
  min-width: 280px;
}
</style>
