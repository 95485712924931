import axios from "../axios";
import errorHandler from "../errorHandler";
import router from "../router";

const payment = {
  namespaced: true,
  state: () => ({
    giftCardForPayment: null,
    giftCards: []
  }),

  mutations: {
    SET_GIFT_CARD_FOR_PAYMENT(state, giftCard) {
      state.giftCardForPayment = giftCard;
    },
    SET_GIFT_CARDS(state, giftCards) {
      state.giftCards = giftCards
    }
  },

  actions: {
    async setGiftCardForPayment({ commit }, data) {
      const { id } = data;
      try {
        const response = await axios.get(`giftCards/${id}`)
        commit("SET_GIFT_CARD_FOR_PAYMENT", response.data);
      } catch (error) {
        console.log(error);
      }


    },
    async useUserGiftCard({ dispatch }, data) {
      const code = data.code
      try {
        await axios.post(`userGiftCards/code`, {
          code
        })
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Twoja subskrypcja została przedłużona",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        router.push({ name: "Courses" });
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message:
              "Podany kod jest nieprawidłowy",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        console.log(error);
      }
    },
    fetchGiftCards: async ({ commit }, data) => {
      try {
        const type = data && data.type ? data.type : null;
        const response = await axios.get("giftCards", {
          params: {
            type
          }
        });
        commit("SET_GIFT_CARDS", response.data);
      } catch (error) {
        console.log(error);
      }
    },

    async addGiftCard({ dispatch }, object) {
      try {
        const type = object.type
        if (type) {
          object.data.type = type
        }
        await axios.post(`giftCards`, object.data);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie dodano!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        if (type) {
          await dispatch("fetchGiftCards", {
            type
          });
        }
        else {
          await dispatch("fetchGiftCards");
        }

      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async updateGiftCard({ dispatch }, object) {
      try {
        const type = object.type
        await axios.put(`giftCards/${object.giftCardId}`, object.data);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Edycja przebiegła pomyślnie!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        if (type) {
          await dispatch("fetchGiftCards", {
            type
          });
        }
        else {
          await dispatch("fetchGiftCards");
        }

      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async deleteGiftCard({ dispatch }, data) {
      try {
        const type = data.type
        await axios.delete(`giftCards/${data.id}`);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie usunięto!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        if (type === 'FOR-BEGINNERS') {
          await dispatch("fetchGiftCards", {
            type: type
          });
        } else {
          await dispatch("fetchGiftCards");
        }

      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
  },

  getters: {
    getGiftCardForPayment: state => state.giftCardForPayment,
    getAllGiftCards: state => state.giftCards
  },
};
export default payment;
