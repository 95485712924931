<template>
  <v-card width="100%">
    <management-nav />
    <v-card-title>
      Dostępne warsztaty Anatomia w praktyce
      <v-spacer></v-spacer>
      <v-text-field v-model="search" label="Szukaj" single-line hide-details
        ><font-awesome-icon
          icon="search"
          slot="prepend-inner"
          class="mr-1 mt-1"
      /></v-text-field>
    </v-card-title>
    <v-data-table
      :loading="!items"
      loading-text="Trwa pobieranie danych..."
      :headers="headers"
      :items="items"
      :search="search"
    >
      <template v-slot:no-data>
        <v-alert :value="items && items.length === 0">
          Brak warsztatów Anatomia w praktyce
        </v-alert>
      </template>
      <template v-slot:[`item.endDate`]="{ item }">
        <p style="text-align: center">
          {{ item.endDate ? formatDate(item.endDate) : "Brak" }}
        </p>
        <v-btn @click="editEndDate(item)" class="mt-4"> Edytuj </v-btn>
      </template>

      <template v-slot:[`item.active`]="{ item }">
        <p style="text-align: center">{{ item.active ? "Tak" : "Nie" }}</p>
        <v-btn
          style="font-size: 12px; width: 100px"
          @click="markStatusAsActiveOrInactive(item)"
        >
          {{ item.active ? "Dezaktywuj" : "Aktywuj" }}
        </v-btn>
      </template>

      <template v-slot:[`item.members`]="{ item }">
        <div class="d-flex flex-column">
          <v-btn @click="showMembers(item)">
            Pokaż ({{ item.members.length }})
          </v-btn>

          <v-btn @click="showAddEmailModal(item.id)" class="mt-4">
            Dodaj
          </v-btn>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="green"
              @click="editWorkshop(item)"
              v-bind="attrs"
              v-on="on"
              v-on:click="isModalVisible = true"
            >
              <font-awesome-icon icon="edit" />
            </v-btn>
          </template>
          <span>Edytuj</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="red"
              @click="(isDeleteModalVisible = true), (packageData = item)"
              v-bind="attrs"
              v-on="on"
            >
              <font-awesome-icon icon="times" />
            </v-btn>
          </template>
          <span>Usuń</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="orange"
              @click="copyWorkshop(item)"
              v-bind="attrs"
              v-on="on"
              v-on:click="isModalVisible = true"
            >
              <font-awesome-icon icon="copy" />
            </v-btn>
          </template>
          <span>Kopiuj</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="#11A8F5"
              @click="openDuplicateModal(item)"
              v-bind="attrs"
              v-on="on"
            >
              <font-awesome-icon icon="clone" />
            </v-btn>
          </template>
          <span>Duplikuj</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <template>
          <p
            style="max-height: 200px; min-width: 300px; overflow: hidden"
            :id="item.id"
            v-html="htmlToFormattedText(item.description)"
          />
        </template>
      </template>
    </v-data-table>
    <AddTheoryPracticeWorkshopModal
      :isVisible="isModalVisible"
      :workshopItemData="workshopData"
      @closeModal="closeModal()"
      @updated="refreshWorkshops()"
    />
    <TheoryPracticeWorkshopMembersModal
      :isVisible="isWorkshopMembersModalVisible"
      :members="workshopMembers"
      @closeModal="closeWorkshopMembersModal()"
      @memberDeleted="refreshWorkshops()"
    />
    <AddEmailModal
      :label="'Dodaj użytkownika do warsztatów'"
      @confirm="addMemberToTheoryPracticeWorkshop"
      @closeModal="closeAddEmailModal()"
      :isVisible="addEmailModalVisible"
    />
    <select-date-modal
      :isVisible="isSelectDateModalVisible"
      :currentDate="currentDate"
      @closeModal="closeSelectDateModal()"
      @dateSelected="handleEditDate"
    />
    <before-delete-modal
      :isVisible="isDeleteModalVisible"
      @closeModal="closeDeleteModal()"
      @confirmDeletion="confirmDelete(packageData.id)"
    />
    <confirm-modal
      :isVisible="duplicateModalVisible"
      @closeModal="duplicateModalVisible = false"
      @confirm="confirmDuplicateWorkshop()"
      :title="`Czy na pewno chcesz stworzyć kopię warsztatu?`"
      :confirmText="'Tak'"
    />
    <v-btn
      fab
      dark
      large
      color="green"
      fixed
      left
      bottom
      v-on:click="isModalVisible = true"
    >
      <font-awesome-icon icon="plus" />
    </v-btn>
  </v-card>
</template>

<script>
import AddTheoryPracticeWorkshopModal from "../components/modals/AddTheoryPracticeWorkshopModal";
import ManagementNav from "../components/ManagementNav.vue";
import TheoryPracticeWorkshopMembersModal from "../components/modals/TheoryPracticeWorkshopMembersModal";
import { SERVER_URL } from "../axios";
import AddEmailModal from "../components/modals/AddEmailModal.vue";
import BeforeDeleteModal from "../components/modals/BeforeDeleteModal.vue";
import moment from "moment";
import SelectDateModal from "../components/modals/SelectDateModal.vue";
import ConfirmModal from "../components/modals/ConfirmModal.vue";
export default {
  name: "TheoryPracticeWorkshopsManagement",
  components: {
    AddTheoryPracticeWorkshopModal,
    ManagementNav,
    TheoryPracticeWorkshopMembersModal,
    AddEmailModal,
    BeforeDeleteModal,
    SelectDateModal,
    ConfirmModal,
  },
  async created() {
    await this.$store.dispatch(
      "theoryPracticeWorkshops/fetchTheoryPracticeWorkshopWithNotActive"
    );
    this.loadImages();
  },
  data: () => ({
    headers: [
      { text: "Aktywny?", value: "active" },
      { text: "Nazwa warsztatu", value: "name" },
      { text: "Data automatycznego zakończenia", value: "endDate" },
      { text: "Link do płatności", value: "paymentLink" },
      { text: "Link do zapisów", value: "signupLink" },
      { text: "Link do kursów", value: "coursesLink" },
      { text: "Lista uczestników", value: "members" },
      { text: "Opis warsztatu", value: "description" },
      { text: "Cena", value: "price" },
      { text: "Maksymalna liczba uczestników", value: "maxNumberOfMembers" },

      { text: "Akcje", value: "actions", sortable: false },
    ],
    search: "",
    isModalVisible: false,
    isWorkshopMembersModalVisible: false,
    workshopData: null,
    workshopMembers: [],
    currentDate: null,
    isSelectDateModalVisible: false,
    workshopsIdToEdit: "",
    addEmailModalVisible: false,
    isDeleteModalVisible: false,
    duplicateModalVisible: false,
    selectedWorkshopToDuplicate: null,
  }),
  computed: {
    items() {
      return this.$store.getters[
        "theoryPracticeWorkshops/getTheoryPracticeWorkshops"
      ];
    },
  },
  methods: {
    async confirmDuplicateWorkshop() {
      await this.$store.dispatch("theoryPracticeWorkshops/duplicateWorkshop", {
        id: this.selectedWorkshopToDuplicate.id,
      });
      await this.refreshWorkshops();
    },
    openDuplicateModal(workshop) {
      this.selectedWorkshopToDuplicate = workshop;
      this.duplicateModalVisible = true;
    },
    async handleEditDate(e, date) {
      await this.$store.dispatch(
        "theoryPracticeWorkshops/editTheoryPracticeWorkshopEndDate",
        {
          id: this.workshopsIdToEdit,
          endDate: moment(date).endOf("day").toDate(),
        }
      );
      this.refreshWorkshops();
      this.closeSelectDateModal();
    },

    closeSelectDateModal() {
      this.isSelectDateModalVisible = false;
      this.currentDate = moment().format("YYYY-MM-DD");
      this.workshopsIdToEdit = "";
    },
    editEndDate(workshop) {
      const endDate = workshop.endDate;
      this.isSelectDateModalVisible = true;
      this.currentDate = endDate
        ? moment(endDate).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");
      this.workshopsIdToEdit = workshop.id;
    },
    formatDate(date) {
      if (date) {
        return moment(date).format("YYYY-MM-DD");
      }
      return "Brak";
    },
    confirmDelete(id) {
      this.deletePackage(id);
    },
    closeDeleteModal() {
      this.isDeleteModalVisible = false;
      this.packageData = null;
    },
    showAddEmailModal(id) {
      this.workshopsIdToEdit = id;
      this.addEmailModalVisible = true;
    },
    async addMemberToTheoryPracticeWorkshop(email) {
      const response = await this.$store.dispatch(
        "theoryPracticeWorkshops/adminAddUserToTheoryPracticeWorkshop",
        {
          id: this.workshopsIdToEdit,
          email,
        }
      );
      if (response) {
        this.closeAddEmailModal();
      }
    },
    async loadImages() {
      this.items.forEach(async (item) => {
        this.loadImage(item);
      });
    },

    async loadImage(item) {
      const element = document.getElementById(
        `theoryPracticeWorkshopImage_${item.id}`
      );
      if (element) {
        if (item.imagePath) {
          const extension = item.imagePath?.split(".")[1];

          element.src = `${SERVER_URL}theoryPracticeWorkshop_${
            item.id
          }.${extension}?d=${new Date().getTime()}`;
        }
      }
    },

    async markStatusAsActiveOrInactive(item) {
      if (!item.active) {
        await this.$store.dispatch(
          "theoryPracticeWorkshops/markTheoryPracticeWorkshopAsActive",
          { id: item.id }
        );
      } else {
        await this.$store.dispatch(
          "theoryPracticeWorkshops/markTheoryPracticeWorkshopAsNotActive",
          { id: item.id }
        );
      }
    },

    async refreshWorkshops() {
      await this.$store.dispatch(
        "theoryPracticeWorkshops/fetchTheoryPracticeWorkshopWithNotActive"
      );
      this.loadImages();
    },
    copyWorkshop(data) {
      this.workshopData = data;
      this.workshopData.id = null;
      this.workshopData.copy = true;
    },
    showMembers(item) {
      this.workshopMembers = item.members;
      this.isWorkshopMembersModalVisible = true;
    },
    htmlToFormattedText(text) {
      const regex =
        /((http|https|ftp|ftps):\/\/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g;
      text = text.replace(regex, '<a href="$1">$1</a>');
      return text;
    },
    editWorkshop(data) {
      this.workshopData = data;
    },
    async deletePackage(id) {
      await this.$store.dispatch(
        "theoryPracticeWorkshops/deleteTheoryPracticeWorkshop",
        { id }
      );
    },
    closeModal() {
      this.isModalVisible = false;
      this.workshopData = null;
    },
    closeWorkshopMembersModal() {
      this.isWorkshopMembersModalVisible = false;
    },
    closeAddEmailModal() {
      this.addEmailModalVisible = false;
      this.workshopsIdToEdit = "";
    },
  },
};
</script>

<style scoped>
.item-image {
  width: 130px;
  height: 130px;
  border-radius: 1rem;
  margin: 1rem;
}
</style>
