<template>
  <div class="mb-12 mt-12">
    <v-container class="mb-6">
      <v-row align="start" justify="center">
        <v-col cols="12" md="6" class="image">
          <img
            :id="workshopItem.id"
            alt="
            Nauli,
            anatomia jogi.
            kręgosłup,
            joga wzmacniająca,
            kurs jogi online,
            joga online,
            filmy jogi,
            techniki oddechowe,
            joga balanse,
            joga video,
            uważna praktyka,
            praktyka dla początkujących
            "
            style="position: relative"
            loading="lazy"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12" class="title text-h5 content">
              <p class="workshop-item-name">{{ workshopItem.name }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="content">
              <p
                class="description"
                v-html="
                  workshopItem.description.length < 250
                    ? htmlToFormattedText(workshopItem.description)
                    : htmlToFormattedText(
                        workshopItem.description.substring(0, 250) + '...'
                      )
                "
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <span class="watch-button" @click="redirect">Oglądaj</span>
      <span class="line"></span>
    </v-container>
  </div>
</template>

<script>
import { SERVER_URL } from "../axios";
export default {
  name: "WorkshopItem",
  props: {
    workshop: Object,
    workshopItem: Object,
  },

  watch: {
    workshopItem(item) {
      if (item) {
        this.loadImage(item);
      }
    },
  },
  mounted() {
    if (this.workshopItem) {
      this.loadImage(this.workshopItem);
    }
  },
  methods: {
    htmlToFormattedText(text) {
      const regex =
        /((http|https|ftp|ftps):\/\/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g;
      text = text.replace(regex, '<a href="$1">$1</a>');
      return text;
    },
    async loadImage(workshopItem) {
      const element = document.getElementById(workshopItem.id);
      if (element) {
        if (workshopItem.imagePath) {
          const extension = workshopItem.imagePath?.split(".")[1];
          element.src = `${SERVER_URL}${workshopItem.id}.${extension}`;
        }
      }
    },
    redirect() {
      this.$store.dispatch("videos/setSelectedVideo", {
        video: this.workshopItem,
      });
      window.localStorage.setItem(
        "selected-workshops-id",
        this.workshopItem.timePackageId
      );
      this.$router.push({
        name: "WorkshopsVideos",
        params: {
          course: this.workshopItem,
          path: "warsztaty",
        },
      });
    },
  },
};
</script>

<style scoped>
.image img {
  border-radius: 5px;
  margin: 0rem 2rem;
  width: 100%;
}
.image {
  max-width: 400px;
  display: flex;
  justify-content: center;
}
.content {
  margin: 0 2rem;
  text-align: left;
  max-height: 200px;
}
.title p {
  margin-bottom: 0;
}

.line {
  width: 100%;
  height: 1px;
  background: gray;
  margin-bottom: 3rem;
  opacity: 30%;
}
.watch-button {
  color: #fff;
  background: linear-gradient(270deg, #c4a2a2 0%, #b28a8a 90.06%);
  border-radius: 10px;
  padding: 0.6rem 1.2rem;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 400;
  width: 80%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 3rem;
  cursor: pointer;
}
@media screen and (max-width: 960px) {
  .content {
    text-align: center;
    margin: 0;
  }
}

@media screen and (max-width: 360px) {
  .image {
    width: 260px;
  }
}
</style>
