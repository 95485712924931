<template>
  <v-row justify="center">
    <v-dialog
      max-width="700px"
      :value="isVisible"
      persistent
      @keydown.esc="closeModal"
    >
      <v-card>
        <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager">
          <v-card-title>
            <span class="headline"> Dodaj warsztat Anatomia w praktyce</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    type="text"
                    rules="required"
                    v-model="name"
                    label="Nazwa"
                    color="indigo"
                    outlined
                    dense
                    maxLength="255"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextAreaWithValidation
                    type="text"
                    rules="required"
                    v-model="description"
                    label="Opis"
                    color="indigo"
                    outlined
                    dense
                    maxLength="2048"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" style="padding-top: 0; padding-bottom: 0">
                  <p>Data automatycznego zakończenia</p>
                  <v-date-picker
                    full-width
                    v-model="endDate"
                    label="Data"
                    color="indigo"
                    outlined
                    dense
                    rules="required"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    v-model="price"
                    label="Cena"
                    color="indigo"
                    outlined
                    dense
                    :onlyNumbers="true"
                    rules="required"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    v-model="maxNumberOfMembers"
                    label="Liczba uczestników"
                    color="indigo"
                    outlined
                    dense
                    :onlyNumbers="true"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text v-on:click="closeModal">
              <font-awesome-icon icon="times" class="mr-1" />
              Anuluj
            </v-btn>
            <v-btn
              color="success"
              text
              :disabled="invalid"
              v-on:click="addWorkshopItem"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              Zapisz zmiany
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "../inputs/VTextFieldWithValidation";
import VTextAreaWithValidation from "../inputs/VTextAreaWithValidation.vue";
import moment from "moment";
export default {
  name: "AddTheoryPracticeWorkshopModal",
  props: ["isVisible", "workshopItemData"],

  components: {
    ValidationObserver,
    VTextFieldWithValidation,
    VTextAreaWithValidation,
  },

  data: () => ({
    workshopId: null,
    input: "",
    discountError: false,
    updated: false,
    name: "",
    description: "",
    maxNumberOfMembers: 30,
    price: null,
    endDate: null,
    isSelectDateModalVisible: false,
  }),
  updated() {
    if (this.workshopItemData) {
      const { id, name, description, maxNumberOfMembers, price, endDate } =
        this.workshopItemData;
      this.workshopItemId = id;
      this.updated = this.workshopItemId ? true : false;
      this.name = name;
      this.description = description;
      this.maxNumberOfMembers = maxNumberOfMembers;
      this.price = price;
      this.endDate = endDate ? moment(endDate).format("YYYY-MM-DD") : null;
    }
    if (!this.endDate) {
      this.endDate = moment().format("YYYY-MM-DD");
    }
  },

  watch: {
    endDate() {
      this.endDate = moment(this.endDate).format("YYYY-MM-DD");
    },
  },

  async created() {},

  methods: {
    closeSelectDateModal() {
      this.isSelectDateModalVisible = false;
    },
    formatDate(date) {
      if (date) {
        return moment(date).format("YYYY-MM-DD");
      }
      return "Brak";
    },

    closeModal() {
      this.updated = false;
      this.name = "";
      this.description = "";
      this.maxNumberOfMembers = 30;
      this.price = null;
      this.endDate = null;
      this.$emit("closeModal");
    },
    async addWorkshopItem() {
      if (!this.endDate) {
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "red",
          message: "Wybierz datę zakończenia warsztatów",
        });
        return;
      }

      let object = {
        name: this.name,
        description: this.description,
        maxNumberOfMembers: this.maxNumberOfMembers,
        price: this.price,
        endDate: this.endDate,
      };
      if (this.updated) {
        await this.$store
          .dispatch("theoryPracticeWorkshops/editTheoryPracticeWorkshop", {
            id: this.workshopItemId,
            data: object,
          })
          .then(() => {
            this.closeModal();
            this.$emit("updated");
          });
      } else {
        await this.$store
          .dispatch("theoryPracticeWorkshops/createTheoryPracticeWorkshop", {
            data: object,
          })
          .then(() => {
            this.closeModal();
            this.$emit("updated");
          });
      }
    },
  },

  computed: {},
};
</script>
<style type="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
