<template>
  <v-card width="100%">
    <management-nav />
    <v-card-title>
      Podkategorie nr 2 subskrypcji DagaJoga Początek
      <v-spacer></v-spacer>
      <v-text-field v-model="search" label="Szukaj" single-line hide-details
        ><font-awesome-icon icon="search" slot="prepend-inner" class="mt-1 mr-1"
      /></v-text-field>
    </v-card-title>
    <v-data-table
      :loading="!items"
      loading-text="Trwa pobieranie danych..."
      :headers="headers"
      :items="items"
      :search="search"
    >
      <template v-slot:no-data>
        <v-alert :value="items && items.length === 0">
          Brak podkategorii w subskrypcjach miesięcznych
        </v-alert>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="green"
              @click="editSubCategory(item)"
              v-bind="attrs"
              v-on="on"
              v-on:click="isModalVisible = true"
            >
              <font-awesome-icon icon="edit" />
            </v-btn>
          </template>
          <span>Edytuj</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="red"
              v-bind="attrs"
              v-on="on"
              v-on:click="(isDeleteModalVisible = true), (currentItem = item)"
            >
              <font-awesome-icon icon="times" />
            </v-btn>
          </template>
          <span>Usuń</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.photo`]="{ item }">
        <template>
          <img
            class="item-image"
            :id="item.imagePath"
            @click="editSubCategory(item)"
            v-bind="attrs"
            v-on="on"
            v-on:click="isModalVisible = true"
            alt="
              joga zdrowy,
              filmy jogi,
              techniki oddechowe,
              praktyka wzmacniająca,
              joga online,
              praktyka dla początkujących,
              szkoła online,
              prana vyavama,
              joga wzmacniająca,
              joga video,
              ćwiczenia online,
              uważna praktyka,
              anatomia jogi
              "
            loading="lazy"
          />
        </template>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <template>
          <p
            :id="item.id"
            v-bind="attrs"
            v-on="on"
            v-html="htmlToFormattedText(item.name)"
          />
        </template>
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <template>
          <p
            :id="item.id"
            v-bind="attrs"
            v-on="on"
            v-html="htmlToFormattedText(item.description)"
          />
        </template>
      </template>
    </v-data-table>
    <add-sub-category-modal
      :isVisible="isModalVisible"
      :movieSubCategoryData="movieSubCategoryData"
      @closeModal="closeModal()"
      @updated="timePackageUpdated()"
      :level="2"
      :type="'FOR-BEGINNERS'"
    />
    <BeforeDeleteModal
      :isVisible="isDeleteModalVisible"
      @closeModal="closeDeleteModal()"
      @confirmDeletion="confirm(currentItem.id)"
    />
    <v-btn
      fab
      dark
      large
      color="green"
      fixed
      left
      bottom
      v-on:click="isModalVisible = true"
    >
      <font-awesome-icon icon="plus" />
    </v-btn>
  </v-card>
</template>

<script>
import ManagementNav from "../components/ManagementNav.vue";
import BeforeDeleteModal from "../components/modals/BeforeDeleteModal.vue";
import AddSubCategoryModal from "../components/modals/AddSubCategoryModal.vue";
import { SERVER_URL } from "../axios";

export default {
  name: "MovieSubcategoriesManagement",
  components: {
    ManagementNav,
    BeforeDeleteModal,
    AddSubCategoryModal,
  },

  async mounted() {
    await this.$store.dispatch("movieSubCategories/fetchAllSubcategoriesL2", {
      forBeginners: true,
    });
    this.loadImages();
  },
  data: () => ({
    headers: [
      { text: "Nazwa podkategorii", value: "name" },
      { text: "Opis", value: "description" },
      { text: "Podkategoria", value: "movie_sub_category.name" },
      { text: "Zdjęcie", value: "photo" },
      { text: "Akcje", value: "actions", sortable: false },
    ],
    search: "",
    isModalVisible: false,
    isDeleteModalVisible: false,
    movieSubCategoryData: null,
    currentItem: null,
  }),
  computed: {
    items() {
      return this.$store.getters["movieSubCategories/getMovieSubCategoriesL2"];
    },
  },

  watch: {
    search() {
      setTimeout(() => {
        this.loadImages();
      }, 10);
    },
  },
  methods: {
    htmlToFormattedText(text) {
      const regex = /((http|https|ftp|ftps):\/\/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g;
      text = text.replace(regex, '<a href="$1">$1</a>');
      return text;
    },
    async timePackageUpdated() {
      if (this.type) {
        await this.$store.dispatch(
          "movieSubCategories/fetchAllSubcategoriesL2",
          {
            type: "FOR-BEGINNERS",
            forBeginners: true,
          }
        );
      } else {
        await this.$store.dispatch(
          "movieSubCategories/fetchAllSubcategoriesL2",
          {
            type: "FOR-BEGINNERS",
            forBeginners: true,
          }
        );
      }

      this.loadImages();
    },
    async loadImages() {
      this.items.forEach(async (item) => {
        this.loadImage(item);
      });
    },

    async loadImage(subCategory) {
      const element = document.getElementById(subCategory.imagePath);
      if (element) {
        if (subCategory.imagePath) {
          const extension = subCategory.imagePath.split(".")[1];
          element.src = `${SERVER_URL}${
            subCategory.id
          }.${extension}?${new Date().getTime()}`;
        }
      }
    },

    editSubCategory(data) {
      this.movieSubCategoryData = data;
    },
    async deleteSubCategory(id) {
      await this.$store.dispatch(
        "movieSubCategories/deleteMovieSubCategoryL2",
        {
          id,
          forBeginners: true,
        }
      );
      this.timePackageUpdated();
    },

    confirm(id) {
      this.deleteSubCategory(id);
    },

    closeModal() {
      this.isModalVisible = false;
      this.movieSubCategoryData = null;
      this.currentItem = null;
    },
    closeDeleteModal() {
      this.isDeleteModalVisible = false;
      this.movieSubCategoryData = null;
      this.currentItem = null;
    },
  },
};
</script>

<style scoped>
.item-image {
  width: 130px;
  height: 130px;
  border-radius: 1rem;
  margin: 1rem;
}
</style>
