<template>
  <v-card width="100%">
    <management-nav />
    <v-card-title>
      Sklep - kody rabatowe
      <v-spacer></v-spacer>
    </v-card-title>

    <v-row class="ml-1">
      <v-col cols="12">
        <v-row
          class="pb-0"
          v-for="(discount, index) in discounts"
          :key="`codeValue${index}`"
        >
          <v-col cols="12" sm="5">
            <v-text-field-with-validation
              type="number"
              v-model.number="discount.value"
              label="Wartość zniżki w procentach"
              color="indigo"
              outlined
              dense
              rules="min_value:0.01"
              @newValueAfterChange="handleNewDiscountValue($event, index)"
            />
          </v-col>

          <v-col cols="12" sm="5">
            <v-text-field-with-validation
              v-model="discount.code"
              label="Kod zniżkowy"
              color="indigo"
              outlined
              dense
            />
          </v-col>

          <v-col cols="12" sm="2">
            <v-btn
              @click="removeDiscount(discount, index)"
              class="ml-2"
              fab
              x-small
              color="red"
            >
              <font-awesome-icon icon="minus" />
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="ml-1 mb-4">
      <v-col>
        <v-btn @click="addNextDiscount" color="orange">
          Dodaj kolejną zniżkę
          <font-awesome-icon icon="plus" />
        </v-btn>
      </v-col>
    </v-row>

    <v-btn class="ml-4" style="width: 100%" @click="saveDiscounts" color="green"
      >Zapisz</v-btn
    >
  </v-card>
</template>

<script>
import VTextFieldWithValidation from "../components/inputs/VTextFieldWithValidation.vue";
import ManagementNav from "../components/ManagementNav.vue";
export default {
  name: "ShopProductsDiscountsManagement",
  components: {
    ManagementNav,
    VTextFieldWithValidation,
  },
  async created() {
    await this.$store.dispatch("discountCode/fetchDiscountCodes", {
      type: "SHOP-PRODUCTS",
    });
  },
  data: () => ({
    discounts: [],
    discountsToRemove: [],
  }),
  computed: {
    discountCodes() {
      return this.$store.getters["discountCode/getAllDiscountCodes"];
    },
  },

  watch: {
    discountCodes() {
      this.discounts = this.discountCodes.map((discount) => {
        return {
          id: discount.id,
          code: discount.code,
          value: discount.value,
        };
      });
    },
  },
  methods: {
    async saveDiscounts() {
      let isEmptyCode = false;
      const codesArray = this.discounts.map(function (item) {
        if (!item.value || !item.code) {
          isEmptyCode = true;
        }
        return item.code;
      });

      let moreThanOneSameCodes = codesArray.some(function (item, idx) {
        return codesArray.indexOf(item) != idx;
      });
      if (isEmptyCode) {
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "red",
          message: "Kody rabatowe oraz wartości zniżek muszą być uzupełnione",
        });
        return;
      }
      if (moreThanOneSameCodes) {
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "red",
          message: "Wszystkie kody zniżkowe muszą być unikalne",
        });
        return;
      }

      await this.handleRemoveDiscountCodes();
      await this.handleAddAndUpdateDiscountCodes();
    },
    handleNewDiscountValue(value, index) {
      if (value && Number(value) > 1) {
        this.discounts[index].value = 0.99;
      }
    },
    addNextDiscount() {
      this.discounts.push({
        code: "",
        value: 0.0,
      });
    },
    removeDiscount(discount, index) {
      if (discount.id) {
        this.discountsToRemove.push(discount);
      }
      this.discounts.splice(index, 1);
    },

    async handleRemoveDiscountCodes() {
      if (this.discountsToRemove && this.discountsToRemove.length > 0) {
        for await (const discount of this.discountsToRemove) {
          await this.$store.dispatch("discountCode/deleteDiscountCode", {
            id: discount.id,
          });
        }
      }
    },

    async handleAddAndUpdateDiscountCodes() {
      if (this.discounts) {
        for await (const discount of this.discounts) {
          const { id, code, value } = discount;
          if (!id) {
            await this.$store.dispatch("discountCode/addDiscountCode", {
              data: {
                type: "SHOP-PRODUCTS",
                code: code,
                value,
              },
            });
          } else {
            await this.$store.dispatch("discountCode/updateDiscountCode", {
              discountCodeId: id,
              data: {
                type: "SHOP-PRODUCTS",
                code: code,
                value,
              },
            });
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.item-image {
  width: 130px;
  height: 130px;
  border-radius: 1rem;
  margin: 1rem;
  cursor: pointer;
  object-fit: cover;
}
.image-container {
  position: relative;
}
.slider-prev,
.slider-next {
  position: absolute;
  top: 40%;
  font-weight: lighter;
  color: #000;
  background: rgb(255, 255, 255, 0.75);
  cursor: pointer;
  border-radius: 100px;
  padding: 0 5px;
  font-size: 20px;
}
.slider-prev {
  left: 1.3rem;
}
.slider-next {
  right: 1.3rem;
}
.delete-image-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
</style>
