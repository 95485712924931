<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex xs12 sm6 offset-sm3 mt-6 class="transparent-bg">
        <h1 class="text-center">
          Dziękujemy za skorzystanie z naszego serwisu
        </h1>
        <blockquote class="blockquote text-center">
          Jeśli płatność przebiegła pomyślnie, informacje dotyczące wykupionego
          nagrania prześlemy na podany adres e-mail
        </blockquote>
        <blockquote class="blockquote text-center">
          <button @click="moveToHomePage" class="primary-button">
            Strona główna
          </button>
        </blockquote>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import config from "../config";
export default {
  metaInfo: {
    title: "Dagajoga kurs online - portal jogi online",
  },
  data: function () {
    return {
      homepage: config.HOMEPAGE,
    };
  },
  methods: {
    moveToHomePage() {
      window.open(this.homepage, "_self");
    },
  },
};
</script>

<style scoped>
.transparent-bg {
  padding: 50px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.664);
}
i {
  font-size: 120px;
}
.primary-button {
  text-decoration: none;
  margin: 0 auto;
}
</style>
