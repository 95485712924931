import axios from "../axios";

const users = {
  namespaced: true,

  state: () => ({
    users: [],
    userSchedule: [],
    adminScheduleStandard: [],
    adminScheduleForBeginners: []
  }),
  mutations: {
    SET_USERS(state, users) {
      state.users = users;
    },
    SET_USER_SCHEDULE(state, schedule) {
      state.userSchedule = schedule
    },
    SET_ADMIN_SCHEDULE_STANDARD(state, schedule) {
      state.adminScheduleStandard = schedule
    },
    SET_ADMIN_SCHEDULE_FOR_BEGINNERS(state, schedule) {
      state.adminScheduleForBeginners = schedule
    },
  },
  actions: {
    fetchAll: async ({ commit }) => {
      try {
        const response = await axios.get(`management/users`);
        commit("SET_USERS", response.data);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },

    fetchUserSchedule: async ({ commit }, data) => {
      try {
        const { type } = data
        const params = {
          type: type || 'STANDARD'
        }
        const response = await axios.get(`schedules`, { params });
        commit("SET_USER_SCHEDULE", response.data);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },

    fetchAdminSchedule: async ({ commit }, data) => {
      try {
        const { type } = data
        const params = {
          type: type || 'STANDARD'
        }
        const response = await axios.get(`schedules/admin`, { params });
        if (type === 'FOR-BEGINNERS') {
          commit("SET_ADMIN_SCHEDULE_FOR_BEGINNERS", response.data);
        } else {
          commit("SET_ADMIN_SCHEDULE_STANDARD", response.data);
        }

        return response.data;
      } catch (error) {
        console.log(error);
      }
    },

    resetSchedules: async ({ commit }) => {
      commit("SET_USER_SCHEDULE", []);
      commit("SET_ADMIN_SCHEDULE_FOR_BEGINNERS", []);
      commit("SET_ADMIN_SCHEDULE_STANDARD", []);
    },

    addVideoToUserSchedule: async ({ dispatch }, data) => {
      await axios.put(`schedules`, {
        ...data
      })

      if (data.type === 'FOR-BEGINNERS') {
        dispatch("fetchAdminSchedule", { type: 'FOR-BEGINNERS' })
        dispatch("fetchUserSchedule", { type: 'FOR-BEGINNERS' })
      }
      else {
        dispatch("fetchAdminSchedule", {})
        dispatch("fetchUserSchedule", {})
      }


    }

  },
  getters: {
    getUsers: (state) => state.users,
    getUserSchedule: state => state.userSchedule,
    getAdminScheduleStandard: state => state.adminScheduleStandard,
    getAdminScheduleForBeginners: state => state.adminScheduleForBeginners
  },
};
export default users;
