<template>
  <button
    class="button"
    :class="
      classes
        ? `${classes} ${withoutBackground ? 'without-background' : ''}`
        : `${withoutBackground ? 'without-background' : ''}`
    "
    :style="`${width ? `width: ${width}` : ``}`"
    @click="handleClick"
  >
    {{ label }}
  </button>
</template>

<script>
export default {
  props: ["label", "width", "height", "withoutBackground", "type", "classes"],
  emits: ["clicked"],
  data() {
    return {};
  },
  methods: {
    handleClick() {
      this.$emit("clicked");
    },
  },
};
</script>

<style scoped>
.button {
  border-radius: 50px;
  /* background: rgb(167, 214, 253); */
  background: #d1acac;
  height: 35px;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  text-transform: uppercase;
  width: 200px;
  cursor: pointer;
}
.withLetterSpacing {
  letter-spacing: 0.0892857143em;
}
.button:hover {
  /* background: rgb(139, 185, 223); */
  background: #d19d9d;
}

.without-background {
  background: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.without-background:hover {
  background: rgba(209, 172, 172, 0.5);
}
</style>
