<template>
  <div class="schedule-item">
    <p class="day-name label">{{ translateDay(scheduleDay.day) }}</p>
    <p class="item-name label">
      {{ scheduleDay.timePackageItem.name }}
    </p>
    <img
      v-if="scheduleDay"
      @click="moveToCourse(scheduleDay)"
      :id="`${scheduleDay.id}`"
      alt=""
      style="position: relative"
      loading="lazy"
    />
    <font-awesome-icon
      v-if="allowedToRemove"
      @click="openRemoveScheduleDayModal"
      class="remove-icon"
      color="red"
      icon="times"
    />
    <before-delete-modal
      @closeModal="isDeleteModalVisible = false"
      :isVisible="isDeleteModalVisible"
      @confirmDeletion="deleteScheduleDay"
    />
  </div>
</template>

<script>
import axios from "../axios";
import { SERVER_URL } from "../axios";
import BeforeDeleteModal from "./modals/BeforeDeleteModal.vue";
export default {
  props: ["scheduleDay", "isAdminScheduleItem"],
  name: "ScheduleItem",
  components: { BeforeDeleteModal },
  data() {
    return {
      isLoading: false,
      isDeleteModalVisible: false,
    };
  },
  async mounted() {
    if (this.scheduleDay && this.scheduleDay.timePackageItem) {
      await this.loadImage(this.scheduleDay);
    }
  },
  methods: {
    async deleteScheduleDay() {
      await this.$store.dispatch("users/addVideoToUserSchedule", {
        timePackageItemId: null,
        type: this.scheduleDay.type,
        day: this.scheduleDay.day,
      });
    },

    async openRemoveScheduleDayModal() {
      this.isDeleteModalVisible = true;
    },

    async loadImage(scheduleDay) {
      const element = document.getElementById(`${scheduleDay.id}`);
      if (element) {
        if (scheduleDay.timePackageItem.imagePath) {
          const extension =
            scheduleDay.timePackageItem.imagePath?.split(".")[1];
          element.src = `${SERVER_URL}${scheduleDay.timePackageItem.id}.${extension}`;
        }
      }
    },

    moveToCourse(scheduleDay) {
      const currentRouteName = this.$route.name;
      if (
        currentRouteName !== "Courses" &&
        currentRouteName !== "CoursesForBeginners"
      ) {
        return;
      }
      const timePackageItem = scheduleDay.timePackageItem;
      window.localStorage.setItem(
        "selectedCourse",
        this.isAdminScheduleItem ? "admin-schedules" : `user-schedules`
      );
      axios.post(`timePackageItems/${timePackageItem.id}/viewsCounter`);
      this.$store.dispatch("videos/setSelectedVideo", {
        timePackageItem,
      });
      this.$router.push({
        name: "VideosDemo",
        params: {
          course: timePackageItem,
          path:
            currentRouteName === "CoursesForBeginners"
              ? "subskrypcja-podstawy"
              : "subskrypcja",
        },
        query: {
          id: `${timePackageItem.name.replaceAll(" ", "-")}_${
            timePackageItem.id
          }`,
        },
      });
    },

    translateDay(dayName) {
      switch (dayName) {
        case "MONDAY":
          return "Poniedziałek";
        case "TUESDAY":
          return "Wtorek";
        case "WEDNESDAY":
          return "Środa";
        case "THURSDAY":
          return "Czwartek";
        case "FRIDAY":
          return "Piątek";
        case "SATURDAY":
          return "Sobota";
        case "SUNDAY":
          return "Niedziela";
        default:
          return "";
      }
    },
  },
  watch: {
    async scheduleDay(scheduleDay) {
      this.isLoading = true;
      if (scheduleDay.timePackageItem) {
        await this.loadImage(scheduleDay);
      }
      setTimeout(() => {
        this.isLoading = false;
      }, 50);
    },
  },

  computed: {
    allowedToRemove() {
      const currentRouteName = this.$route.name;
      if (
        (currentRouteName === "Courses" ||
          currentRouteName === "CoursesForBeginners") &&
        this.isAdminScheduleItem
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped>
.schedule-item {
  min-width: 183px;
  width: 183px;
  height: 157px;
  object-fit: cover;
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.5s ease;
  position: relative;
  padding: 0;
}
.label {
  position: absolute;
  z-index: 2;
  background: rgb(255, 255, 255, 0.75);
  width: 100%;
  margin: 0;
  font-weight: 500;
  padding: 0 3px;
}
.day-name {
  top: 0;
  left: 0;
  font-size: 14px;
}
.item-name {
  bottom: 0;
  left: 0;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
img {
  width: 100%;
  height: 100%;

  cursor: pointer;
}
img:hover {
  opacity: 0.75;
}
.remove-icon {
  position: absolute;
  top: 2px;
  right: 2px;
  opacity: 1;
  z-index: 3;
  width: 8px;
  cursor: pointer;
}
</style>