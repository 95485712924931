import axios from "../axios";

const theoryPracticeWorkshopsLessonsAttachments = {
  namespaced: true,

  state: () => ({
    theoryPracticeWorkshopsLessonsAttachments: [],
    updatedAttachment: null,
  }),

  mutations: {
    SET_THEORY_PRACTICE_WORKSHOPS_LESSONS_ATTACHMENTS(state, attachments) {
      state.theoryPracticeWorkshopsLessonsAttachments = attachments;
    },
    SET_THEORY_PRACTICE_WORKSHOP_LESSON_ATTACHMENT(state, updatedAttachment) {
      state.updatedAttachment = updatedAttachment;
    },
  },

  actions: {
    async fetchTheoryPracticeWorkshopsLessonsAttachments({ commit }, data) {
      const params = {};
      if (data && data.type) {
        params.type = data.type;
      }
      let url = "theoryPracticeWorkshopsLessons/attachments";
      if (data && data.theoryPracticeWorkshopsLessonId) {
        url = `theoryPracticeWorkshopsLessons/${data.theoryPracticeWorkshopsLessonId}/attachments`;
      }
      try {
        const response = await axios.get(url, { params });
        commit(
          "SET_THEORY_PRACTICE_WORKSHOPS_LESSONS_ATTACHMENTS",
          response.data
        );
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOPS_LESSONS_ATTACHMENTS", null);
        console.log(err);
      }
    },

    async createTheoryPracticeWorkshopLessonAttachment(
      { commit, dispatch },
      object
    ) {
      const { data, lessonId } = object;
      const formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }

      try {
        const response = await axios.post(
          `theoryPracticeWorkshopsLessons/${lessonId}/attachments`,
          formData
        );
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON_ATTACHMENT", response.data);
        await dispatch("fetchTheoryPracticeWorkshopsLessonsAttachments");
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON_ATTACHMENT", null);
        console.log(err);
      }
    },

    async editTheoryPracticeWorkshopLessonAttachment(
      { commit, dispatch },
      object
    ) {
      const { data, id, lessonId } = object;
      const formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }

      try {
        const response = await axios.put(
          `theoryPracticeWorkshopsLessons/${lessonId}/attachments/${id}`,
          formData
        );
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON_ATTACHMENT", response.data);
        await dispatch("fetchTheoryPracticeWorkshopsLessonsAttachments");
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON_ATTACHMENT", null);
        console.log(err);
      }
    },

    async deleteTheoryPracticeWorkshopLessonAttachment(
      { commit, dispatch },
      data
    ) {
      try {
        await axios.delete(
          `theoryPracticeWorkshopsLessons/${data.lessonId}/attachments/${data.id}`
        );
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON_ATTACHMENT", true);
        await dispatch("fetchTheoryPracticeWorkshopsLessonsAttachments", {
          withNotActive: true,
        });
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON_ATTACHMENT", false);
        console.log(err);
      }
    },
  },

  getters: {
    getTheoryPracticeWorkshopsLessonsAttachments: (state) =>
      state.theoryPracticeWorkshopsLessonsAttachments,
  },
};
export default theoryPracticeWorkshopsLessonsAttachments;
