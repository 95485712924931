import axios from "../axios";
import router from "../router";
import jwt_decode from "jwt-decode";

const auth = {
  namespaced: true,
  state: () => ({
    token: window.localStorage.getItem("token"),
    hasActiveSubscription: false,
    currentRoute: "",
    profile: null,
    passwordChange: false,
    profilePicture: "",
    hasActiveSubscriptionForBeginners: false,
    hasActiveSubscriptionExtended: false,
  }),

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_ACTIVE_SUBSCRIPTION(state, hasActiveSubscription) {
      state.hasActiveSubscription = hasActiveSubscription;
    },
    SET_ACTIVE_SUBSCRIPTION_FOR_BEGINNERS(state, hasActiveSubscription) {
      state.hasActiveSubscriptionForBeginners = hasActiveSubscription;
    },
    SET_CURRET_ROUTE(state, currentRoute) {
      state.currentRoute = currentRoute;
    },
    SET_USER_PROFILE(state, profile) {
      state.profile = profile;
    },
    SET_PASSWORD_CHANGE(state, passwordChange) {
      state.passwordChange = passwordChange;
    },
    SET_PROFILE_PICTURE(state, profilePicture) {
      state.profilePicture = profilePicture;
    },
    SET_ACTIVE_SUBSCRIPTION_EXTENDED(state, hasActiveSubscriptionExtended) {
      state.hasActiveSubscriptionExtended = hasActiveSubscriptionExtended;
    },
  },

  actions: {
    async login({ commit, dispatch }, object) {
      try {
        const response = await axios.post(
          "management/signin",
          {
            password: object.password,
            login: object.login,
          },
          { skipAuthRefresh: true }
        );
        const { accessToken, isAdmin } = response.data;
        if (!isAdmin) {
          dispatch(
            "snackbar/showSnackbar",
            {
              color: "red",
              message:
                "Podane dane logowania są nieprawidłowe! Spróbuj ponownie.",
            },
            { root: true }
          );
          return;
        }
        localStorage.setItem("token", accessToken);
        localStorage.removeItem("userToken");
        commit("SET_TOKEN", response.data.accessToken);
        router.push({ name: "Account" });
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${accessToken}`;
        dispatch(
          "snackbar/showSnackbar",
          { color: "red", message: "Zalogowano pomyślnie!" },
          { root: true }
        );
      } catch (error) {
        commit("SET_TOKEN", null);

        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");

        delete axios.defaults.headers.common["Authorization"];
        if (error.response.status === 404) {
          dispatch(
            "snackbar/showSnackbar",
            {
              color: "red",
              message:
                "Podane dane logowania są nieprawidłowe! Spróbuj ponownie.",
            },
            { root: true }
          );
          return;
        } else {
          dispatch(
            "snackbar/showSnackbar",
            {
              color: "red",
              message: `Wystąpił błąd podczas próby logowania. Spróbuj ponownie później!`,
            },
            { root: true }
          );
        }
      }
    },

    async loginUser({ commit, dispatch }, object) {
      try {
        const response = await axios.post(
          "/signin",
          {
            password: object.password,
            login: object.login,
          },
          { skipAuthRefresh: true }
        );
        const { accessToken, isAdmin } = response.data;
        commit("SET_TOKEN", response.data.accessToken);
        if (isAdmin) {
          localStorage.setItem("token", accessToken);
          localStorage.removeItem("userToken");
          router.push({ name: "Account" });
        } else {
          localStorage.setItem("userToken", accessToken);
          localStorage.removeItem("token");
          if (window.localStorage.getItem("dagajogaLastRoute")) {
            router.push({
              path: window.localStorage.getItem("dagajogaLastRoute"),
            });
          } else if (router.history.current.params.previousPage) {
            router.push({
              name: router.history.current.params.previousPage,
              params: router.history.current.params,
            });
          } else if (router.history.current.params.previousPath) {
            router.push({
              path: router.history.current.params.previousPath,
              params: router.history.current.params,
            });
          } else {
            if (router.history.current.name !== "Courses") {
              router.push({ name: "Courses" });
            }
          }
        }
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${accessToken}`;
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Zalogowano pomyślnie!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      } catch (error) {
        commit("SET_TOKEN", null);
        localStorage.removeItem("userToken");
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");

        delete axios.defaults.headers.common["Authorization"];
        if (error.response && error.response.status === 404) {
          dispatch(
            "snackbar/setSnackbar",
            {
              color: "red",
              icon: "exclamation-triangle",
              message:
                "Podane dane logowania są nieprawidłowe! Spróbuj ponownie.",
            },
            { root: true }
          );
          dispatch("snackbar/toggleSnackbar", true, { root: true });

          return;
        } else if (error.response && error.response.status === 403) {
          dispatch(
            "snackbar/setSnackbar",
            {
              color: "red",
              icon: "exclamation-triangle",
              message:
                "Konto nie zostało zweryfikowane! Kliknij w link aktywacyjny wysłany na twój adres email",
            },
            { root: true }
          );
          dispatch("snackbar/toggleSnackbar", true, { root: true });
        } else {
          dispatch(
            "snackbar/setSnackbar",
            {
              color: "red",
              icon: "exclamation-triangle",
              message:
                "Wystąpił błąd podczas próby logowania. Spróbuj ponownie później! Szczegóły: " +
                error.message,
            },
            { root: true }
          );
          dispatch("snackbar/toggleSnackbar", true, { root: true });
        }
      }
    },
    async verifyUser({ commit, dispatch }, object) {
      try {
        const response = await axios.put(
          "/verify",
          {
            verificationCode: object.code,
          },
          { skipAuthRefresh: true }
        );
        const { accessToken } = response.data;
        if (accessToken) {
          localStorage.setItem("userToken", accessToken);
          localStorage.removeItem("token");
          commit("SET_TOKEN", response.data.accessToken);
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${accessToken}`;
        }
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Zweryfikowano pomyślnie!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      } catch (error) {
        commit("SET_TOKEN", null);

        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");

        delete axios.defaults.headers.common["Authorization"];

        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message:
              "Wystąpił błąd podczas próby weryfikacji. Spróbuj ponownie później! Szczegóły: " +
              error.message,
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async registerUser({ dispatch }, object) {
      try {
        const response = await axios.post(
          "/signup",
          {
            password: object.password,
            email: object.email,
            confirmPassword: object.confirmPassword,
            refUserId: object.refUserId,
          },
          { skipAuthRefresh: true }
        );
        const { accessToken } = response.data;
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${accessToken}`;
        router.push({ name: "RegisterSuccess" });
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Zarejestrowano pomyślnie!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      } catch (error) {
        delete axios.defaults.headers.common["Authorization"];
        if (error.response.status === 403) {
          dispatch(
            "snackbar/setSnackbar",
            {
              color: "red",
              icon: "exclamation-triangle",
              message: "Podany email jest zajęty",
            },
            { root: true }
          );
          dispatch("snackbar/toggleSnackbar", true, { root: true });

          return;
        } else {
          dispatch(
            "snackbar/setSnackbar",
            {
              color: "red",
              icon: "exclamation-triangle",
              message:
                "Wystąpił błąd podczas próby rejestracji. Spróbuj ponownie później! Szczegóły: " +
                error.message,
            },
            { root: true }
          );
          dispatch("snackbar/toggleSnackbar", true, { root: true });
        }
      }
    },

    async checkUserActiveSubscriptions({ commit }, data) {
      try {
        const extendedSubscription = data ? data.extendedSubscription : null;
        const token = window.localStorage.getItem("userToken");
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        const response = await axios.get(
          `/check-active-subscription${
            extendedSubscription ? "?extendedSubscription=true" : ""
          }`
        );
        if (response.data === true) {
          commit("SET_ACTIVE_SUBSCRIPTION", true);
        } else {
          commit("SET_ACTIVE_SUBSCRIPTION", false);
        }
      } catch (error) {
        commit("SET_ACTIVE_SUBSCRIPTION", false);
      }
    },

    async checkUserActiveSubscriptionsExtended({ commit }) {
      try {
        const token = window.localStorage.getItem("userToken");
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        const response = await axios.get(`/check-active-subscription-extended`);
        if (response.data === true) {
          commit("SET_ACTIVE_SUBSCRIPTION_EXTENDED", true);
        } else {
          commit("SET_ACTIVE_SUBSCRIPTION_EXTENDED", false);
        }
      } catch (error) {
        commit("SET_ACTIVE_SUBSCRIPTION_EXTENDED", false);
      }
    },

    async checkUserActiveSubscriptionsForBeginners({ commit }) {
      try {
        const token = window.localStorage.getItem("userToken");
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        const response = await axios.get(
          `/check-active-subscription-for-beginners`
        );
        if (response.data === true) {
          commit("SET_ACTIVE_SUBSCRIPTION_FOR_BEGINNERS", true);
        } else {
          commit("SET_ACTIVE_SUBSCRIPTION_FOR_BEGINNERS", false);
        }
      } catch (error) {
        commit("SET_ACTIVE_SUBSCRIPTION_FOR_BEGINNERS", false);
      }
    },

    async setCurrentRoute({ commit }, data) {
      try {
        commit("SET_CURRET_ROUTE", data.currentRoute);
      } catch (error) {
        commit("SET_CURRET_ROUTE", "");
      }
    },

    async fetchUserProfile({ commit }) {
      try {
        const token = window.localStorage.getItem("userToken");
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        const response = await axios.get(`/profile`);
        if (response.data) {
          commit("SET_USER_PROFILE", response.data);
        } else {
          commit("SET_USER_PROFILE", {});
        }
      } catch (error) {
        commit("SET_USER_PROFILE", {});
      }
    },

    async sendEmailToChangePassword({ dispatch }, data) {
      try {
        const response = await axios.get(
          `/change-password-request?email=${data.email}`
        );
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message:
              "Na podany adres email została wyslana wiadomość z linkiem do strony, na której ustalisz swoje nowe hasło.",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        return response;
      } catch (error) {
        console.log(error);
      }
    },

    async changePassword({ dispatch }, data) {
      try {
        await axios.post(`/change-password`, data);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Twoje hasło zostało zmienione pomyślnie",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        return true;
      } catch (error) {
        console.log(error);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message:
              "Wystąpił błąd podczas próby zmiany hasła. Spróbuj ponownie później",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        return null;
      }
    },

    async changeOwnPassword({ dispatch }, data) {
      try {
        await axios.post(`/change-own-password`, data);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Twoje hasło zostało zmienione pomyślnie",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        return true;
      } catch (error) {
        console.log(error);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message:
              "Wystąpił błąd podczas próby zmiany hasła. Spróbuj ponownie później",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        return null;
      }
    },

    async setProfilePicture({ dispatch }, data) {
      try {
        const formData = new FormData();
        formData.append("file", data);
        await axios.post(`profile/image`, formData);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Twoje zdjęcie profilowe zostało zmienione",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      } catch (error) {
        console.log(error);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message:
              "Wystąpił błąd podczas próby zmiany zdjęcia profilowego. Spróbuj ponownie później",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async fetchProfilePicture({ commit }, id) {
      try {
        const response = await axios.get(`/profile/${id}/image`);
        commit("SET_PROFILE_PICTURE", response.request.responseURL);
      } catch (error) {
        console.log(error);
      }
    },

    async fetchCommentPicture({ dispatch }, id) {
      try {
        const response = await axios.get(`/profile/${id}/image`);
        return response.request.responseURL;
      } catch (error) {
        dispatch();
        console.log(error);
      }
    },
    async checkToken({ commit }) {
      try {
        const response = await axios.get(`/check-token`);
        if (response.data.isAuth) {
          let token = response.config.headers.Authorization;
          token = token.replace("Bearer ", "");
          window.localStorage.setItem("userToken", token);
          commit("SET_TOKEN", token);
        } else {
          window.localStorage.removeItem("userToken");
          commit("SET_TOKEN", null);
        }
      } catch (error) {
        window.localStorage.removeItem("userToken");
        commit("SET_TOKEN", null);
      }
    },

    async setTokenToNull({ commit }) {
      commit("SET_TOKEN", null);
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    getToken: (state) => (state.token ? jwt_decode(state.token) : null),
    getHasActiveSubscription: (state) => state.hasActiveSubscription,
    getHasActiveSubscriptionExtended: (state) =>
      state.hasActiveSubscriptionExtended,
    getHasActiveSubscriptionForBeginners: (state) =>
      state.hasActiveSubscriptionForBeginners,
    getProfile: (state) => state.profile,
    getProfilePicture: (state) => state.profilePicture,
    getLastRoute: () => window.localStorage.getItem("dagajogaLastRoute"),
  },
};
export default auth;
