var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"width":"100%"}},[_c('management-nav'),_c('v-card-title',[_vm._v(" Produkty w sklepie "),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Szukaj","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('font-awesome-icon',{staticClass:"mr-1 mt-1",attrs:{"slot":"prepend-inner","icon":"search"},slot:"prepend-inner"})],1)],1),_c('v-data-table',{attrs:{"loading":!_vm.items,"loading-text":"Trwa pobieranie danych...","headers":_vm.headers,"items":_vm.items,"search":_vm.search},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":_vm.items && _vm.items.length === 0}},[_vm._v(" Brak ")])]},proxy:true},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('p',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(item.active ? "Tak" : "Nie"))]),_c('v-btn',{staticStyle:{"font-size":"12px","width":"100px"},on:{"click":function($event){return _vm.markStatusAsActiveOrInactive(item)}}},[_vm._v(" "+_vm._s(item.active ? "Dezaktywuj" : "Aktywuj")+" ")])]}},{key:"item.members",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{on:{"click":function($event){return _vm.showMembers(item)}}},[_vm._v(" Pokaż ("+_vm._s(item.members.length)+") ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"green"},on:{"click":[function($event){return _vm.editShopProduct(item)},function($event){_vm.isModalVisible = true}]}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"edit"}})],1)]}}],null,true)},[_c('span',[_vm._v("Edytuj")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red"},on:{"click":function($event){(_vm.isDeleteModalVisible = true), (_vm.currentItem = item)}}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1)]}}],null,true)},[_c('span',[_vm._v("Usuń")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"orange"},on:{"click":[function($event){return _vm.copyShopProduct(item)},function($event){_vm.isModalVisible = true}]}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"copy"}})],1)]}}],null,true)},[_c('span',[_vm._v("Kopiuj")])])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [[_c('p',{staticStyle:{"max-height":"200px","min-width":"300px","overflow":"scroll"},attrs:{"id":item.id},domProps:{"innerHTML":_vm._s(_vm.htmlToFormattedText(item.description))}})]]}},{key:"item.attachment",fn:function(ref){
var item = ref.item;
return [(item.type === 'PDF')?[(
            item.shop_products_attachments &&
              item.shop_products_attachments.length > 0 &&
              item.shop_products_attachments[0].path
          )?[_c('span',{staticStyle:{"text-decoration":"underline","color":"#1976d2","cursor":"pointer"},on:{"click":function($event){return _vm.moveToAttachment(item.shop_products_attachments[0])}}},[_vm._v("Załącznik")]),_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.deleteAttachment(item)}}},[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1)]:[_c('v-btn',{staticClass:"my-2 mx-2 submit-button",on:{"click":function($event){return _vm.addAtachmentForShopProduct(item)}}},[_vm._v(" Dodaj załącznik ")])],_c('input',{staticStyle:{"display":"none"},attrs:{"id":("attachments_input_" + (item.id)),"type":"file","accept":".pdf"},on:{"change":function($event){return _vm.handleNewAttachment($event, item)}}})]:[_vm._v(" Załączniki są dostępne tylko dla typu PDF ")]]}},{key:"item.image",fn:function(ref){
          var item = ref.item;
return [[(_vm.productsVisibleImage && _vm.productsVisibleImage[item.id])?_c('div',{staticClass:"image-container"},[_c('button',{staticClass:"slider-prev",on:{"click":function($event){return _vm.prevImage(item)}}},[_vm._v(" ❮ ")]),_c('button',{staticClass:"slider-next",on:{"click":function($event){return _vm.nextImage(item)}}},[_vm._v(" ❯ ")]),_c('v-btn',{staticClass:"delete-image-icon",attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.deleteImage(item)}}},[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1),(_vm.productsVisibleImage && _vm.productsVisibleImage[item.id])?_c('img',{staticClass:"item-image",attrs:{"id":("mainImage_" + (item.id)),"alt":"","loading":"lazy"},on:{"click":function($event){return _vm.addImages(item)}}}):_vm._e()],1):_c('div',[_c('v-btn',{staticClass:"my-2 mx-2 submit-button",on:{"click":function($event){return _vm.addImages(item)}}},[_vm._v(" Dodaj zdjęcia ")])],1),_c('input',{staticStyle:{"display":"none"},attrs:{"id":("images_input_" + (item.id)),"type":"file","multiple":"","accept":"image/*"},on:{"change":function($event){return _vm.handleNewImages($event, item)}}})]]}}],null,true)}),_c('add-shop-product-modal',{attrs:{"isVisible":_vm.isModalVisible,"productData":_vm.shopProductData},on:{"closeModal":function($event){return _vm.closeModal()},"updated":function($event){return _vm.refreshShopProducts()}}}),_c('before-delete-modal',{attrs:{"isVisible":_vm.isDeleteModalVisible},on:{"closeModal":function($event){return _vm.closeDeleteModal()},"confirmDeletion":function($event){return _vm.deleteShopProduct(_vm.currentItem.id)}}}),_c('v-btn',{attrs:{"fab":"","dark":"","large":"","color":"green","fixed":"","left":"","bottom":""},on:{"click":function($event){_vm.isModalVisible = true}}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }