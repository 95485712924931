const errorHandler = (text, errorCode) => {
  let message = "";
  switch (errorCode) {
    case "002":
      message = "Nie znaleziono!";
      break;
    case "003":
      message = "Nie znaleziono!";
      break;
    case "004":
      message = "Nie znaleziono!";
      break;
    case "005":
      message = "Nie znaleziono!";
      break;
    case "400":
      message = "Brak autoryzacji dla roli!";
      break;
    case "401":
      message = "Brak tokenu!";
      break;
    case "402":
      message = "Zły token!";
      break;
    case "403":
      message = "Brak weryfikacji w bazie danych!";
      break;
    default:
      message = text;
      break;
  }
  return message;
};
export default errorHandler;
