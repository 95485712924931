<template>
  <v-card width="100%">
    <management-nav />
    <v-card-title>
      Anatomia w praktyce - teoria do lekcji
      <v-spacer></v-spacer>
      <v-text-field v-model="search" label="Szukaj" single-line hide-details
        ><font-awesome-icon
          icon="search"
          slot="prepend-inner"
          class="mr-1 mt-1"
      /></v-text-field>
    </v-card-title>
    <v-data-table
      :loading="!items"
      loading-text="Trwa pobieranie danych..."
      :headers="headers"
      :items="items"
      :search="search"
    >
      <template v-slot:no-data>
        <v-alert :value="items && items.length === 0"> Brak lekcji </v-alert>
      </template>
      <template v-slot:[`item.active`]="{ item }">
        <p style="text-align: center">{{ item.active ? "Tak" : "Nie" }}</p>
        <v-btn
          style="font-size: 12px; width: 100px"
          @click="markStatusAsActiveOrInactive(item)"
        >
          {{ item.active ? "Dezaktywuj" : "Aktywuj" }}
        </v-btn>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="green"
              @click="editLesson(item)"
              v-bind="attrs"
              v-on="on"
              v-on:click="key++, (isModalVisible = true)"
            >
              <font-awesome-icon icon="edit" />
            </v-btn>
          </template>
          <span>Edytuj</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="red"
              @click="(isDeleteModalVisible = true), (packageData = item)"
              v-bind="attrs"
              v-on="on"
            >
              <font-awesome-icon icon="times" />
            </v-btn>
          </template>
          <span>Usuń</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="orange"
              @click="copyTheory(item)"
              v-bind="attrs"
              v-on="on"
              v-on:click="isModalVisible = true"
            >
              <font-awesome-icon icon="copy" />
            </v-btn>
          </template>
          <span>Kopiuj</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.lesson`]="{ item }">
        <p>
          {{
            `${item.theoryPracticeWorkshopsLesson.name} - ${item.theoryPracticeWorkshopsLesson.theoryPracticeWorkshop.name}`
          }}
        </p></template
      >

      <template v-slot:[`item.description`]="{ item }">
        <template>
          <p
            style="max-height: 200px; min-width: 300px; overflow: hidden"
            :id="item.id"
            v-html="htmlToFormattedText(item.description)"
          />
        </template>
      </template>
      <template v-slot:[`item.photo`]="{ item }">
        <template>
          <img
            class="item-image"
            :id="`image_${item.id}`"
            @click="editLesson(item)"
            v-on:click="isModalVisible = true"
            alt=""
            loading="lazy"
          />
        </template>
      </template>
      <template v-slot:[`item.images`]="{ item }">
        <v-btn @click="showImages(item)"
          >{{ item.images && item.images.length > 0 ? "Edytuj" : "Dodaj" }}
        </v-btn>
      </template>
    </v-data-table>
    <add-theory-practice-workshop-lesson-theory-modal
      :isVisible="isModalVisible"
      :lessonItemData="lessonData"
      @closeModal="closeModal()"
      @updated="handleItemsUpdate"
      :keyHandle="key"
    />
    <before-delete-modal
      :isVisible="isDeleteModalVisible"
      @closeModal="closeDeleteModal()"
      @confirmDeletion="confirmDelete(packageData.id)"
    />
    <images-preview-modal
      :images="imagesToPreview"
      :isVisible="isImagesPreviewModalVisible"
      @closeModal="isImagesPreviewModalVisible = false"
      @removeImage="handleRemoveImage"
      @saveImages="handleSaveImages"
    />
    <v-btn
      fab
      dark
      large
      color="green"
      fixed
      left
      bottom
      v-on:click="key++, (isModalVisible = true)"
    >
      <font-awesome-icon icon="plus" />
    </v-btn>
  </v-card>
</template>

<script>
import ManagementNav from "../components/ManagementNav.vue";
import BeforeDeleteModal from "../components/modals/BeforeDeleteModal.vue";
import AddTheoryPracticeWorkshopLessonTheoryModal from "../components/modals/AddTheoryPracticeWorkshopLessonTheoryModal.vue";
import { SERVER_URL } from "../axios";
import ImagesPreviewModal from "../components/modals/ImagesPreviewModal.vue";
export default {
  name: "TheoryPracticeWorkshopsLessonsTheoriesManagement",
  components: {
    ManagementNav,
    BeforeDeleteModal,
    AddTheoryPracticeWorkshopLessonTheoryModal,
    ImagesPreviewModal,
  },
  async created() {
    await this.fetchTheories(true);
    await this.$store.dispatch(
      "theoryPracticeWorkshopsLessons/fetchTheoryPracticeWorkshopsLessons",
      { withNotActive: true }
    );
  },
  data: () => ({
    headers: [
      { text: "Opis", value: "description" },
      { text: "Lekcja", value: "lesson" },
      { text: "Link Youtube", value: "link" },
      { text: "Zdjęcia", value: "images" },
      { text: "Akcje", value: "actions", sortable: false },
    ],
    search: "",
    isModalVisible: false,
    lessonData: null,
    currentDate: null,
    workshopsIdToEdit: "",
    isDeleteModalVisible: false,
    imagesToPreview: [],
    isImagesPreviewModalVisible: false,
    selectedTheoryId: "",
    items: [],
    key: 1,
  }),
  computed: {},
  methods: {
    async handleItemsUpdate() {
      await this.fetchTheories(true);
    },
    async fetchTheories(withNotActive) {
      const theories = await this.$store.dispatch(
        "theoryPracticeWorkshopsLessonsTheories/fetchTheoryPracticeWorkshopsLessonsTheories",
        { withNotActive }
      );
      this.items = theories;
    },
    async handleSaveImages(files) {
      let addedImages = [];
      for await (const file of files) {
        const addedImage = await this.$store.dispatch(
          "theoryPracticeWorkshopsLessonsTheories/addTheoryPracticeWorkshopLessonTheoryImage",
          {
            theoryPracticeWorkshopLessonTheoryId: this.selectedTheoryId,
            file,
          }
        );
        addedImages.push(addedImage);
      }

      await this.fetchTheories(true);
      this.$store.dispatch("snackbar/showSnackbar", {
        color: "success",
        message: "Zdjęcia zostały dodane pomyślnie",
      });

      addedImages.forEach((addedIm) => {
        const splittedPath = addedIm.path.split(".");
        const extension = splittedPath[splittedPath.length - 1];
        this.imagesToPreview.push({
          id: addedIm.id,
          url: `${SERVER_URL}/lessonsImages/${addedIm.id}.${extension}`,
        });
      });
    },
    async handleRemoveImage(imageId) {
      await this.$store.dispatch(
        "theoryPracticeWorkshopsLessonsTheories/deleteTheoryPracticeWorkshopLessonTheoryImage",
        {
          theoryPracticeWorkshopLessonTheoryId: this.selectedTheoryId,
          imageId,
        }
      );
      this.imagesToPreview = this.imagesToPreview.filter(
        (i) => i.id !== imageId
      );
    },
    showImages(item) {
      this.selectedTheoryId = item.id;
      this.imagesToPreview = [];
      const images = item.images;
      images.forEach((image) => {
        const splittedPath = image.path.split(".");
        const extension = splittedPath[splittedPath.length - 1];
        this.imagesToPreview.push({
          id: image.id,
          url: `${SERVER_URL}/lessonsImages/${image.id}.${extension}`,
        });
      });
      this.isImagesPreviewModalVisible = true;
    },
    confirmDelete(id) {
      this.deletePackage(id);
    },
    closeDeleteModal() {
      this.isDeleteModalVisible = false;
      this.packageData = null;
    },

    copyTheory(data) {
      const { id, ...rest } = data;
      this.lessonData = rest;
      this.lessonData.id = null;
      this.lessonData.copy = true;
      return id;
    },
    htmlToFormattedText(text) {
      const regex =
        /((http|https|ftp|ftps):\/\/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g;
      text = text ? text.replace(regex, '<a href="$1">$1</a>') : text;
      return text;
    },
    editLesson(data) {
      this.lessonData = data;
    },
    async deletePackage(id) {
      await this.$store.dispatch(
        "theoryPracticeWorkshopsLessonsTheories/deleteTheoryPracticeWorkshopLessonTheory",
        { id }
      );
      this.handleItemsUpdate();
    },
    closeModal() {
      this.isModalVisible = false;
      this.lessonData = null;
    },
  },
};
</script>

<style scoped>
.item-image {
  width: 130px;
  height: 130px;
  border-radius: 1rem;
  margin: 1rem;
  object-fit: cover;
}
</style>
