var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('theory-practice-page-header'),_c('div',[(_vm.lesson)?_c('path-navigation',{attrs:{"paths":[
        {
          name: ("Warsztaty - " + (_vm.lesson.theoryPracticeWorkshop.name)),
          to: ("/warsztaty-teoria-i-praktyka/" + (_vm.lesson.theoryPracticeWorkshop.id)),
        },
        {
          name: ((_vm.lesson.name) + " - Teoria"),
          to: ("/warsztaty-teoria-i-praktyka/" + (_vm.lesson.theoryPracticeWorkshop.id) + "/lekcje/" + (this.$route.params.id) + "/teoria"),
        } ]}}):_vm._e(),_c('section',[_vm._m(0),(_vm.theories && _vm.theories.length > 0)?_c('div',_vm._l((_vm.theories),function(theory){return _c('theory-practice-lesson-theory',{key:theory.id,attrs:{"theory":theory}})}),1):_vm._e(),(_vm.attachments && _vm.attachments.length > 0)?_c('p',{staticClass:"attachments-title"},[_vm._v(" Pliki do pobrania ")]):_vm._e(),(_vm.attachments && _vm.attachments.length > 0)?_c('div',_vm._l((_vm.attachments),function(attachment){return _c('theory-practice-lesson-attachment',{key:attachment.id,attrs:{"attachment":attachment}})}),1):_vm._e(),(_vm.lesson)?_c('comment-section',{key:_vm.lesson ? _vm.lesson.id : new Date(),attrs:{"id":_vm.lesson ? _vm.lesson.id : null,"type":'lesson',"lesson":_vm.lesson,"isTheory":true}}):_vm._e()],1)],1),_c('div',{staticClass:"flex justify-center text-center",attrs:{"id":"lessons-select"}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require("../../assets/theory-black.svg"),"alt":""}}),_c('span',[_vm._v("Teoria")])])}]

export { render, staticRenderFns }