<template>
  <v-row justify="center">
    <v-dialog
      max-width="700px"
      :value="isVisible"
      persistent
      @keydown.esc="closeModal"
    >
      <v-card>
        <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager">
          <v-card-title>
            <span class="headline"> Dodaj film do produktu</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    type="text"
                    rules="required"
                    v-model="name"
                    label="Nazwa"
                    color="indigo"
                    outlined
                    dense
                    maxLength="255"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    type="text"
                    rules="required|link"
                    v-model="link"
                    label="Link"
                    color="indigo"
                    outlined
                    dense
                    maxLength="255"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextAreaWithValidation
                    type="text"
                    rules="required"
                    v-model="description"
                    label="Opis"
                    color="indigo"
                    outlined
                    dense
                    maxLength="2050"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <v-file-input
                    v-model="file"
                    label="Zdjęcie filmu"
                  ></v-file-input>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <v-select
                    v-model="shopProductId"
                    :items="products"
                    item-value="value"
                    item-text="name"
                    outlined
                    color="indigo"
                    dense
                    label="Produkt"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text v-on:click="closeModal">
              <font-awesome-icon icon="times" class="mr-1" />
              Anuluj
            </v-btn>
            <v-btn
              color="success"
              text
              :disabled="invalid"
              v-on:click="addShopProductVideo"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              Zapisz zmiany
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "../inputs/VTextFieldWithValidation";
import VTextAreaWithValidation from "../inputs/VTextAreaWithValidation.vue";

export default {
  name: "AddShopProductVideooModal",
  props: ["isVisible", "shopProductVideoData", "type", "level"],

  components: {
    ValidationObserver,
    VTextFieldWithValidation,
    VTextAreaWithValidation,
  },

  data: () => ({
    shopProductVideoId: null,
    input: "",
    discountError: false,
    updated: false,
    name: "",
    description: "",
    file: null,
    shopProductId: null,
    link: "",
  }),
  updated() {
    if (this.shopProductVideoData) {
      const { id, name, description, imagePath, shop_product, link } =
        this.shopProductVideoData;
      this.shopProductVideoId = id;
      this.updated = true;
      this.name = name;
      this.description = description;
      this.imagePath = imagePath;
      this.shopProductId = shop_product.id;
      this.link = link;
    }
  },

  async created() {
    await this.$store.dispatch("shopProducts/fetchAllProducts");
  },

  methods: {
    closeModal() {
      this.updated = false;
      this.name = "";
      this.description = "";
      this.file = null;
      this.link = "";
      this.$emit("closeModal");
    },
    async addShopProductVideo() {
      if (!this.shopProductId) {
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "red",
          message: "Produkt do którego ma być przypisany film jest wymagany",
        });
        return;
      }

      let filesAreCorrect = true;

      if (this.file && !this.file.type.includes("image")) {
        filesAreCorrect = false;
      }

      if (!filesAreCorrect) {
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "red",
          message:
            "Niepoprawny format pliku. Dozwolone formaty to: jpg, jpeg, png",
        });
        return;
      }

      let object = {
        name: this.name,
        description: this.description,
        file: this.file,
        link: this.link,
      };
      if (this.shopProductId) {
        object.shopProductId = this.shopProductId;
      }
      if (this.shopProductVideoId) {
        object.shopProductVideoId = this.shopProductVideoId;
      }

      if (this.updated) {
        await this.$store
          .dispatch("shopProducts/updateShopProductVideo", {
            ...object,
          })
          .then(() => {
            this.closeModal();
            this.$emit("updated");
          });
      } else {
        await this.$store
          .dispatch("shopProducts/addShopProductVideo", {
            ...object,
          })
          .then(() => {
            this.closeModal();
            this.$emit("updated");
          });
      }
    },
  },

  computed: {
    products() {
      let products = this.$store.getters["shopProducts/getShopProducts"];
      let items = products.map((product) => {
        return {
          name: product.name,
          value: product.id,
        };
      });
      return items;
    },
  },
};
</script>
<style type="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
