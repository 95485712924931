import axios from "../axios";
import errorHandler from "../errorHandler";

const discountCode = {
  namespaced: true,
  state: () => ({
    discountCodes: [],
  }),

  mutations: {
    SET_DISCOUNT_CODES(state, discountCodes) {
      state.discountCodes = discountCodes;
    },
  },

  actions: {
    fetchDiscountCodes: async ({ commit }, data) => {
      try {
        const type = data && data.type ? data.type : null;
        const timePackageId =
          data && data.timePackageId ? data.timePackageId : null;
        const params = {};
        if (type) {
          params.type = type;
        }
        if (timePackageId) {
          params.timePackageId = timePackageId;
        }
        const response = await axios.get("discountCodes", {
          params,
        });
        commit("SET_DISCOUNT_CODES", response.data);
      } catch (error) {
        console.log(error);
      }
    },

    async addDiscountCode({ dispatch }, object) {
      try {
        await axios.post(`discountCodes`, object.data);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie dodano!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      } catch (error) {
        console.log(error.response);
      }
    },

    async updateDiscountCode({ dispatch }, object) {
      try {
        await axios.put(`discountCodes/${object.discountCodeId}`, object.data);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Edycja przebiegła pomyślnie!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      } catch (error) {
        console.log(error.response);
      }
    },

    async deleteDiscountCode({ dispatch }, data) {
      try {
        await axios.delete(`discountCodes/${data.id}`);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie usunięto!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async getDiscountCodeByCodeAndType(_, data) {
      try {
        const params = { code: data.code, type: data.type };
        const response = await axios.get(`discountCodes/byCodeAndType`, {
          params,
        });
        return response.data;
      } catch (error) {
        return null;
      }
    },
  },

  getters: {
    getAllDiscountCodes: (state) => state.discountCodes,
  },
};
export default discountCode;
