<template>
  <div>
    <p v-if="point"><b>Wybrany punkt:</b> {{ point.name }}</p>
    <button type="button" id="furgonetka-map">Wybierz punkt odbioru</button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      point: null,
    };
  },
  emits: ["point-selected"],
  mounted() {
    setTimeout(() => {
      this.initFurgonetkaMap();
    }, 1000);
  },

  methods: {
    initFurgonetkaMap() {
      const callback = (params) => {
        this.point = params.point;
        this.$emit("point-selected", params.point);
      };
      const handleClickMap = () => {
        new window.Furgonetka.Map({
          courierServices: ["inpost", "poczta", "orlen"],
          city: "Warszawa",
          street: "Żoliborz",
          callback: callback,
        }).show();
      };
      const button = document.querySelector("#furgonetka-map");
      button.addEventListener("click", handleClickMap, false);
    },
  },
};
</script>

<style scoped>
#furgonetka-map {
  text-decoration: underline;
  margin-bottom: 2rem;
  font-size: 18px;
}
</style>