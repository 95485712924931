<template>
  <ValidationProvider
    :name="name"
    :rules="rules"
    v-slot="{ errors }"
    :vid="vid"
  >
    <div class="flexit">
      <label for="title">{{ title }}</label>
      <input
        v-model="innerValue"
        :type="type"
        autocomplete="none"
        :placeholder="placeholder"
      />
      <h5 v-if="errors[0]">{{ error }}</h5>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import * as Validator from "vee-validate";

Validator.extend("RegexEmail", {
  message: "Błędny format adresu email. pl|com|eu|uk|net|de|ru|br|fr|it|in",
  validate: (value) => {
    var patt = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+(pl|eu|com|uk|net|de|ru|br|fr|it|in)))$/
    );
    return patt.test(value);
  },
});

export default {
  components: {
    ValidationProvider,
  },
  props: {
    rules: {
      type: [Object, String],
      default: "",
    },
    // must be included in props
    value: {
      type: null,
    },
    vid: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    innerValue: "",
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      if (
        this.type === "number" &&
        this.innerValue.toString().match(/^[0-9]+,[0-9]+$/)
      ) {
        this.innerValue = this.innerValue.toString().replace(",", ".");
      }

      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      if (newVal) {
        this.innerValue = newVal.toString().trim();
      } else {
        this.innerValue = newVal;
      }
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
};
</script>

<style scoped>
input {
  border-bottom: 1px solid black;
  color: black;
  width: 80%;
  margin-bottom: 30px;
  padding: 10px 0;
}
label {
  font-weight: 350;
  font-size: 1.1rem;
}
.flexit {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
h5 {
  background: rgb(230, 57, 51);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  margin-top: -25px;
  margin-bottom: 15px;
}
</style>