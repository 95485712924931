import axios from "../axios";
const shopProducts = {
  namespaced: true,
  state: () => ({
    shopProducts: [],
    shopProductsPagination: {
      pageNumber: 1,
      onPage: 100000,
      total: 0,
      totalPages: 0,
    },
    selectedShopProduct: null,
    productsUpdated: false,
    bucketUpdated: false,
    bucket: {
      numberOfProducts: 0,
      products: [],
      selectedFurgonetkaService: null,
    },
    furgonetkaServices: [],
    shopProductVideos: [],
    shopProductVideo: null,
    shopProductsOrders: [],
  }),
  mutations: {
    SET_SHOP_PRODUCTS(state, products) {
      state.shopProducts = products;
    },
    SET_SHOP_PRODUCTS_PAGINATION(state, pagination) {
      state.shopProductsPagination = pagination;
    },
    SET_SELECTED_SHOP_PRODUCT(state, product) {
      state.selectedShopProduct = product;
    },
    SET_PRODUCTS_UDPATED(state, status) {
      state.productsUpdated = status;
    },
    SET_BUCKET_UPDATED(state, status) {
      state.bucketUpdated = status;
    },
    SET_BUCKET(state, bucket) {
      state.bucket = bucket;
    },
    SET_FURGONETKA_SERVICES(state, services) {
      state.furgonetkaServices = services;
    },
    SET_SHOP_PRODUCT_VIDEOS(state, videos) {
      state.shopProductVideos = videos;
    },
    SET_SHOP_PRODUCT_VIDEO(state, video) {
      state.shopProductVideo = video;
    },
    SET_SHOP_PRODUCTS_ORDERS(state, orders) {
      state.shopProductsOrders = orders;
    },
  },
  actions: {
    async fetchAllProducts({ commit }, data) {
      try {
        const url = `/shopProducts`;
        const params = {
          onPage: data && data.onPage ? data.onPage : 100000,
          pageNumber: data && data.pageNumber ? data.pageNumber : 1,
        };
        const response = await axios.get(url, { params });
        commit("SET_SHOP_PRODUCTS", response.data.data);
        commit("SET_SHOP_PRODUCTS_PAGINATION", response.data.pagination);
      } catch (error) {
        console.log(error);
      }
    },

    async fetchProductDetails({ commit }, data) {
      try {
        const url = `/shopProducts/${data.id}`;
        const response = await axios.get(url);
        commit("SET_SELECTED_SHOP_PRODUCT", response.data);
      } catch (error) {
        console.log(error);
      }
    },

    async setSelectedProductToNull({ commit }) {
      try {
        commit("SET_SELECTED_SHOP_PRODUCT", null);
      } catch (error) {
        console.log(error);
      }
    },

    async addShopProduct({ commit, dispatch }, dataObject) {
      try {
        const { data } = dataObject;
        const formData = new FormData();
        const filesArray = data.files || [];
        filesArray.forEach((file) => {
          formData.append("files", file);
        });
        for (const [key, value] of Object.entries(data)) {
          formData.append(key, value);
        }
        const url = `/shopProducts`;
        await axios.post(url, formData);
        commit("SET_PRODUCTS_UDPATED", true);
        await dispatch("fetchAllProducts");
      } catch (error) {
        commit("SET_PRODUCTS_UDPATED", false);
      }
    },

    async updateShopProduct({ commit, dispatch }, dataObject) {
      try {
        const { id, data } = dataObject;
        const formData = new FormData();
        let filesArray = data.files || [];
        if (typeof filesArray === "object" && data.files && data.files.item) {
          filesArray = [];
          for (let i = 0; i < data.files.length; i++) {
            let file = data.files.item(i);
            filesArray.push(file);
          }
        }
        filesArray.forEach((file) => {
          formData.append("files", file);
        });
        for (const [key, value] of Object.entries(data)) {
          formData.append(key, value);
        }
        const url = `/shopProducts/${id}`;
        await axios.put(url, formData);
        commit("SET_PRODUCTS_UDPATED", true);
        await dispatch("fetchAllProducts");
      } catch (error) {
        console.log(error);
        commit("SET_PRODUCTS_UDPATED", false);
      }
    },

    async removeShopProductImage({ commit, dispatch }, data) {
      try {
        const shopProductImageId = data.shopProductImageId;
        const url = `/shopProducts/images/${shopProductImageId}`;
        await axios.delete(url);
        commit("SET_PRODUCTS_UDPATED", true);
        await dispatch("fetchAllProducts");
      } catch (error) {
        commit("SET_PRODUCTS_UDPATED", false);
      }
    },

    async removeShopProduct({ commit, dispatch }, data) {
      try {
        const shopProductId = data.shopProductId;
        const url = `/shopProducts/${shopProductId}`;
        await axios.delete(url);
        commit("SET_PRODUCTS_UDPATED", true);
        await dispatch("fetchAllProducts");
      } catch (error) {
        commit("SET_PRODUCTS_UDPATED", false);
      }
    },

    async setEmptyBucket({ commit }) {
      try {
        const emptyBucket = {
          numberOfProducts: 0,
          products: [],
          selectedFurgonetkaService: null,
        };
        window.localStorage.setItem(
          "dagajoga_bucket",
          JSON.stringify(emptyBucket)
        );
        commit("SET_BUCKET", emptyBucket);
        commit("SET_BUCKET_UPDATED", true);
      } catch (error) {
        commit("SET_BUCKET_UPDATED", false);
      }
    },

    showProductAddedToBucketNotification({ commit, dispatch }) {
      dispatch(
        "snackbar/setSnackbar",
        {
          color: "success",
          icon: "check",
          message: "Produkt został dodany do koszyka",
        },
        { root: true }
      );
      dispatch("snackbar/toggleSnackbar", true, { root: true });
      commit("SET_BUCKET_UPDATED", true);
    },

    showProductRemovedFromBucketNotification({ commit, dispatch }) {
      dispatch(
        "snackbar/setSnackbar",
        {
          color: "success",
          icon: "check",
          message: "Produkt został usunięty z koszyka",
        },
        { root: true }
      );
      dispatch("snackbar/toggleSnackbar", true, { root: true });
      commit("SET_BUCKET_UPDATED", true);
    },

    async addProductToBucket({ commit, dispatch }, data) {
      try {
        let { product, amount } = data;
        const currentBucket = window.localStorage.getItem("dagajoga_bucket");
        const jsonCurrentBucket = JSON.parse(currentBucket);
        const alreadyAddedProductInBucket = jsonCurrentBucket.products.find(
          (productInBucket) => productInBucket.id === product.id
        );
        if (alreadyAddedProductInBucket) {
          alreadyAddedProductInBucket.amount =
            alreadyAddedProductInBucket.amount + amount;
        } else {
          product.amount = amount;
          jsonCurrentBucket.products.push(product);
          jsonCurrentBucket.numberOfProducts++;
        }

        window.localStorage.setItem(
          "dagajoga_bucket",
          JSON.stringify(jsonCurrentBucket)
        );

        commit("SET_BUCKET_UPDATED", true);
        commit("SET_BUCKET", jsonCurrentBucket);
        await dispatch("showProductAddedToBucketNotification");
      } catch (error) {
        console.log(error);
        commit("SET_BUCKET_UPDATED", false);
      }
    },

    async removeProductFromBucket({ commit, dispatch }, data) {
      try {
        let { product } = data;
        const currentBucket = window.localStorage.getItem("dagajoga_bucket");
        let jsonCurrentBucket = JSON.parse(currentBucket);
        const alreadyAddedProductInBucket = jsonCurrentBucket.products.find(
          (productInBucket) => productInBucket.id === product.id
        );
        if (alreadyAddedProductInBucket) {
          jsonCurrentBucket.products = jsonCurrentBucket.products.filter(
            (productInBucket) => productInBucket.id !== product.id
          );
          jsonCurrentBucket.numberOfProducts =
            jsonCurrentBucket.products.length;
        }
        window.localStorage.setItem(
          "dagajoga_bucket",
          JSON.stringify(jsonCurrentBucket)
        );

        commit("SET_BUCKET_UPDATED", true);
        commit("SET_BUCKET", jsonCurrentBucket);
        await dispatch("showProductRemovedFromBucketNotification");
      } catch (error) {
        console.log(error);
        commit("SET_BUCKET_UPDATED", false);
      }
    },

    async addQuantityProductInBucket({ commit }, data) {
      try {
        let { product } = data;
        const currentBucket = window.localStorage.getItem("dagajoga_bucket");
        const jsonCurrentBucket = JSON.parse(currentBucket);
        const alreadyAddedProductInBucket = jsonCurrentBucket.products.find(
          (productInBucket) => productInBucket.id === product.id
        );
        if (alreadyAddedProductInBucket) {
          alreadyAddedProductInBucket.amount =
            alreadyAddedProductInBucket.amount + 1;
        }
        window.localStorage.setItem(
          "dagajoga_bucket",
          JSON.stringify(jsonCurrentBucket)
        );

        commit("SET_BUCKET_UPDATED", true);
        commit("SET_BUCKET", jsonCurrentBucket);
      } catch (error) {
        console.log(error);
        commit("SET_BUCKET_UPDATED", false);
      }
    },

    async minusQuantityProductInBucket({ commit }, data) {
      try {
        let { product } = data;
        const currentBucket = window.localStorage.getItem("dagajoga_bucket");
        const jsonCurrentBucket = JSON.parse(currentBucket);
        const alreadyAddedProductInBucket = jsonCurrentBucket.products.find(
          (productInBucket) => productInBucket.id === product.id
        );
        if (
          alreadyAddedProductInBucket &&
          alreadyAddedProductInBucket.amount > 1
        ) {
          alreadyAddedProductInBucket.amount =
            alreadyAddedProductInBucket.amount - 1;
        }
        window.localStorage.setItem(
          "dagajoga_bucket",
          JSON.stringify(jsonCurrentBucket)
        );

        commit("SET_BUCKET_UPDATED", true);
        commit("SET_BUCKET", jsonCurrentBucket);
      } catch (error) {
        console.log(error);
        commit("SET_BUCKET_UPDATED", false);
      }
    },

    async setBucketInStoreFromLocalStorage({ commit }) {
      const currentBucket = window.localStorage.getItem("dagajoga_bucket");
      const jsonCurrentBucket = JSON.parse(currentBucket);
      commit("SET_BUCKET", jsonCurrentBucket);
    },

    async getAvailableFurgonetkaServices({ commit }, data) {
      const url = `/shopProducts/furgonetka/availableServices`;
      const response = await axios.post(url, data);
      commit("SET_FURGONETKA_SERVICES", response.data);
    },

    async setFurgonetkaServiceInBucket({ commit }, data) {
      const currentBucket = window.localStorage.getItem("dagajoga_bucket");
      const jsonCurrentBucket = JSON.parse(currentBucket);
      jsonCurrentBucket.selectedFurgonetkaService =
        data.selectedFurgonetkaService;
      window.localStorage.setItem(
        "dagajoga_bucket",
        JSON.stringify(jsonCurrentBucket)
      );
      commit("SET_BUCKET", jsonCurrentBucket);
    },

    async fetchShopProductVideos({ commit }, data) {
      try {
        const { productId } = data;
        const url = `/shopProducts/${productId}/videos`;
        const response = await axios.get(url);
        commit("SET_SHOP_PRODUCT_VIDEOS", response.data);
      } catch (error) {
        console.log(error);
        commit("SET_SHOP_PRODUCT_VIDEOS", []);
      }
    },

    async fetchShopProductVideo({ commit }, data) {
      try {
        const { productId, shopProductVideoId } = data;
        const url = `/shopProducts/${productId}/videos/${shopProductVideoId}`;
        const response = await axios.get(url);
        commit("SET_SHOP_PRODUCT_VIDEO", response.data);
      } catch (error) {
        console.log(error);
        commit("SET_SHOP_PRODUCT_VIDEO", null);
      }
    },

    async fetchAllShopProductsVideos({ commit }) {
      try {
        const url = `/shopProducts/videos`;
        const response = await axios.get(url);
        commit("SET_SHOP_PRODUCT_VIDEOS", response.data);
      } catch (error) {
        console.log(error);
        commit("SET_SHOP_PRODUCT_VIDEOS", []);
      }
    },

    async addShopProductVideo({ commit, dispatch }, data) {
      try {
        const url = `/shopProducts/${data.shopProductId}/videos`;
        delete data.shopProductId;
        delete data.shopProductVideoId;
        const formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
          formData.append(key, value);
        }
        await axios.post(url, formData);
        commit("SET_PRODUCTS_UDPATED", true);
        await dispatch("fetchAllShopProductsVideos");
      } catch (error) {
        console.log(error);
        commit("SET_PRODUCTS_UDPATED", false);
      }
    },

    async updateShopProductVideo({ commit, dispatch }, data) {
      try {
        const url = `/shopProducts/${data.shopProductId}/videos/${data.shopProductVideoId}`;
        delete data.shopProductId;
        delete data.shopProductVideoId;
        const formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
          formData.append(key, value);
        }
        await axios.put(url, formData);
        commit("SET_PRODUCTS_UDPATED", true);
        await dispatch("fetchAllShopProductsVideos");
      } catch (error) {
        console.log(error);
        commit("SET_PRODUCTS_UDPATED", false);
      }
    },

    async deleteShopProductVideo({ commit, dispatch }, data) {
      try {
        const url = `/shopProducts/${data.shopProductId}/videos/${data.shopProductVideoId}`;
        await axios.delete(url);
        commit("SET_PRODUCTS_UDPATED", true);
        await dispatch("fetchAllShopProductsVideos");
      } catch (error) {
        console.log(error);
        commit("SET_PRODUCTS_UDPATED", false);
      }
    },

    async addAttachmentToShopProduct({ commit, dispatch }, data) {
      try {
        let filesArray = [];
        if (data.file && typeof data.file === "object" && data.file.item) {
          for (let i = 0; i < data.file.length; i++) {
            let file = data.file.item(i);
            filesArray.push(file);
          }
        }
        const formData = new FormData();
        filesArray.forEach((file) => {
          formData.append("file", file);
        });
        const url = `/shopProducts/${data.shopProductId}/attachments`;
        await axios.post(url, formData);
        commit("SET_PRODUCTS_UDPATED", true);
        await dispatch("fetchAllProducts");
      } catch (error) {
        console.log(error);
        commit("SET_PRODUCTS_UDPATED", false);
      }
    },

    async removeAttachmentFromShopProduct({ commit, dispatch }, data) {
      try {
        const url = `/shopProducts/${data.shopProductId}/attachments`;
        await axios.delete(url);
        commit("SET_PRODUCTS_UDPATED", true);
        await dispatch("fetchAllProducts");
      } catch (error) {
        console.log(error);
        commit("SET_PRODUCTS_UDPATED", false);
      }
    },

    async checkUserHasAccessToProductVideos({ commit }, data) {
      try {
        const url = `/shopProducts/${data.shopProductId}/videos/access`;
        const response = await axios.get(url);
        commit("SET_PRODUCTS_UDPATED", true);
        return response.data;
      } catch (error) {
        console.log(error);
        commit("SET_PRODUCTS_UDPATED", false);
        return false;
      }
    },

    async fetchUserOrders({ commit }) {
      try {
        const url = `/shopProducts/orders`;
        const response = await axios.get(url);
        commit("SET_SHOP_PRODUCTS_ORDERS", response.data);
        return response.data;
      } catch (error) {
        console.log(error);
        commit("SET_SHOP_PRODUCTS_ORDERS", []);
        return false;
      }
    },

    async checkOrderIsPaid({ commit }, data) {
      try {
        const { shopOrderId } = data;
        const url = `/shopProducts/orders/${shopOrderId}/paymentStatus`;
        const response = await axios.get(url);
        commit("SET_PRODUCTS_UDPATED", true);
        return response.data?.isPaid;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
  },

  getters: {
    getShopProducts: (state) => state.shopProducts,
    getShopProductsPagination: (state) => state.shopProductsPagination,
    getSelectedShopProduct: (state) => state.selectedShopProduct,
    getBucket: (state) => state.bucket,
    getFurgonetkaServices: (state) => state.furgonetkaServices,
    getShopProductVideos: (state) => state.shopProductVideos,
    getShopProductVideo: (state) => state.shopProductVideo,
    getShopProductsOrders: (state) => state.shopProductsOrders,
  },
};

export default shopProducts;
