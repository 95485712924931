<template>
  <v-row justify="center">
    <v-dialog
      max-width="700px"
      :value="isVisible"
      persistent
      @keydown.esc="closeModal"
    >
      <v-card>
        <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager">
          <v-card-title>
            <span class="headline">{{
              `${
                updated ? "Edytuj" : "Dodaj"
              } kurs do warsztatów z sekwencjonowania`
            }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    type="text"
                    rules="required"
                    v-model="name"
                    label="Nazwa"
                    color="indigo"
                    outlined
                    dense
                    maxLength="255"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextAreaWithValidation
                    type="text"
                    v-model="description"
                    label="Opis"
                    color="indigo"
                    outlined
                    dense
                    maxLength="2048"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    type="text"
                    v-model="videoLink"
                    label="Link YouTube"
                    color="indigo"
                    outlined
                    dense
                    maxLength="1024"
                    rules="required|link"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="sequencingWorkshopId"
                    :items="workshops"
                    item-value="id"
                    item-text="name"
                    outlined
                    color="indigo"
                    dense
                    label="Warsztat"
                  />
                </v-col>
              </v-row>
              <v-row v-if="!copiedSequencingWorkshopItemId">
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <v-file-input
                    v-model="file"
                    label="Zdjęcie kursu"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text v-on:click="closeModal">
              <font-awesome-icon icon="times" class="mr-1" />
              Anuluj
            </v-btn>
            <v-btn
              color="success"
              text
              :disabled="invalid"
              v-on:click="addPackageItem"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              Zapisz zmiany
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "../inputs/VTextFieldWithValidation";
import VTextAreaWithValidation from "../inputs/VTextAreaWithValidation.vue";

export default {
  name: "AddSequencingWorkshopsItemModal",
  props: ["isVisible", "workshopItemData"],

  components: {
    ValidationObserver,
    VTextFieldWithValidation,
    VTextAreaWithValidation,
  },
  mounted() {
    this.$store.dispatch(
      "sequencingWorkshops/fetchSequencingWorkshopWithNotActive"
    );
  },

  data: () => ({
    sequencingWorkshopItemId: null,
    input: "",
    discountError: false,
    updated: false,
    name: "",
    description: "",
    videoLink: "",
    file: null,
    sequencingWorkshopId: "",
    imagePath: "",
    copiedSequencingWorkshopItemId: "",
  }),
  updated() {
    if (this.workshopItemData) {
      const {
        id,
        videoLink,
        name,
        description,
        imagePath,
        sequencingWorkshop,
        copy,
        copiedSequencingWorkshopItemId,
      } = this.workshopItemData;
      this.sequencingWorkshopItemId = id;
      this.updated = true;
      this.name = name;
      this.videoLink = videoLink;
      this.description = description;
      this.imagePath = imagePath;
      this.sequencingWorkshopId = sequencingWorkshop.id;
      if (copy) {
        this.updated = null;
        this.copiedSequencingWorkshopItemId = copiedSequencingWorkshopItemId;
      }
    }
  },

  methods: {
    closeModal() {
      this.updated = false;
      this.$emit("closeModal");
      (this.sequencingWorkshopItemId = null),
        (this.input = ""),
        (this.discountError = false),
        (this.updated = false),
        (this.name = ""),
        (this.description = ""),
        (this.videoLink = ""),
        (this.file = null),
        (this.sequencingWorkshopId = ""),
        (this.imagePath = ""),
        (this.copiedSequencingWorkshopItemId = "");
    },
    async addPackageItem() {
      let filesAreCorrect = true;

      if (!this.sequencingWorkshopId) {
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "red",
          message: "Warsztat do którego ma być przypisany kurs jest wymagany",
        });
        return;
      }

      if (this.file && !this.file.type.includes("image")) {
        filesAreCorrect = false;
      }

      if (!filesAreCorrect) {
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "red",
          message:
            "Niepoprawny format pliku. Dozwolone formaty to: jpg, jpeg, png",
        });
        return;
      }

      let object = {
        name: this.name,
        description: this.description,
        videoLink: this.videoLink,
        file: this.file,
        sequencingWorkshopId: this.sequencingWorkshopId,
      };

      if (this.updated) {
        await this.$store
          .dispatch("sequencingWorkshops/updateSequencingWorkshopItem", {
            id: this.sequencingWorkshopItemId,
            data: object,
          })
          .then(() => {
            this.closeModal();
            this.$emit("updated");
          });
      } else {
        await this.$store
          .dispatch("sequencingWorkshops/addSequencingWorkshopItem", {
            data: object,
          })
          .then(() => {
            this.closeModal();
            this.$emit("updated");
          });
      }
    },
  },

  computed: {
    workshops() {
      return this.$store.getters["sequencingWorkshops/getSequencingWorkshops"];
    },
  },
};
</script>
<style type="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
