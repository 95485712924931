<template>
  <v-row justify="center">
    <v-dialog
      max-width="700px"
      :value="isVisible"
      persistent
      @keydown.esc="closeModal"
    >
      <v-card>
        <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager">
          <v-card-title>
            <span class="headline"> Dodaj załącznik</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-select
                    item-value="value"
                    item-text="name"
                    :items="[
                      {
                        name: 'Teoria',
                        value: 'THEORY',
                      },
                      { name: 'Praktyka', value: 'PRACTICE' },
                    ]"
                    outlined
                    v-model="type"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    type="text"
                    rules="required"
                    v-model="name"
                    label="Nazwa załącznika"
                    color="indigo"
                    outlined
                    dense
                    maxLength="255"
                  />
                </v-col>
              </v-row>
              <v-row v-if="!updated">
                <v-col cols="12">
                  <v-select-with-validation
                    :key="keyHandle"
                    rules="required"
                    v-model="theoryPracticeWorkshopsLessonId"
                    :items="theoryPracticeWorkshopsLessons"
                    item-value="id"
                    item-text="name"
                    outlined
                    color="indigo"
                    dense
                    label="Lekcja"
                    :returnValue="true"
                  />
                </v-col>
              </v-row>
              <v-row v-if="!updated">
                <v-col cols="12">
                  <v-file-input v-model="file" label="Dodaj załącznik" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text v-on:click="closeModal">
              <font-awesome-icon icon="times" class="mr-1" />
              Anuluj
            </v-btn>
            <v-btn
              color="success"
              text
              :disabled="invalid"
              v-on:click="addLessonAttachmentItem"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              Zapisz zmiany
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "../inputs/VTextFieldWithValidation";
import VSelectWithValidation from "../inputs/VSelectWithValidation.vue";

export default {
  name: "AddTheoryPracticeWorkshopLessonAttachmentModal",
  props: ["isVisible", "attachmentItemData", "keyHandle"],

  components: {
    ValidationObserver,
    VTextFieldWithValidation,
    VSelectWithValidation,
  },

  data: () => ({
    input: "",
    discountError: false,
    updated: false,
    name: "",
    theoryPracticeWorkshopsLessonId: "",
    lessonAttachmentItemId: "",
    file: null,
    type: "THEORY",
  }),
  updated() {
    if (this.attachmentItemData) {
      const { id, name, theoryPracticeWorkshopsLessonId, type } =
        this.attachmentItemData;

      this.lessonAttachmentItemId = id;
      this.updated = this.lessonAttachmentItemId ? true : false;
      this.name = name;
      this.theoryPracticeWorkshopsLessonId = theoryPracticeWorkshopsLessonId;
      this.type = type;
    }
  },

  async created() {},

  methods: {
    closeModal() {
      this.updated = false;
      this.name = "";
      this.theoryPracticeWorkshopsLessonId = "";
      this.file = null;
      this.type = "THEORY";
      this.$emit("closeModal");
    },
    async addLessonAttachmentItem() {
      let object = {
        name: this.name,
        type: this.type,
      };
      if (this.updated) {
        await this.$store
          .dispatch(
            "theoryPracticeWorkshopsLessonsAttachments/editTheoryPracticeWorkshopLessonAttachment",
            {
              lessonId: this.theoryPracticeWorkshopsLessonId,
              id: this.lessonAttachmentItemId,
              data: object,
            }
          )
          .then(() => {
            this.closeModal();
            this.$emit("updated");
          });
      } else {
        if (!this.file) {
          this.$store.dispatch("snackbar/showSnackbar", {
            color: "red",
            message: "Wybierz plik, który chcesz dodać",
          });
          return;
        }

        object.file = this.file;

        await this.$store
          .dispatch(
            "theoryPracticeWorkshopsLessonsAttachments/createTheoryPracticeWorkshopLessonAttachment",
            {
              lessonId: this.theoryPracticeWorkshopsLessonId,
              data: object,
            }
          )
          .then(() => {
            this.closeModal();
            this.$emit("updated");
          });
      }
    },
  },

  computed: {
    theoryPracticeWorkshopsLessons() {
      return this.$store.getters[
        "theoryPracticeWorkshopsLessons/getTheoryPracticeWorkshopsLessons"
      ];
    },
  },
};
</script>
<style type="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
