import { render, staticRenderFns } from "./SubcategoryL2View.vue?vue&type=template&id=336d00ac&scoped=true&"
import script from "./SubcategoryL2View.vue?vue&type=script&lang=js&"
export * from "./SubcategoryL2View.vue?vue&type=script&lang=js&"
import style0 from "./SubcategoryL2View.vue?vue&type=style&index=0&id=336d00ac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "336d00ac",
  null
  
)

export default component.exports