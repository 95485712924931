<template>
  <v-row justify="center">
    <v-dialog max-width="1100px" :value="isVisible" @keydown.esc="closeModal">
      <v-card>
        <ValidationObserver ref="obs" mode="eager">
          <v-card-title>
            <span class="headline"> Lista zakupów</span>
          </v-card-title>

          <v-data-table
            :loading="!items"
            loading-text="Trwa pobieranie danych..."
            :headers="headers"
            :items="items"
            :search="search"
          >
            <template v-slot:no-data>
              <v-alert :value="items && items.length === 0">
                Brak zakupów
              </v-alert>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="green"
                    @click="getUserSubscriptions(item)"
                    v-bind="attrs"
                    v-on="on"
                    v-on:click="isModalVisible = true"
                  >
                    <font-awesome-icon icon="shopping-cart" />
                  </v-btn>
                </template>
                <span>Lista subskrypcji</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.timePackageExpirationTime`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <p
                    :id="item.id"
                    v-bind="attrs"
                    v-on="on"
                    v-html="formatExpiryDate(item.timePackageExpirationTime)"
                  />
                </template>
              </v-tooltip>
            </template>

            <template v-slot:[`item.createdAt`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <p
                    :id="item.id"
                    v-bind="attrs"
                    v-on="on"
                    v-html="formatExpiryDate(item.createdAt)"
                  />
                </template>
              </v-tooltip>
            </template>

            <template v-slot:[`item.invoiceUrl`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <p
                    :id="item.id"
                    v-bind="attrs"
                    v-on="on"
                    v-html="downloadText(item.invoiceUrl)"
                    @click="downloadInvoice(item.invoiceUrl)"
                    style="cursor: pointer; text-decoration: underline"
                  />
                </template>
              </v-tooltip>
            </template>

            <template v-slot:[`item.isActiveTimePackage`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <p
                    :id="item.id"
                    v-bind="attrs"
                    v-on="on"
                    v-html="isActive(item.isActiveTimePackage)"
                  />
                </template>
              </v-tooltip>
            </template>

            <template v-slot:[`item.productType`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <p
                    :id="item.id"
                    v-bind="attrs"
                    v-on="on"
                    v-html="getProductName(item)"
                  />
                </template>
              </v-tooltip>
            </template>

            <template v-slot:[`item.description`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <p
                    :id="item.id"
                    v-bind="attrs"
                    v-on="on"
                    v-html="getShortText(item.description)"
                  />
                </template>
              </v-tooltip>
            </template>

            <template v-slot:[`item.name`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <p
                    :id="item.id"
                    v-bind="attrs"
                    v-on="on"
                    v-html="getShortText(item.name)"
                  />
                </template>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text v-on:click="closeModal">
              <font-awesome-icon icon="times" class="mr-1" />
              Wróć
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from "moment";
import axios from "axios";

export default {
  name: "UserSubscriptionsModal",
  props: ["isVisible"],

  components: {},
  created() {},

  data: () => ({
    headers: [
      { text: "Produkt", value: "productType" },

      { text: "Nazwa", value: "name" },
      { text: "Opis", value: "description" },
      //{ text: "Aktywna subskrypcja", value: "isActiveTimePackage" },
      { text: "Faktura", value: "invoiceUrl" },
      { text: "Data utworzenia", value: "createdAt", width: "11%" },
      {
        text: "Data wygaśnięcia",
        value: "timePackageExpirationTime",
        width: "11%",
      },
      { text: "Liczba dni", value: "numberOfDays" },
      { text: "Email odbiorcy vouchera", value: "giftRecipientEmail" },
      { text: "ZoomID", value: "zoomId" },
      { text: "Data zajęć", value: "date" },
      { text: "Cena", value: "price" },
    ],
    search: "",
    isModalVisible: false,
    giftCard: null,
  }),
  computed: {
    items() {
      return this.$store.getters["userSubscriptions/getUserSubscriptions"];
    },
  },
  updated() {
    if (this.giftCard) {
      const { id, price, numberOfDays, name, description } = this.giftCard;
      this.giftCardId = id;
      this.price = price ? price : 0;
      this.updated = true;
      this.numberOfDays = numberOfDays;
      this.name = name;
      this.description = description;
    }
  },
  methods: {
    formatExpiryDate(date) {
      if (date != null) {
        const formattedDate = moment(date).format("YYYY-DD-MM");
        return formattedDate;
      }
      return null;
    },

    downloadText(invoiceUrl) {
      if (invoiceUrl) return "Pobierz";
      return null;
    },

    async downloadInvoice(invoiceUrl) {
      const response = await axios.get(invoiceUrl, {
        responseType: "blob",
      });
      let fileName = "faktura.pdf";
      for (const [key, value] of Object.entries(response.headers)) {
        if (key === "content-disposition") {
          const res = value.split('"');
          fileName = res[1];
          fileName = unescape(fileName);
        }
      }
      fileName = encodeURI(fileName);
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", fileName);
      document.body.appendChild(fileLink);
      fileLink.click();
    },

    isActive(isActiveTimePackage) {
      if (isActiveTimePackage != null) {
        const response = isActiveTimePackage ? "Tak" : "Nie";
        return response;
      }
      return null;
    },

    getProductName(item) {
      if (item.isSequencingWorkshop) return "Warsztaty z sekwencjonowania";
      if (item.isTariffWorkshop) return "Warsztaty z taryfami";
      if (item.productType === "TIME_PACKAGE") return "Subskrypcja";
      if (item.productType === "GIFT_CARD") return "Voucher";
      if (item.productType === "PACKAGE" && item.zoomId)
        return "Zajęcia na żywo ZOOM";
      if (item.productType === "PACKAGE") return "Pakiet";
      if (item.productType === "WORKSHOPS") return "Warsztaty";
    },

    getShortText(text) {
      if (text.length > 20) return text.substring(0, 19) + "...";
      return text;
    },

    closeModal() {
      this.price = 0;
      this.updated = false;
      this.description = "";
      this.numberOfDays = 0;
      this.name = "";
      this.giftCardId = null;
      this.$emit("closeModal");
    },
  },
};
</script>
<style type="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
