var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('theory-practice-page-header'),_c('div',[(_vm.lesson)?_c('path-navigation',{attrs:{"paths":[
        {
          name: ("Warsztaty - " + (_vm.lesson.theoryPracticeWorkshop.name)),
          to: ("/warsztaty-teoria-i-praktyka/" + (_vm.lesson.theoryPracticeWorkshop.id)),
        },
        {
          name: ((_vm.lesson.name) + " - Praktyka"),
          to: ("/warsztaty-teoria-i-praktyka/" + (_vm.lesson.theoryPracticeWorkshop.id) + "/lekcje/" + (this.$route.params.id) + "/praktyka"),
        } ]}}):_vm._e(),_c('section',[_vm._m(0),(_vm.practices && _vm.practices.length > 0)?_c('div',_vm._l((_vm.practices),function(practice){return _c('theory-practice-lesson-practice',{key:practice.id,attrs:{"practice":practice,"customKey":practice.id}})}),1):_vm._e(),(_vm.attachments && _vm.attachments.length > 0)?_c('p',{staticClass:"attachments-title"},[_vm._v(" Pliki do pobrania ")]):_vm._e(),(_vm.attachments && _vm.attachments.length > 0)?_c('div',_vm._l((_vm.attachments),function(attachment){return _c('theory-practice-lesson-attachment',{key:attachment.id,attrs:{"attachment":attachment}})}),1):_vm._e(),(_vm.lesson)?_c('comment-section',{key:_vm.lesson ? _vm.lesson.id : new Date(),attrs:{"id":_vm.lesson ? _vm.lesson.id : null,"type":'lesson',"lesson":_vm.lesson,"isTheory":false}}):_c('div',[_c('h3',{staticClass:"ml-1"},[_vm._v("Brak teorii")])])],1)],1),_c('div',{staticClass:"flex justify-center text-center",attrs:{"id":"lessons-select"}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require("../../assets/practice-black.svg"),"alt":""}}),_c('span',[_vm._v("Praktyka")])])}]

export { render, staticRenderFns }