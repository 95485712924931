<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex xs12 sm6 offset-sm3 mt-6 class="transparent-bg">
        <h1 class="text-center">
          Dziękujemy za skorzystanie z naszego serwisu
        </h1>
        <blockquote class="blockquote text-center">
          Jeśli płatność przebiegła pomyślnie, zakupiony kurs powinien pojawić
          się na liście Twoich dostępnych kursów.
        </blockquote>
        <!-- <telegram class="telegram" /> -->
        <blockquote class="blockquote text-center">
          <a class="center">
            <div class="primary-button" @click="redirect">Moje kursy</div>
          </a>
        </blockquote>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  metaInfo: {
    title: "Dagajoga subskrypcja - portal jogi online",
  },
  methods: {
    redirect() {
      this.$router.push({ name: "CoursesForBeginners" });
    },
  },
};
</script>

<style scoped>
.telegram {
  position: absolute;
  bottom: 0;
  right: 1rem;
}
.transparent-bg {
  position: relative;
  padding: 50px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.664);
}
i {
  font-size: 120px;
}
.btn-gradient {
  width: fit-content;
  padding: 15px 25px;
  border-radius: 30px;
  font-size: 1.4rem;
  cursor: pointer;
}
.center {
  display: flex;
  justify-content: center;
  margin-right: 20px;
  margin-top: 15px;
  cursor: default;
}
a {
  text-decoration: none;
}
</style>
