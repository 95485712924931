var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"width":"100%"}},[_c('management-nav'),_c('v-card-title',[_vm._v(" Lista użytkowników "),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Szukaj","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('font-awesome-icon',{staticClass:"mr-1 mt-1",attrs:{"slot":"prepend-inner","icon":"search"},slot:"prepend-inner"})],1)],1),_c('v-data-table',{attrs:{"loading":!_vm.items,"loading-text":"Trwa pobieranie danych...","headers":_vm.headers,"items":_vm.items,"search":_vm.search,"items-per-page":30,"footer-props":{ 'items-per-page-options': [5, 10, 15, 30, -1] }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":_vm.items && _vm.items.length === 0}},[_vm._v(" Brak użytkowników ")])]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"green"},on:{"click":[function($event){return _vm.getUserSubscriptions(item.id)},function($event){_vm.isModalVisible = true}]}},[_c('font-awesome-icon',{attrs:{"icon":"shopping-cart"}})],1),_c('span',[_vm._v("Lista zakupów")])]}},(_vm.showWorkshops)?{key:"item.usersWorkshops",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.availableWorkshops),function(availableWorkshop){return _c('div',{key:availableWorkshop.id,staticClass:"workshopItem"},[_c('v-checkbox',{attrs:{"label":availableWorkshop.name,"id":((item.id) + "_" + (availableWorkshop.id))},on:{"change":function($event){return _vm.handleWorkshopsChange(availableWorkshop.id, item.id)}},model:{value:(_vm.userWorkshops[((item.id) + "_" + (availableWorkshop.id))]),callback:function ($$v) {_vm.$set(_vm.userWorkshops, ((item.id) + "_" + (availableWorkshop.id)), $$v)},expression:"userWorkshops[`${item.id}_${availableWorkshop.id}`]"}})],1)})}}:null,{key:"item.subscriptionExpiryDate",fn:function(ref){
var item = ref.item;
return [[_c('p',{attrs:{"id":item.id},domProps:{"innerHTML":_vm._s(_vm.formatExpiryDate(item.subscriptionExpiryDate))}}),_c('v-btn',{attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.editDate(item)}}},[_c('font-awesome-icon',{attrs:{"icon":"edit"}})],1)]]}},{key:"item.extendedSubscriptionExpiryDate",fn:function(ref){
var item = ref.item;
return [[_c('p',{attrs:{"id":item.id},domProps:{"innerHTML":_vm._s(_vm.formatExpiryDate(item.extendedSubscriptionExpiryDate))}}),_c('v-btn',{attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.editDateForExtended(item)}}},[_c('font-awesome-icon',{attrs:{"icon":"edit"}})],1)]]}},{key:"item.subscriptionForBeginnersExpiryDate",fn:function(ref){
var item = ref.item;
return [[_c('p',{attrs:{"id":item.id},domProps:{"innerHTML":_vm._s(_vm.formatExpiryDate(item.subscriptionForBeginnersExpiryDate))}}),_c('v-btn',{attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.editDateForBeginners(item)}}},[_c('font-awesome-icon',{attrs:{"icon":"edit"}})],1)]]}}],null,true)}),_c('UserSubscriptionsModal',{attrs:{"isVisible":_vm.isModalVisible},on:{"closeModal":function($event){return _vm.closeModal()}}}),_c('SelectDateModal',{attrs:{"isVisible":_vm.isSelectDateModalVisible,"withPrice":true},on:{"closeModal":function($event){return _vm.closeSelectDateModal()},"dateSelected":_vm.dateSelected}}),_c('SelectDateModal',{attrs:{"isVisible":_vm.isSelectDateForBeginnersModalVisible,"withPrice":true},on:{"closeModal":function($event){return _vm.closeSelectDateForBeginnersModal()},"dateSelected":_vm.dateForBeginnersSelected}}),_c('SelectDateModal',{attrs:{"isVisible":_vm.isSelectDateForExtendedModalVisible,"withPrice":true},on:{"closeModal":function($event){return _vm.closeSelectDateForExtendedModal()},"dateSelected":_vm.dateSelectedExtended}}),_c('v-btn',{attrs:{"fab":"","dark":"","large":"","color":"green","fixed":"","left":"","bottom":""},on:{"click":function($event){_vm.isModalVisible = true}}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }