<template>
  <div class="content-s">
    <div class="d-flex title-row">
      <h1 v-if="comments.length == 0">Brak komentarzy</h1>
      <h1 v-else>Komentarze({{ numberOfComments }})</h1>
      <div
        class="user-select"
        v-if="
          profile &&
          hasAdminPermissions &&
          members &&
          members.length > 0 &&
          lesson &&
          type === 'lesson'
        "
      >
        <v-select
          :items="members"
          item-value="value"
          item-text="name"
          label="Wybierz użytkownika"
          v-model="selectedUserId"
          clearable
        />
      </div>
    </div>

    <div class="display-comments" v-if="!isFetching">
      <Comment
        v-for="comment in comments"
        :comment="comment"
        :key="comment.comment_id"
        @subCommentAdded="subCommentAdded()"
        :timePackageId="id"
        :customKey="`${comment.comment.id}_${refreshKey}`"
        :hasAdminPermissions="hasAdminPermissions"
        @deleted="handleDeletedComment"
      />
    </div>
    <div v-if="loggedIn" class="add-comment">
      <quill-editor
        v-model="content"
        ref="myQuillEditor"
        :options="editorOption"
        class="window-edit"
      />
      <v-file-input
        class="mt-8 mb-8"
        accept="image/png, image/jpeg, image/jpg"
        placeholder="Dodaj zdjęcie(a) do komentarza"
        prepend-icon="mdi-camera"
        multiple
        color="#B28A8A"
        v-model="files"
      ></v-file-input>
      <div class="button-s" @click="addComment(id)">Dodaj komentarz</div>
    </div>
  </div>
</template>

<script>
import "quill/dist/quill.snow.css";
import { quillEditor, Quill } from "vue-quill-editor";
import Comment from "./Comment.vue";
import "quill-emoji/dist/quill-emoji.css";
import Emoji from "quill-emoji/dist/quill-emoji";

Quill.register(
  {
    "formats/emoji": Emoji.EmojiBlot,
    "modules/short_name_emoji": Emoji.ShortNameEmoji,
    "modules/toolbar_emoji": Emoji.ToolbarEmoji,
    "modules/textarea_emoji": Emoji.TextAreaEmoji,
  },
  true
);

export default {
  name: "CommentSection",
  components: {
    quillEditor,
    Comment,
  },
  props: ["id", "type", "lesson", "isTheory"],
  async created() {
    await this.fetchCommentsData();
  },
  data: () => ({
    loggedIn: false,
    image_url: "",
    content: "",
    files: null,
    pack: null,
    thisComment: null,
    isFetching: true,
    editorOption: {
      debug: "error",
      placeholder: "Dodaj komentarz...",
      readOnly: true,
      theme: "snow",
      formats: [
        "bold",
        "color",
        "background",
        "size",
        "italic",
        "underline",
        "emoji",
        "strike",
        "align",
      ],
      modules: {
        toolbar: {
          container: [
            [{ size: ["small", false, "large"] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            ["emoji"],
          ],
          handlers: {
            emoji: function () {},
          },
        },
        toolbar_emoji: true,
      },
    },
    selectedUserId: null,
    refreshKey: 1,
  }),
  computed: {
    hasAdminPermissions() {
      if (
        this.profile &&
        (this.profile.isAdmin || this.profile.hasAdminPermissions)
      ) {
        return true;
      } else {
        return false;
      }
    },
    profile() {
      return this.$store.getters["auth/getProfile"];
    },
    comments() {
      const commentsData = this.$store.getters["comments/getComments"];
      if (commentsData) {
        return commentsData.comments;
      } else {
        return [];
      }
    },
    numberOfComments() {
      const commentsData = this.$store.getters["comments/getComments"];
      if (commentsData) {
        return commentsData.numberOfComments;
      } else {
        return 0;
      }
    },
    members() {
      const members =
        this.$store.getters[
          "theoryPracticeWorkshops/getTheoryPracticeWorkshopMembers"
        ];
      if (members) {
        return members.map((m) => {
          return {
            value: m.userId,
            name: m.email,
          };
        });
      } else {
        return [];
      }
    },
  },
  watch: {
    content() {
      this.delta = this.$refs.myQuillEditor.quill.getContents();
    },
    selectedUserId() {
      this.fetchLessonComments();
    },
  },
  methods: {
    async handleDeletedComment() {
      await this.fetchCommentsData();
    },
    async fetchCommentsData() {
      if (this.id) {
        if (this.type && this.type === "lesson") {
          await this.fetchLessonComments();
          this.isFetching = false;
          await this.$store.dispatch(
            "theoryPracticeWorkshops/fetchTheoryPracticeWorkshopMembers",
            this.lesson.theoryPracticeWorkshopId
          );
        } else {
          await this.$store
            .dispatch("comments/fetchAllByTimePackageID", this.id)
            .then(() => {
              this.isFetching = false;
            });
        }
      }
      const token = window.localStorage.getItem("userToken");
      if (token) {
        this.loggedIn = true;
        await this.$store.dispatch(`auth/fetchUserProfile`);
      }
    },

    async fetchLessonComments() {
      this.refreshKey = this.refreshKey + 1;
      await this.$store.dispatch("comments/fetchAllByLessonId", {
        id: this.id,
        userId: this.selectedUserId,
        isTheory: this.isTheory,
      });
    },
    reRender() {
      this.$forceUpdate();
    },
    async subCommentAdded() {
      if (this.type === "lesson") {
        this.fetchLessonComments();
      } else {
        await this.$store.dispatch("comments/fetchAllByTimePackageI", this.id);
      }
    },
    async addComment(id) {
      let filesAreCorrect = true;
      if (this.files && this.files.length > 20) {
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "red",
          message: "Możesz dodać maksymalnie 20 zdjęć do swojego komentarza",
        });
        return;
      }
      if (this.files) {
        this.files.forEach((file) => {
          if (!file.type.includes("image")) {
            filesAreCorrect = false;
          }
        });
      }

      if (!filesAreCorrect) {
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "red",
          message:
            "Niepoprawny format pliku. Dozwolone formaty to: jpg, jpeg, png",
        });
        return;
      }
      const formatted = this.content.toString();
      if (!formatted || formatted.length === 0) {
        this.$store.dispatch(
          "snackbar/showSnackbar",
          { color: "red", message: "Komentarz nie może być pusty" },
          { root: true }
        );
        return;
      }
      this.isFetching = true;
      try {
        const object = {
          comment: formatted,
          id: id,
        };
        let newComment = null;
        if (this.type === "lesson") {
          object.isTheory = this.isTheory;
          newComment = await this.$store.dispatch(
            "comments/addCommentForLesson",
            object
          );
        } else {
          newComment = await this.$store.dispatch(
            "comments/addComment",
            object
          );
        }

        if (newComment) {
          if (this.files) {
            this.pack = {
              files: this.files,
              commentID: newComment.id,
            };
            await this.$store.dispatch("comments/addCommentImage", this.pack);
          }

          if (this.type === "lesson") {
            await this.fetchLessonComments();
            this.isFetching = false;
          } else {
            await this.$store
              .dispatch("comments/fetchAllByTimePackageID", this.id)
              .then(() => {
                this.isFetching = false;
              });
          }

          this.content = "";
          this.files = null;
          //window.location.reload();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isFetching = false;
      }
    },
  },
};
</script>

<style lang="scss">
.add-comment {
  width: 80vw;
  margin: auto;
  height: 100%;
  display: block;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.window-edit {
  width: 1100px;
  height: 200px;
  margin: 50px 0;
}

.button-s {
  width: 1100px;
  cursor: pointer;
  padding: 5px 40px;
  font-size: 1.1rem;
  background: linear-gradient(270deg, #c4a2a2 0%, #b28a8a 90.06%);
  font-weight: 500;
  color: #fff;
  text-align: center;
  border-radius: 5px;
}
.content-s {
  width: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  margin: auto;
  height: 100%;
  padding: 50px 0;
  h1 {
    padding-left: 100px;
  }
}
.display-comments {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title-row {
  width: 80%;
  justify-content: space-between;
  flex-wrap: wrap;
}
.user-select {
  width: 300px;
}
@media screen and (max-width: 1300px) {
  .content-s {
    h1 {
      padding-left: 0px;
    }
  }
}
@media screen and (max-width: 1150px) {
  .content-s,
  .window-edit,
  .button-s {
    width: 95vw;
  }
}
@media screen and (max-width: 600px) {
  .button-s {
    margin-top: 30px;
  }
}
// @media screen and (max-width:1400px){
// .button-s{
//     width: 100vw;
//     margin-top: 50px;
// }

// .window-edit{
//     width:100vw;
//    margin:0;
//    margin-top: 50px;
// }
// .content-s{
//    justify-content:left;
//    margin:0;
//    padding:100px 10px;
//    padding-left:0px;
// }
// }

// @media screen and (max-width:1200px){
//     .content-s{
//    padding-left:0px;
// }
// }
// @media screen and(max-width:560px){
// .button-s{
//     margin-top: 80px;
// }
// }
</style>
