var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"1100px","value":_vm.isVisible},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeModal.apply(null, arguments)}}},[_c('v-card',[_c('ValidationObserver',{ref:"obs",attrs:{"mode":"eager"}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" Lista zakupów")])]),_c('v-data-table',{attrs:{"loading":!_vm.items,"loading-text":"Trwa pobieranie danych...","headers":_vm.headers,"items":_vm.items,"search":_vm.search},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":_vm.items && _vm.items.length === 0}},[_vm._v(" Brak zakupów ")])]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"green"},on:{"click":[function($event){return _vm.getUserSubscriptions(item)},function($event){_vm.isModalVisible = true}]}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"shopping-cart"}})],1)]}}],null,true)},[_c('span',[_vm._v("Lista subskrypcji")])])]}},{key:"item.timePackageExpirationTime",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({attrs:{"id":item.id},domProps:{"innerHTML":_vm._s(_vm.formatExpiryDate(item.timePackageExpirationTime))}},'p',attrs,false),on))]}}],null,true)})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({attrs:{"id":item.id},domProps:{"innerHTML":_vm._s(_vm.formatExpiryDate(item.createdAt))}},'p',attrs,false),on))]}}],null,true)})]}},{key:"item.invoiceUrl",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticStyle:{"cursor":"pointer","text-decoration":"underline"},attrs:{"id":item.id},domProps:{"innerHTML":_vm._s(_vm.downloadText(item.invoiceUrl))},on:{"click":function($event){return _vm.downloadInvoice(item.invoiceUrl)}}},'p',attrs,false),on))]}}],null,true)})]}},{key:"item.isActiveTimePackage",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({attrs:{"id":item.id},domProps:{"innerHTML":_vm._s(_vm.isActive(item.isActiveTimePackage))}},'p',attrs,false),on))]}}],null,true)})]}},{key:"item.productType",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({attrs:{"id":item.id},domProps:{"innerHTML":_vm._s(_vm.getProductName(item))}},'p',attrs,false),on))]}}],null,true)})]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({attrs:{"id":item.id},domProps:{"innerHTML":_vm._s(_vm.getShortText(item.description))}},'p',attrs,false),on))]}}],null,true)})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({attrs:{"id":item.id},domProps:{"innerHTML":_vm._s(_vm.getShortText(item.name))}},'p',attrs,false),on))]}}],null,true)})]}}],null,true)}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"warning","text":""},on:{"click":_vm.closeModal}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":"times"}}),_vm._v(" Wróć ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }