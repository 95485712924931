<template>
  <v-row justify="center">
    <v-dialog
      max-width="700px"
      :value="isVisible"
      persistent
      @keydown.esc="closeModal"
    >
      <v-card>
        <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager">
          <v-card-title>
            <span class="headline"> Dodaj lekcję</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    type="text"
                    rules="required"
                    v-model="name"
                    label="Nazwa"
                    color="indigo"
                    outlined
                    dense
                    maxLength="255"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextAreaWithValidation
                    type="text"
                    rules="required"
                    v-model="description"
                    label="Opis"
                    color="indigo"
                    outlined
                    dense
                    maxLength="2048"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-select-with-validation
                    :key="key"
                    rules="required"
                    v-model="theoryPracticeWorkshopId"
                    :items="theoryPracticeWorkshops"
                    item-value="id"
                    item-text="name"
                    outlined
                    color="indigo"
                    dense
                    label="Warsztat"
                    :returnValue="true"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <v-file-input
                    v-model="file"
                    label="Zdjęcie kursu"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text v-on:click="closeModal">
              <font-awesome-icon icon="times" class="mr-1" />
              Anuluj
            </v-btn>
            <v-btn
              color="success"
              text
              :disabled="invalid"
              v-on:click="addWorkshopItem"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              Zapisz zmiany
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "../inputs/VTextFieldWithValidation";
import VTextAreaWithValidation from "../inputs/VTextAreaWithValidation.vue";
import VSelectWithValidation from "../inputs/VSelectWithValidation.vue";

export default {
  name: "AddTheoryPracticeWorkshopLessonModal",
  props: ["isVisible", "workshopItemData"],

  components: {
    ValidationObserver,
    VTextFieldWithValidation,
    VTextAreaWithValidation,
    VSelectWithValidation,
  },

  data: () => ({
    input: "",
    discountError: false,
    updated: false,
    name: "",
    description: "",
    file: null,
    theoryPracticeWorkshopId: "",
  }),
  updated() {
    if (this.workshopItemData) {
      const {
        id,
        name,
        description,
        maxNumberOfMembers,
        price,
        theoryPracticeWorkshopId,
      } = this.workshopItemData;
      this.workshopItemId = id;
      this.updated = this.workshopItemId ? true : false;
      this.name = name;
      this.description = description;
      this.maxNumberOfMembers = maxNumberOfMembers;
      this.price = price;
      this.theoryPracticeWorkshopId = theoryPracticeWorkshopId;
    }
  },

  async created() {},

  methods: {
    closeModal() {
      this.updated = false;
      this.name = "";
      this.description = "";
      this.maxNumberOfMembers = 30;
      this.price = null;
      this.file = null;
      this.theoryPracticeWorkshopId = "";
      this.$emit("closeModal");
    },
    async addWorkshopItem() {
      let filesAreCorrect = true;

      if (!this.theoryPracticeWorkshopId) {
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "red",
          message: "Warsztat do którego ma być przypisany kurs jest wymagany",
        });
        return;
      }

      if (this.file && !this.file.type.includes("image")) {
        filesAreCorrect = false;
      }

      if (!filesAreCorrect) {
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "red",
          message:
            "Niepoprawny format pliku. Dozwolone formaty to: jpg, jpeg, png",
        });
        return;
      }

      let object = {
        name: this.name,
        description: this.description,
        file: this.file,
        theoryPracticeWorkshopId: this.theoryPracticeWorkshopId,
      };
      if (this.updated) {
        await this.$store
          .dispatch(
            "theoryPracticeWorkshopsLessons/editTheoryPracticeWorkshopLesson",
            {
              id: this.workshopItemId,
              data: object,
            }
          )
          .then(() => {
            this.closeModal();
            this.$emit("updated");
          });
      } else {
        await this.$store
          .dispatch(
            "theoryPracticeWorkshopsLessons/createTheoryPracticeWorkshopLesson",
            {
              data: object,
            }
          )
          .then(() => {
            this.closeModal();
            this.$emit("updated");
          });
      }
    },
  },

  computed: {
    theoryPracticeWorkshops() {
      return this.$store.getters[
        "theoryPracticeWorkshops/getTheoryPracticeWorkshops"
      ];
    },
  },
};
</script>
<style type="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
