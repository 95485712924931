<template>
  <div class="container">
    <theory-practice-page-header />
    <div>
      <path-navigation
        v-if="workshop"
        :paths="[
          {
            name: `Warsztaty - ${workshop.name}`,
            to: `/warsztaty-teoria-i-praktyka/${workshop.id}`,
          },
        ]"
      />
      <section>
        <h2 class="workshop-name">{{ workshop ? `${workshop.name}` : "" }}</h2>
        <div
          class="lessons"
          v-if="workshop && workshop.id && lessons && lessons.length > 0"
        >
          <theory-practice-lesson
            :lesson="lesson"
            v-for="lesson in lessons"
            :key="lesson.id"
          />
        </div>
        <div v-else>
          <h3 class="ml-1">Brak lekcji w warsztacie</h3>
        </div>
      </section>
    </div>

    <div class="flex justify-center text-center" id="lessons-select"></div>
  </div>
</template>

<script>
import { SERVER_URL } from "../../axios";
import PathNavigation from "../../components/PathNavigation.vue";
import TheoryPracticeLesson from "./components/TheoryPracticeLesson.vue";
import TheoryPracticePageHeader from "./components/TheoryPracticePageHeader.vue";

export default {
  metaInfo: {
    title: "Dagajoga warsztaty - Anatomia w praktyce",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Dagajoga to szkoła internetowa o wszechstronnym systemie budowania praktyki własnej.\nPraktyka jogi połączona z nowoczesnymi metodami ćwiczeń funkcjonalnych, ćwiczeń wzmacniających.",
      },
      {
        property: "og:image",
        content: "https://dagajoga.pl/wp-content/uploads/2022/02/image-5.png",
      },
    ],
  },
  name: "TheoryPracticeWorkshopLessons",
  data: () => ({}),
  components: {
    PathNavigation,
    TheoryPracticePageHeader,
    TheoryPracticeLesson,
  },
  props: {
    path: String,
  },

  async mounted() {
    await this.$store.dispatch(
      "theoryPracticeWorkshops/fetchTheoryPracticeWorkshop",
      this.$route.params.id
    );
    await this.$store.dispatch(
      "theoryPracticeWorkshopsLessons/fetchTheoryPracticeWorkshopsLessons",
      {
        theoryPracticeWorkshopId: this.$route.params.id,
      }
    );
    const isPaid = await this.$store.dispatch(
      "theoryPracticeWorkshops/checkUserPaidForWorkshop",
      this.$route.params.id
    );
    if (!isPaid) {
      this.redirectLogin();
    }
  },

  computed: {
    workshop() {
      return this.$store.getters[
        "theoryPracticeWorkshops/getTheoryPracticeWorkshop"
      ];
    },
    lessons() {
      return this.$store.getters[
        "theoryPracticeWorkshopsLessons/getTheoryPracticeWorkshopsLessons"
      ];
    },
  },
  methods: {
    redirectLogin(signup) {
      this.$router.push({
        name: "Auth",
        params: {
          name: "TheoryPracticeWorkshopLessons",
          params: { id: this.$route.params.id },
          signup: signup,
        },
      });
    },

    getImage(item) {
      const extension = item.imagePath?.split(".")[1];
      return `${SERVER_URL}sequencingWorkshopItem_${
        item.id
      }.${extension}?d=${new Date().getTime()}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 2560px;
  margin: 0;
}
.workshop-name {
  margin-bottom: 1rem;
}
.lessons {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
</style>
