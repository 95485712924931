<template>
  <v-row justify="center">
    <v-dialog
      max-width="700px"
      :value="isVisible"
      persistent
      @keydown.esc="closeModal"
    >
      <v-card>
        <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager">
          <v-card-title>
            <span class="headline"> Dodaj teorię</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <html-editor
                    @contentchanged="handleHtmlEditorContentForCategory($event)"
                    :startContent="
                      lessonItemData && lessonItemData.description
                        ? lessonItemData.description
                        : ''
                    "
                    :maxLength="8000"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    type="text"
                    v-model="link"
                    label="Link YouTube"
                    color="indigo"
                    outlined
                    dense
                    maxLength="1024"
                    rules="link"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  v-if="
                    theoryPracticeWorkshopsLessons &&
                    theoryPracticeWorkshopsLessons.length > 0
                  "
                >
                  <v-select-with-validation
                    :key="keyHandle"
                    rules="required"
                    v-model="theoryPracticeWorkshopsLessonId"
                    :items="theoryPracticeWorkshopsLessons"
                    item-value="id"
                    item-text="name"
                    outlined
                    color="indigo"
                    dense
                    label="Lekcja"
                    :returnValue="true"
                    :selectedValue="theoryPracticeWorkshopsLessonId"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text v-on:click="closeModal">
              <font-awesome-icon icon="times" class="mr-1" />
              Anuluj
            </v-btn>
            <v-btn
              color="success"
              text
              :disabled="invalid"
              v-on:click="addLessonPracticeItem"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              Zapisz zmiany
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "../inputs/VTextFieldWithValidation";
import HtmlEditor from "../HtmlEditor.vue";
import VSelectWithValidation from "../inputs/VSelectWithValidation.vue";

export default {
  name: "AddTheoryPracticeWorkshopLessonTheoryModal",
  props: ["isVisible", "lessonItemData", "keyHandle"],

  components: {
    ValidationObserver,
    VTextFieldWithValidation,
    HtmlEditor,
    VSelectWithValidation,
  },

  data: () => ({
    input: "",
    discountError: false,
    updated: false,
    description: "",
    link: null,
    theoryPracticeWorkshopsLessonId: "",
  }),
  updated() {
    if (this.lessonItemData) {
      const { id, description, theoryPracticeWorkshopsLessonId, link } =
        this.lessonItemData;

      this.lessonPracticeItemId = id;
      this.updated = this.lessonPracticeItemId ? true : false;
      this.description = description;
      this.theoryPracticeWorkshopsLessonId = theoryPracticeWorkshopsLessonId;
      this.link = link;
    } else {
      this.theoryPracticeWorkshopsLessonId = "";
    }
  },

  watch: {
    lessonItemData() {
      if (this.lessonItemData) {
        const { id, description, theoryPracticeWorkshopsLessonId, link } =
          this.lessonItemData;

        this.lessonPracticeItemId = id;
        this.updated = this.lessonPracticeItemId ? true : false;
        this.description = description;
        this.theoryPracticeWorkshopsLessonId = theoryPracticeWorkshopsLessonId;
        this.link = link;
      } else {
        this.theoryPracticeWorkshopsLessonId = "";
      }
    },
  },

  async created() {},

  methods: {
    handleHtmlEditorContentForCategory(content) {
      this.description = content;
    },
    closeModal() {
      this.updated = false;
      this.description = "";
      this.theoryPracticeWorkshopsLessonId = "";
      this.link = "";
      this.$emit("closeModal");
    },
    async addLessonPracticeItem() {
      let object = {
        description: this.description,
        link: this.link,
        theoryPracticeWorkshopsLessonId: this.theoryPracticeWorkshopsLessonId,
      };
      if (this.updated) {
        await this.$store
          .dispatch(
            "theoryPracticeWorkshopsLessonsTheories/editTheoryPracticeWorkshopLessonTheory",
            {
              id: this.lessonPracticeItemId,
              data: object,
            }
          )
          .then((responseStatus) => {
            if (responseStatus === 200) {
              this.closeModal();
              this.$emit("updated");
            }
          });
      } else {
        await this.$store
          .dispatch(
            "theoryPracticeWorkshopsLessonsTheories/createTheoryPracticeWorkshopLessonTheory",
            {
              data: object,
            }
          )
          .then((responseStatus) => {
            if (responseStatus === 200) {
              this.closeModal();
              this.$emit("updated");
            }
          });
      }
    },
  },

  computed: {
    theoryPracticeWorkshopsLessons() {
      const lessons =
        this.$store.getters[
          "theoryPracticeWorkshopsLessons/getTheoryPracticeWorkshopsLessons"
        ];
      if (!lessons) return [];

      lessons.forEach((lesson) => {
        lesson.name = `${lesson.name} - ${lesson.theoryPracticeWorkshop.name}`;
      });
      return lessons;
    },
  },
};
</script>
<style type="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
