<template>
  <div class="box">
    <div class="content">
      <div class="img"><slot name="image"></slot></div>
      <h3 class="mb-4">{{ name }}</h3>
      <ul class="ml-8">
        <li v-for="item in listOfLi" :key="item">
          {{ item }}
        </li>
      </ul>
    </div>
    <p class="see-more" @click="workshopsSelected">Zobacz więcej</p>
  </div>
</template>

<script>
export default {
  name: "WorkshopOffer",
  props: ["name", "listOfLi"],
  emits: ["selected"],
  methods: {
    workshopsSelected() {
      this.$emit("selected");
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&family=Raleway:wght@400;500&display=swap");

.box {
  color: #000;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 650px;
  width: 500px;
}
.content {
  height: 80%;
}
.img {
  text-align: center;
}
img {
  width: 200px;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

h3 {
  font-size: 1.55rem;
  font-weight: bold;
  text-align: center;
}

p {
  font-weight: bold;
  padding-top: 0.5rem;
  text-align: center;
  font-size: 1.02rem;
}

ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

ul li {
  margin: 0.2rem 0;
  color: #000;
  width: 70%;
  font-size: 1.05rem;
  text-align: left;
}

.see-more {
  color: #fff;
  background: linear-gradient(270deg, #c4a2a2 0%, #b28a8a 90.06%);
  border-radius: 10px;
  padding: 0.6rem 1.2rem;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 400;
  width: 180px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 3rem;
  cursor: pointer;
}
</style>
